import {
  AliasValue,
  BaseDataRef,
  BaseDataValueRef,
  BaseInputValue,
  BoolInputValue,
  CalcOperation,
  Calculation,
  CalculationFunction,
  CalculationParams,
  CalculationRef,
  CLS,
  CmsConfiguratorPage,
  CmsField,
  CmsFieldGroup,
  CmsGenericEntry,
  CmsPage,
  CmsProjectCI,
  Condition,
  CONDITIONAL_OPERATOR,
  ConditionGroup,
  Configuration,
  ConfiguratorField,
  Data,
  DATA_TYPES,
  DATA_VALUE_TYPE,
  DATAHANDLER_TYPES,
  DataHandlers,
  DateFieldConfig,
  DateInputValue,
  DynamicDataHandler,
  EmptyFormValue,
  EmptyInputValue,
  EntryMeta,
  Field,
  FIELD_TYPES,
  FIELD_TYPES_TO_DATA_VALUE_TYPES,
  FieldConfig,
  FieldGroup,
  FieldRef,
  FORM_VALUE_TYPES,
  FunctionCommand,
  GenericEntry,
  Image,
  InputValue,
  Is,
  LOGICAL_OPERATOR,
  MultiFormValue,
  MultiSelectFieldConfig,
  MultiSelectionFormValue,
  NewEntry,
  NoInputValue,
  NumericFieldConfig,
  NumericInputValue,
  Page,
  PageRef,
  PrintValue,
  Project,
  ProjectCI,
  ProjectInfo,
  RelativeDate,
  SAVE_DATE_FORMAT,
  SELECT_FIELD_LAYOUTS,
  SelectFieldConfig,
  SelectionData,
  SimpleListHandler,
  SingleFormValue,
  SingleSelectionFormValue,
  Sort,
  SORT_DIRECTION,
  START_DATES,
  SubmitButtonFieldConfig,
  TEXTFIELD_VALIDATIONS,
  TextFieldConfig,
  TextInputValue,
  VALIDATION_ERROR_DETAILS,
  VALIDATION_ERROR_TYPE,
  ValidationError,
  ValueCalcParam,
  ValueCalculation,
  ValueLabel,
  ValueTypes,
} from './index';
import { ObjectUtil } from '../common';

import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { CalculationDetails } from '../configurator';

export class Create {
  public static genericEntry(): GenericEntry {
    return {
      cls: CLS.NONE,
    } as GenericEntry;
  }

  public static convertGenericToCmsEntry(entry: GenericEntry): NewEntry<CmsGenericEntry> {
    return {
      meta: Create.entryMeta(),
      ...entry,
    };
  }

  public static genericCmsEntry<T extends CmsGenericEntry>(entry: Partial<CmsGenericEntry>): T {
    if (!entry.cls) {
      throw new Error('Cannot create entry without cls');
    }

    switch (entry.cls) {
      case CLS.PAGE:
        return Create.cmsPage(entry) as unknown as T;
      case CLS.FIELD_GROUP:
        return Create.cmsFieldGroup(entry) as unknown as T;
      case CLS.FIELD:
        return Create.cmsField((entry as CmsField).config.type, entry) as unknown as T;
      default:
        throw new Error(`Cls "${entry.cls}" is not yet supported for entry creation`);
    }
  }

  public static entryMeta(settings: EntryMeta = {}): EntryMeta {
    return {
      created: settings?.created ? settings.created : new Date(),
      createdBy: settings?.createdBy ? settings.createdBy : undefined,
      projectId: settings?.projectId ? settings.projectId : undefined,
      configuratorId: settings?.configuratorId ? settings.configuratorId : undefined,
      updated: settings?.updated ? settings.updated : undefined,
      updatedBy: settings?.updatedBy ? settings.updatedBy : undefined,
      state: settings?.state ? settings.state : undefined,
    };
  }

  public static mandant(name = '', label = ''): Project {
    return {
      cls: CLS.PROJECT,
      name,
      label,
      pages: [],
      configurators: [],
    } as Project;
  }

  public static configuration(configuration: Partial<Configuration> = {}): Configuration {
    return {
      cls: CLS.CONFIGURATION,
      versions: {
        current: 1,
      },
      name: '',
      label: '',
      settings: {
        cls: CLS.CONFIGURATION_SETTINGS,
      },
      children: [],
      ...configuration,
    };
  }

  public static cmsConfiguratorPage(entry: CmsPage): NewEntry<CmsConfiguratorPage> {
    return {
      cls: CLS.PAGE_WRAPPER,
      entry,
    };
  }

  public static page(page: Partial<Page> = {}): Page {
    return {
      cls: CLS.PAGE,
      label: page.label ? page.label : '',
      name: page.name ? page.name : '',
      children: [],
    };
  }

  public static cmsPage(page: Partial<Omit<CmsPage, 'cls'>> = {}): CmsPage {
    return ObjectUtil.filterUnset({
      cls: CLS.PAGE,
      _id: '',
      label: page.label ? page.label : '',
      name: page.name ? page.name : '',
      children: [],
      meta: Create.entryMeta(),
      info: page.info ? page.info : undefined,
      noNavPrev: page.noNavPrev ? page.noNavPrev : undefined,
      noNavNext: page.noNavNext ? page.noNavNext : undefined,
    });
  }

  public static cmsFieldGroup(page: Partial<Omit<CmsFieldGroup, 'cls'>> = {}): CmsFieldGroup {
    return ObjectUtil.filterUnset({
      cls: CLS.FIELD_GROUP,
      _id: '',
      label: page.label ? page.label : '',
      name: page.name ? page.name : '',
      children: [],
      meta: Create.entryMeta(),
      info: page.info ? page.info : undefined,
      config: {},
    });
  }

  public static cmsField(type: FIELD_TYPES, field: Partial<Omit<CmsField, 'cls'>> = {}): CmsField {
    return ObjectUtil.filterUnset<CmsField>({
      cls: CLS.FIELD,
      _id: '',
      label: field.label ? field.label : '',
      name: field.name ? field.name : '',
      meta: Create.entryMeta(),
      info: field.info ? field.info : undefined,
      config: field.config
        ? (field.config as FieldConfig)
        : {
            cls: CLS.FIELD_CONFIG,
            type,
            dataType: FIELD_TYPES_TO_DATA_VALUE_TYPES[type],
          },
    });
  }

  public static fieldGroup(name = ''): FieldGroup {
    return {
      cls: CLS.FIELD_GROUP,
      name,
    } as FieldGroup;
  }

  public static field(name = '', config: FieldConfig, field?: Partial<Field>): Field {
    return ObjectUtil.filterUnset({
      cls: CLS.FIELD,
      name,
      label: field?.label ?? undefined,
      config: config,
      info: field?.info ?? undefined,
      hint: field?.hint ?? undefined,
    });
  }

  public static projectInfo(): ProjectInfo {
    return {
      name: '',
      slogan: '',
      imprint: '',
      privacyLink: '',
      domain: '',
    } as ProjectInfo;
  }

  public static projectCI(): ProjectCI {
    return {
      mainColor: '#2A3639',
      mainTextColor: '#f1f1f1',
      highlightColor: '#72312A',
      highlightTextColor: '#f1f1f1',
      fontName: 'Verdana',
    };
  }

  public static cmsProjectCI(): CmsProjectCI {
    return {
      ...this.projectCI(),
      logo: undefined,
    };
  }

  public static pageRef(name = ''): PageRef {
    return {
      cls: CLS.PAGE_REF,
      name,
    } as PageRef;
  }

  public static baseDataRef(name = ''): BaseDataRef {
    return {
      cls: CLS.BASE_DATA_REF,
      name,
    };
  }

  public static baseDataValueRef(name = '', property = ''): BaseDataValueRef {
    return {
      cls: CLS.BASE_DATA_REF,
      name,
      property,
    };
  }

  public static fieldRef(name = '', label?: ValueLabel, property?: string): FieldRef {
    return {
      cls: CLS.FIELD_REF,
      name,
      property,
      label,
    };
  }

  public static calculationRef(name = ''): CalculationRef {
    return {
      cls: CLS.CALCULATION_REF,
      name,
    };
  }

  public static fieldWrapper(entry: Field): ConfiguratorField {
    return {
      cls: CLS.FIELD_WRAPPER,
      entry,
    };
  }

  public static textFieldConfig(properties: Partial<TextFieldConfig> = {}): TextFieldConfig {
    return {
      cls: CLS.FIELD_CONFIG,
      dataType: DATA_VALUE_TYPE.STRING,
      type: FIELD_TYPES.TEXT,
      validation: TEXTFIELD_VALIDATIONS.TEXT,
      ...properties,
    };
  }

  public static numericFieldConfig(properties: Partial<NumericFieldConfig> = {}): NumericFieldConfig {
    return {
      cls: CLS.FIELD_CONFIG,
      dataType: DATA_VALUE_TYPE.NUMERIC,
      type: FIELD_TYPES.NUMBER,
      ...properties,
    };
  }

  public static dateFieldConfig(properties: Partial<DateFieldConfig> = {}): DateFieldConfig {
    return {
      cls: CLS.FIELD_CONFIG,
      dataType: DATA_VALUE_TYPE.DATE,
      type: FIELD_TYPES.DATE,
      ...properties,
    };
  }

  public static submitBtnConfig(properties: Partial<SubmitButtonFieldConfig> = {}): SubmitButtonFieldConfig {
    return {
      cls: CLS.FIELD_CONFIG,
      dataType: DATA_VALUE_TYPE.EMPTY,
      type: FIELD_TYPES.SUBMITBTN,
      ...properties,
    };
  }

  public static selectFieldConfig(
    dataHandler: DataHandlers,
    properties: Partial<SelectFieldConfig> = {},
  ): SelectFieldConfig {
    return {
      cls: CLS.FIELD_CONFIG,
      type: FIELD_TYPES.SELECT,
      layout: {
        type: SELECT_FIELD_LAYOUTS.DROPDOWN,
      },
      dataType: DATA_VALUE_TYPE.STRING,
      dataHandler,
      ...properties,
    };
  }

  public static multiSelectFieldConfig(
    dataHandler: DataHandlers,
    properties: Partial<MultiSelectFieldConfig> = {},
  ): MultiSelectFieldConfig {
    return Create.selectFieldConfig(dataHandler, properties);
  }

  /**
   * @deprecated use special creator eg textFieldConfig instead
   * @param type
   * @param dataType
   * @param addProps
   */
  public static fieldConfig<T = FieldConfig>(
    type: FIELD_TYPES,
    dataType: DATA_VALUE_TYPE = DATA_VALUE_TYPE.EMPTY,
    addProps?: Record<string, unknown>,
  ): T {
    const cfg = {
      cls: CLS.FIELD_CONFIG,
      dataType,
      type,
    } as unknown as T;

    if (addProps) {
      return { ...cfg, ...addProps } as T;
    }

    return cfg as T;
  }

  public static data(type: DATA_TYPES, value: InputValue, name = undefined): Data {
    return {
      type,
      value,
      name,
    } as Data;
  }

  public static emptyInputValue(): EmptyInputValue {
    return {
      cls: CLS.INPUT_VALUE,
      type: DATA_VALUE_TYPE.EMPTY,
    };
  }

  public static noInputValue(): NoInputValue {
    return {
      cls: CLS.INPUT_VALUE,
      type: DATA_VALUE_TYPE.VOID,
    };
  }

  public static createDefaultInputValue(type: DATA_VALUE_TYPE): BaseInputValue {
    switch (type) {
      case DATA_VALUE_TYPE.NUMERIC:
        return Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 0);
      case DATA_VALUE_TYPE.STRING:
        return Create.inputValue(DATA_VALUE_TYPE.STRING, '');
      case DATA_VALUE_TYPE.DATE:
        return Create.inputValue(DATA_VALUE_TYPE.DATE, dayjs().format(SAVE_DATE_FORMAT));
      case DATA_VALUE_TYPE.EMPTY:
        return Create.emptyInputValue();
    }
    throw new Error(`Invalid type ${type}`);
  }

  public static createInputValueWithoutValue(type: DATA_VALUE_TYPE): BaseInputValue {
    switch (type) {
      case DATA_VALUE_TYPE.NUMERIC:
        return {
          cls: CLS.INPUT_VALUE,
          type: DATA_VALUE_TYPE.NUMERIC,
        };
      case DATA_VALUE_TYPE.STRING:
        return {
          cls: CLS.INPUT_VALUE,
          type: DATA_VALUE_TYPE.STRING,
        };
      case DATA_VALUE_TYPE.DATE:
        return {
          cls: CLS.INPUT_VALUE,
          type: DATA_VALUE_TYPE.DATE,
        };
    }
    throw new Error(`Invalid type ${type}`);
  }

  public static inputValue(
    type: DATA_VALUE_TYPE.VOID,
    value?: ValueTypes,
    identifier?: string,
    label?: ValueLabel,
  ): NoInputValue;
  public static inputValue(
    type: DATA_VALUE_TYPE.EMPTY,
    value?: ValueTypes,
    identifier?: string,
    label?: ValueLabel,
  ): EmptyInputValue;
  public static inputValue(type: DATA_VALUE_TYPE.NUMERIC): EmptyInputValue;
  public static inputValue(
    type: DATA_VALUE_TYPE.NUMERIC,
    value: boolean,
    identifier?: string,
    label?: ValueLabel,
  ): EmptyInputValue;
  public static inputValue(
    type: DATA_VALUE_TYPE.NUMERIC,
    value: string,
    identifier?: string,
    label?: ValueLabel,
  ): NumericInputValue | EmptyInputValue;
  public static inputValue(
    type: DATA_VALUE_TYPE.NUMERIC,
    value: number,
    identifier?: string,
    label?: ValueLabel,
  ): NumericInputValue;
  public static inputValue(type: DATA_VALUE_TYPE.STRING): EmptyInputValue;
  public static inputValue(
    type: DATA_VALUE_TYPE.STRING,
    value: ValueTypes,
    identifier?: string,
    label?: ValueLabel,
  ): TextInputValue;
  public static inputValue(type: DATA_VALUE_TYPE.DATE): EmptyInputValue;
  public static inputValue(
    type: DATA_VALUE_TYPE.DATE,
    value: ValueTypes,
    identifier?: string,
    label?: ValueLabel,
  ): DateInputValue | EmptyInputValue;
  public static inputValue(type: DATA_VALUE_TYPE.BOOL): EmptyInputValue;
  public static inputValue(
    type: DATA_VALUE_TYPE.BOOL,
    value: boolean,
    identifier?: string,
    label?: ValueLabel,
  ): BoolInputValue;
  public static inputValue(
    type: DATA_VALUE_TYPE.BOOL,
    value: number | string,
    identifier?: string,
    label?: ValueLabel,
  ): BoolInputValue | EmptyInputValue;
  public static inputValue(
    type: DATA_VALUE_TYPE,
    value?: ValueTypes,
    identifier?: string,
    label?: ValueLabel,
  ): InputValue;
  public static inputValue(
    type: DATA_VALUE_TYPE,
    value?: ValueTypes,
    identifier?: string,
    label?: ValueLabel,
  ): InputValue {
    if (type === DATA_VALUE_TYPE.VOID) {
      return Create.noInputValue();
    }
    if (type === DATA_VALUE_TYPE.EMPTY) {
      return Create.emptyInputValue();
    }
    let valueType: ValueTypes | undefined;

    if (value !== undefined) {
      switch (type) {
        case DATA_VALUE_TYPE.STRING:
          valueType = String(value);
          break;
        case DATA_VALUE_TYPE.NUMERIC:
          if (isNaN(parseInt(String(value)))) {
            return Create.emptyInputValue();
          }
          if (typeof value === 'number') {
            valueType = value;
          } else {
            valueType = parseFloat(String(value));
          }
          break;
        case DATA_VALUE_TYPE.BOOL:
          // eslint-disable-next-line no-case-declarations
          const parsed = String(value).toLowerCase();
          if (parsed === 'true') {
            valueType = true;
          } else if (parsed === 'false') {
            valueType = false;
          } else {
            return Create.emptyInputValue();
          }
          break;

        case DATA_VALUE_TYPE.DATE:
          try {
            const parsedDate = dayjs(String(value));
            if (!parsedDate.isValid()) {
              return Create.emptyInputValue();
            }
            valueType = parsedDate.format(SAVE_DATE_FORMAT);
          } catch (_) {
            return Create.emptyInputValue();
          }
          break;
      }
    }

    if (valueType === undefined) {
      throw new Error('Cannot create inputValue without value');
    }

    const entry: InputValue = {
      cls: CLS.INPUT_VALUE,
      type,
      value: valueType,
    };

    if (identifier) {
      entry.identifier = identifier;
    }
    if (label) {
      entry.label = label;
    }
    return entry;
  }

  public static selectionData(name: string, label: string, values?: InputValue[]): SelectionData {
    return {
      cls: CLS.SELECTION_DATA,
      type: DATA_TYPES.SELECTION,
      name,
      label,
      values,
    };
  }

  public static condition(value1: FieldRef, op: CONDITIONAL_OPERATOR, value2?: InputValue | FieldRef): Condition {
    return {
      cls: CLS.CONDITION,
      value1,
      op,
      value2,
    };
  }

  public static conditionGroup(conditions: Condition[] = [], lop = LOGICAL_OPERATOR.AND): ConditionGroup {
    return {
      cls: CLS.CONDITION_GROUP,
      lop,
      conditions,
    };
  }

  public static valueCalculation(
    params: ValueCalcParam[] = [],
    op: CalcOperation = CalcOperation.ADD,
    label: ValueLabel | undefined = undefined,
    alias: string | undefined = undefined,
    condition: Condition | undefined = undefined,
  ): ValueCalculation {
    return Create.calculation(params, op, label, alias, condition) as ValueCalculation;
  }

  public static calculation(
    params: CalculationParams = [],
    op: CalcOperation = CalcOperation.ADD,
    label: ValueLabel | undefined = undefined,
    alias: string | undefined = undefined,
    condition: Condition | undefined = undefined,
  ): Calculation {
    return {
      cls: CLS.CALCULATION,
      op,
      params,
      label,
      condition,
      alias: alias ?? uuidv4(),
      excludeFromResult: false,
    };
  }

  public static calculationDetails(): CalculationDetails {
    return {
      id: uuidv4(),
      cls: CLS.CALCULATION,
      op: CalcOperation.ADD,
      alias: uuidv4(),
      params: [],
    };
  }

  public static valueLabel(text = '', format = ''): ValueLabel {
    return {
      cls: CLS.VALUE_LABEL,
      text,
      format,
    } as ValueLabel;
  }

  public static aliasValue(name = '', label: ValueLabel | undefined = undefined): AliasValue {
    return {
      cls: CLS.ALIAS_VALUE,
      name,
      label,
    } as AliasValue;
  }

  public static relativeDate(): RelativeDate {
    return {
      cls: CLS.RELATIVE_DATE,
      start: {
        cls: CLS.DATE_REF,
        name: START_DATES.TODAY,
      },
      manipulation: '+',
      value: 0,
      unit: 'd',
    } as RelativeDate;
  }

  public static image(title = '', url = '', thumbnail?: string): Image {
    return {
      cls: CLS.IMAGE,
      title,
      url,
      thumbnail: thumbnail,
    };
  }

  public static calculationFunction(
    command = FunctionCommand.ROUND,
    params: (string | number)[] = [],
  ): CalculationFunction {
    return {
      cls: CLS.CALCULATION_FUNCTION,
      command,
      params,
    } as CalculationFunction;
  }

  public static emptyFormValue(): EmptyFormValue {
    return {
      cls: CLS.FORM_VALUE,
      type: FORM_VALUE_TYPES.EMPTY,
    } as EmptyFormValue;
  }

  public static textFormValue(
    value?: string | number | TextInputValue,
  ): SingleFormValue<TextInputValue> | EmptyFormValue {
    if (value === undefined) {
      return Create.emptyFormValue();
    }

    const inputValue = Is.textInputValue(value) ? value : Create.inputValue(DATA_VALUE_TYPE.STRING, value);

    if (!Is.inputValue(inputValue) || Is.emptyInputValue(inputValue)) {
      return Create.emptyFormValue();
    }
    return Create.singleFormValue<TextInputValue>(inputValue);
  }

  public static numericFormValue(
    value?: number | string | NumericInputValue,
  ): SingleFormValue<NumericInputValue> | EmptyFormValue {
    if (value === undefined) {
      return Create.emptyFormValue();
    }
    const inputValue = Is.numericInputValue(value) ? value : Create.inputValue(DATA_VALUE_TYPE.NUMERIC, value);

    if (!Is.inputValue(inputValue) || Is.emptyInputValue(inputValue)) {
      return Create.emptyFormValue();
    }
    return Create.singleFormValue<NumericInputValue>(inputValue);
  }

  public static boolFormValue(
    value?: boolean | string | BoolInputValue,
  ): SingleFormValue<BoolInputValue> | EmptyFormValue {
    const inputValue = Is.boolInputValue(value) ? value : Create.inputValue(DATA_VALUE_TYPE.BOOL, value);

    if (!Is.inputValue(inputValue) || Is.emptyInputValue(inputValue)) {
      return Create.emptyFormValue();
    }
    return Create.singleFormValue<BoolInputValue>(inputValue);
  }

  public static dateFormValue(
    value?: string | Date | DateInputValue,
  ): SingleFormValue<DateInputValue> | EmptyFormValue {
    if (value === undefined) {
      return Create.emptyFormValue();
    }
    if (Is.inputValue(value)) {
      return Create.singleFormValue<DateInputValue>(value);
    }
    if (value instanceof Date) {
      const inputValue = Create.inputValue(DATA_VALUE_TYPE.DATE, value.toISOString());
      if (Is.inputValue(inputValue) && !Is.emptyInputValue(inputValue)) {
        return Create.singleFormValue<DateInputValue>(inputValue);
      }
    }
    if (typeof value === 'string') {
      const inputValue = Create.inputValue(DATA_VALUE_TYPE.DATE, value);
      if (Is.inputValue(inputValue) && !Is.emptyInputValue(inputValue)) {
        return Create.singleFormValue<DateInputValue>(inputValue);
      }
    }

    return Create.emptyFormValue();
  }

  public static singleFormValue<T extends InputValue = InputValue>(
    value?: InputValue,
  ): SingleFormValue<T> | EmptyFormValue {
    if (value === undefined) {
      return Create.emptyFormValue();
    }
    return {
      cls: CLS.FORM_VALUE,
      type: FORM_VALUE_TYPES.SINGLE,
      input: value,
    } as SingleFormValue<T>;
  }

  public static multiFormValue(valueMap: { [key: string]: InputValue }): MultiFormValue {
    return {
      cls: CLS.FORM_VALUE,
      type: FORM_VALUE_TYPES.MULTI,
      valueMap,
    } as MultiFormValue;
  }

  public static singleSelectionFormValue(selection: SelectionData, key = ''): SingleSelectionFormValue {
    return {
      cls: CLS.FORM_VALUE,
      type: FORM_VALUE_TYPES.SINGLE_SELECTION,
      key,
      selection,
    };
  }

  public static multiSelectionFormValue(selection: SelectionData[], key = ''): MultiSelectionFormValue {
    return {
      cls: CLS.FORM_VALUE,
      type: FORM_VALUE_TYPES.MULTI_SELECTION,
      key,
      selection,
    };
  }

  public static validationError(
    type: VALIDATION_ERROR_TYPE,
    detail: VALIDATION_ERROR_DETAILS,
    data?: Record<string, string | number>,
  ): ValidationError {
    return {
      type,
      detail,
      data,
    } as ValidationError;
  }

  public static dynamicDataHandler(templateName?: string, tags?: string[], sort?: Sort[]): DynamicDataHandler {
    return {
      cls: CLS.DATA_HANDLER,
      type: DATAHANDLER_TYPES.DYNAMIC,
      templateName,
      tags,
      sort,
    };
  }

  public static simpleListDataHandler(data: SelectionData[] | BaseDataRef[] = []): SimpleListHandler {
    return {
      type: DATAHANDLER_TYPES.LIST,
      cls: CLS.DATA_HANDLER,
      data,
    };
  }

  public static sort(field = '', direction: SORT_DIRECTION = SORT_DIRECTION.ASC): Sort {
    return {
      field,
      direction,
    };
  }

  public static printValue(key: string, value: string, label = ''): PrintValue {
    return {
      key,
      label,
      value,
    };
  }
}
