import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CfgSettingsService, ScreenSize } from '@kfd/cfg-core';
import { Observable } from 'rxjs';
import { ArrayUtil, LabeledValue, ObjectUtil, SelectionData } from '@kfd/core';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';

interface InlineSelectOption {
  id: string;
  label: string;
  selected: boolean;
  image?: string;
  value: SelectionData;
  additionalProperties: {
    label: string;
    value: string;
  }[];
}

@Component({
  selector: 'kfd-web-inline-selection',
  standalone: true,
  imports: [CommonModule, TooltipModule, CheckboxModule, FormsModule],
  templateUrl: './inline-selection.component.html',
  styleUrl: './inline-selection.component.scss',
})
export class InlineSelectionComponent {
  @Input()
  public minSelections = 1;
  @Input()
  public maxSelections = 1;
  @Input()
  public optionId = 'id';
  @Input()
  public optionLabel = 'label';
  @Input()
  public optionValue = 'value';
  @Input()
  public optionImage = 'image';
  @Output()
  public selectionChange = new EventEmitter<string[]>();
  protected screenSize$: Observable<ScreenSize>;
  protected readonly ScreenSize = ScreenSize;
  // internally used values
  protected inlineSelectOptions: InlineSelectOption[] | undefined;
  private _selectedIds: string[] = [];

  constructor(protected readonly configService: CfgSettingsService) {
    this.screenSize$ = this.configService.onScreenSizeChange();
  }

  private _additionalValues: LabeledValue[] | undefined = [];

  get additionalValues(): LabeledValue[] | undefined {
    return this._additionalValues;
  }

  @Input()
  set additionalValues(value: LabeledValue[] | undefined) {
    this._additionalValues = value;
    this.buildInlineSelectOptions();
  }

  // external values
  private _options: SelectionData[] | undefined;

  get options(): SelectionData[] | undefined {
    return this._options;
  }

  @Input()
  set options(values: SelectionData[] | undefined) {
    if (!values) {
      this._options = [];
      return;
    }
    this._options = values;
    this.buildInlineSelectOptions();
  }

  public get selection(): InlineSelectOption[] {
    return this.inlineSelectOptions?.filter((option) => option.selected) ?? [];
  }

  @Input()
  public set selection(values: string[]) {
    this._selectedIds = values;
  }

  protected selectOption(option: InlineSelectOption): void {
    //skip if max selections reached (but only for multiselect)
    if (this.maxSelections > 1 && this.selection.length >= this.maxSelections) {
      return;
    }

    if (this.maxSelections <= 1) {
      this._selectedIds = [option.id];
    } else {
      this._selectedIds = ArrayUtil.distinct([...this._selectedIds, option.id]);
    }
    this.updateSelections();

    this.emitChanges();
  }

  protected deSelectOption(option: InlineSelectOption): void {
    if (this._selectedIds.includes(option.id)) {
      this._selectedIds = this._selectedIds.filter((o) => o !== option.id);
    }
    this.updateSelections();
    this.emitChanges();
  }

  protected updateSelections(): void {
    this.inlineSelectOptions = this.inlineSelectOptions?.map((o) => {
      return {
        ...o,
        selected: this._selectedIds.includes(o.id),
      };
    });
  }

  protected emitChanges(): void {
    this.selectionChange.emit(this.selection.map((option) => option.id as string));
  }

  private buildInlineSelectOptions(): void {
    if (!this.options) {
      this.inlineSelectOptions = [];
      return;
    }
    this.inlineSelectOptions = this.options.map((option) => {
      return {
        id: ObjectUtil.getValue(option, this.optionId) as string,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        label: (ObjectUtil.getValue(option, this.optionLabel) as string) ?? '',
        image: (ObjectUtil.getValue(option, this.optionImage) as string) ?? undefined,
        selected: false,
        value: option,
        additionalProperties:
          this._additionalValues?.map((additionalValue) => ({
            label: additionalValue.label,
            value: '' + option.values?.find((optionValue) => optionValue.identifier === additionalValue.value)?.value,
          })) ?? [],
      };
    });
    this.updateSelections();
  }
}
