import { Directive, ElementRef, EventEmitter, NgZone, OnDestroy, Output } from '@angular/core';

export class ResizedEvent {
  public newRect: DOMRectReadOnly;
  public oldRect?: DOMRectReadOnly;

  constructor(newRect: DOMRectReadOnly, oldRect: DOMRectReadOnly | undefined) {
    this.newRect = newRect;
    this.oldRect = oldRect;
  }
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[resized]',
  standalone: false,
})
export class ResizedDirective implements OnDestroy {
  @Output()
  public readonly resized = new EventEmitter<ResizedEvent>();
  private readonly observer: ResizeObserver;
  private oldRect?: DOMRectReadOnly;

  constructor(
    private readonly element: ElementRef<HTMLElement>,
    private readonly zone: NgZone,
  ) {
    // we observe the directive attached element for changes
    this.observer = new ResizeObserver((entries) => this.zone.run(() => this.observe(entries[0])));
    this.observer.observe(this.element.nativeElement);
  }

  public ngOnDestroy(): void {
    this.observer.disconnect();
  }

  private observe(domSize: ResizeObserverEntry): void {
    const resizedEvent = new ResizedEvent(domSize.contentRect, this.oldRect);
    this.oldRect = domSize.contentRect;
    this.resized.emit(resizedEvent);
  }
}
