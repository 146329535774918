// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  baseHref: '',
  auth: {
    // session timeout in minutes - need to be aligned with value in auth service
    sessionTimeout: 360,
  },
  services: {
    auth: 'https://auth-service.dev.konfidoo.de/api',
    content: 'https://content-service.dev.konfidoo.de/api',
    cfg: 'https://cfg-service.dev.konfidoo.de/api',
    cms: 'https://cms-service.dev.konfidoo.de/api',
    cms_websocket: {
      url: 'https://cms-service.dev.konfidoo.de/ws/v1',
      path: '/ws/v1',
    },
  },
  apps: {
    cms: 'https://cms.dev.konfidoo.de',
    web: 'https://dev.konfidoo.de',
    cfg: 'https://cfg.dev.konfidoo.de',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
