<kfd-fullscreen-layout>
  <p-message severity="warn">
    <ng-template #icon>
      <div class="flex-1 flex flex-column align-items-center">
        <i class="pi pi-exclamation-triangle text-6xl"></i>
        <h2>Service nicht verfügbar</h2>
        <p>Der Service steht aktuell nicht zur Verfügung, bitte probieren sie es später noch einmal.</p>
      </div>
    </ng-template>
  </p-message>
</kfd-fullscreen-layout>
