import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CMS_APP_ROUTE_PARTS_PROJECT_SETTINGS, LicenceConfigOptions, LicenceLimitValue } from '@kfd/core';
import { Observable, tap } from 'rxjs';
import { ApiProjectService } from '../../../services/api/api-project.service';
import { LICENCE_CONFIG_OPTION_I18N } from '../../global';
import { ContextRoutingService } from '../../../services/context-routing.service';
import { CmsContextService } from '../../../services/cms-context.service';

@Component({
  selector: 'kfd-licence-limit',
  templateUrl: './licence-limit.component.html',
  styleUrls: ['./licence-limit.component.scss'],
  standalone: false,
})
export class LicenceLimitComponent {
  @Output()
  public limitReached = new EventEmitter<boolean>();

  @Output()
  public pageChange = new EventEmitter<void>();

  @Input()
  disabled = false;

  @Input()
  showIfLessThan = 5;

  protected licenceLimit$: Observable<LicenceLimitValue> | undefined;
  protected licenceOptionLabel = '';
  protected showMessage = true;
  private licenceConfigOption: LicenceConfigOptions | undefined;

  constructor(
    public contextRoutingService: ContextRoutingService,
    private projectService: ApiProjectService,
    private ctx: CmsContextService,
  ) {}

  @Input()
  public set configOption(value: LicenceConfigOptions) {
    this.licenceConfigOption = value;
    this.licenceOptionLabel = LICENCE_CONFIG_OPTION_I18N[value];
    this.licenceLimit$ = this.projectService.getLicenceLimit(this.ctx.projectId, this.licenceConfigOption).pipe(
      tap((licenceLimit) => {
        this.limitReached.emit(licenceLimit.limitReached);
      }),
    );
  }

  public showInfo() {
    this.contextRoutingService.toProjectSettings(undefined, CMS_APP_ROUTE_PARTS_PROJECT_SETTINGS.MEMBERSHIP);
    this.pageChange.emit();
  }
}
