import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AliasRefComponent } from './components/alias-ref/alias-ref.component';
import { TagInputComponent } from './components/tag-input/tag-input.component';
import { LabelComponent } from './components/label/label.component';
import { DataValueDialogComponent } from './components/data-value-dialog/data-value-dialog.component';
import { DeleteConfirmBtnComponent } from './components/delete-confirm-btn/delete-confirm-btn.component';
import { AliasRefDialogComponent } from './components/alias-ref-dialog/alias-ref-dialog.component';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DividerModule } from 'primeng/divider';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { MenuModule } from 'primeng/menu';
import { MessagesModule } from 'primeng/messages';
import { PanelModule } from 'primeng/panel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitterModule } from 'primeng/splitter';
import { TableModule } from 'primeng/table';
import { TreeModule } from 'primeng/tree';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShowForScopesDirective } from './directives/show-for-scopes.directive';
import { ConfirmationCodeComponent } from './components/confirmation-code/confirmation-code.component';
import { ConfigService, DocuBtnComponent, LoaderComponent, WEB_CONFIG_SERVICE, WebCoreModule } from '@kfd/web-core';
import { environment } from '../../environments/environment';
import { DataValueSelectComponent } from './components/data-value-select/data-value-select.component';
import { SideNavigationComponent } from './components/app-switcher/side-navigation.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { DataValueTypeComponent } from './components/data-value-type/data-value-type.component';
import { TagModule } from 'primeng/tag';
import { UniqueNameCheckComponent } from './components/unique-name-check/unique-name-check.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { BadgeModule } from 'primeng/badge';
import { ReloadBtnComponent } from './components/reload-btn/reload-btn.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ResponsiveMenuComponent } from './components/responsive-menu/responsive-menu.component';
import { SpeedDialModule } from 'primeng/speeddial';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { HeaderToolbarComponent } from './components/header-toolbar/header-toolbar.component';
import { ProjectSelectionComponent } from './components/mandant-selection/project-selection.component';
import { ProjectSelectionDialogComponent } from './components/mandant-selection-dialog/project-selection-dialog.component';
import { LicenceLimitComponent } from './components/licence-limit/licence-limit.component';
import { SplitRowComponent } from './components/split-row/split-row.component';
import { ShowConfigurationDialogComponent } from './components/show-configuration-dialog/show-configuration-dialog.component';
import { CfgModule } from '@kfd/cfg-core';
import { MultiSelectModule } from 'primeng/multiselect';
import { EmptyMsgComponent } from './components/empty-msg/empty-msg.component';
import { HasScopePipe } from './pipes/has-scope.pipe';
import { IsAdminPipe } from './pipes/is-admin.pipe';
import { UserFeaturePipe } from './pipes/user-feature.pipe';
import { DialogModule } from 'primeng/dialog';
import { UserNamePipe } from './pipes/user-name.pipe';
import { LoadingPipe } from './pipes/loading.pipe';
import { CfgNamePipe } from './pipes/cfg-name.pipe';
import { RequestStatusNamePipe } from './pipes/request-status-name.pipe';
import { ZoomDirective } from './directives/zoom.directive';
import { InteractDragDirective } from './directives/interact-drag.directive';
import { InteractPointerDirective } from './directives/interact-pointer.directive';
import { ResizedDirective } from './directives/resized.directive';
import { DndScrollerComponent } from './components/dnd-scroller/dnd-scroller.component';
import { ValidNameDirective } from './directives/valid-name.directive';
import { VisibleDirective } from './directives/visible.directive';
import { DataEntryBasicFormComponent } from './components/data-entry-basic-form/data-entry-basic-form.component';
import { App, Service } from '@kfd/core';
import { ImageModule } from 'primeng/image';
import { MetaViewComponent } from './components/meta-view/meta-view.component';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { LabelNameInputComponent } from './components/label-name-input/label-name-input.component';
import { BasedataTemplateSelectionComponent } from './components/basedata-template-selection/basedata-template-selection.component';
import { SortInputComponent } from './components/sort-input/sort-input.component';
import { FullscreenLayoutComponent } from './components/fullscreen-layout/fullscreen-layout.component';
import { OnlyAdminDirective } from './directives/only-admin.directive';
import { StepperModule } from 'primeng/stepper';
import { TextareaModule } from 'primeng/textarea';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RouterModule } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { UserSettingPipe } from './pipes/user-setting.pipe';
import { CfgDraftViewComponent } from './components/cfg-draft-view/cfg-draft-view.component';
import { TabsModule } from 'primeng/tabs';
import { PopoverModule } from 'primeng/popover';
import { SelectModule } from 'primeng/select';
import { DatePickerModule } from 'primeng/datepicker';
import { OverlayBadgeModule } from 'primeng/overlaybadge';
import { AssetViewerComponent } from './components/asset-viewer/asset-viewer.component';

const primengImExports = [
  AutoCompleteModule,
  AvatarModule,
  BadgeModule,
  ButtonModule,
  BreadcrumbModule,
  DatePickerModule,
  CardModule,
  CheckboxModule,
  ChipModule,
  ContextMenuModule,
  DividerModule,
  DialogModule,
  SelectModule,
  DynamicDialogModule,
  FieldsetModule,
  ImageModule,
  InputGroupModule,
  InputGroupAddonModule,
  InputNumberModule,
  InputSwitchModule,
  InputTextModule,
  TextareaModule,
  MessageModule,
  MessagesModule,
  MultiSelectModule,
  MenuModule,
  OverlayPanelModule,
  OverlayBadgeModule,
  PanelModule,
  PopoverModule,
  ProgressSpinnerModule,
  RadioButtonModule,
  SelectButtonModule,
  SpeedDialModule,
  SplitterModule,
  StepperModule,
  TabsModule,
  TableModule,
  TagModule,
  TreeModule,
  ToastModule,
  ToggleButtonModule,
  InputSwitchModule,
  ToolbarModule,
  TooltipModule,
  ConfirmDialogModule,
];

const components = [
  DeleteConfirmBtnComponent,
  DndScrollerComponent,
  AliasRefComponent,
  AliasRefDialogComponent,
  AssetViewerComponent,
  BasedataTemplateSelectionComponent,
  FullscreenLayoutComponent,
  TagInputComponent,
  LabelComponent,
  DataValueDialogComponent,
  DataEntryBasicFormComponent,
  ConfirmationCodeComponent,
  DataValueSelectComponent,
  SideNavigationComponent,
  UserInfoComponent,
  DataValueTypeComponent,
  UniqueNameCheckComponent,
  ReloadBtnComponent,
  ResponsiveMenuComponent,
  BreadcrumbComponent,
  HeaderToolbarComponent,
  ProjectSelectionComponent,
  ProjectSelectionDialogComponent,
  MetaViewComponent,
  LabelNameInputComponent,
  LicenceLimitComponent,
  SplitRowComponent,
  SortInputComponent,
  ShowConfigurationDialogComponent,
  EmptyMsgComponent,
];

const directives = [
  InteractDragDirective,
  InteractPointerDirective,
  ShowForScopesDirective,
  ResizedDirective,
  ValidNameDirective,
  VisibleDirective,
  ZoomDirective,
  OnlyAdminDirective,
];

const pipes = [
  CfgNamePipe,
  LoadingPipe,
  RequestStatusNamePipe,
  UserFeaturePipe,
  UserSettingPipe,
  UserNamePipe,
  IsAdminPipe,
  HasScopePipe,
];

@NgModule({
  declarations: [...components, ...pipes, ...directives],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    WebCoreModule,
    CfgModule.forRoot({}),
    ...primengImExports,
    RouterModule,
    DocuBtnComponent,
    LetDirective,
    CfgDraftViewComponent,
    LoaderComponent,
  ],
  providers: [
    {
      provide: WEB_CONFIG_SERVICE,
      useFactory: () => {
        const configService = new ConfigService();
        configService.sessionTimeout = environment.auth.sessionTimeout;
        configService.services = {
          [Service.CMS_SERVICE]: environment.services.cms,
          [Service.AUTH_SERVICE]: environment.services.auth,
          [Service.CFG_SERVICE]: environment.services.cfg,
          [Service.CONTENT_SERVICE]: environment.services.content,
        };
        configService.apps = {
          [App.CMS]: environment.apps.cms,
          [App.WEB]: environment.apps.web,
        };
        return configService;
      },
    },
  ],
  exports: [WebCoreModule, CfgModule, ...components, ...primengImExports, ...pipes, ...directives],
})
export class SharedModule {}
