import { Pipe, PipeTransform } from '@angular/core';
import { isObservable, Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

/**
 * @deprecated use `loading` pipe from `@kfd/web-core` instead
 */
@Pipe({
  name: 'loading',
  standalone: false,
})
export class LoadingPipe<T> implements PipeTransform {
  transform(val: T | undefined): Observable<{ value?: T; loading: boolean; error?: boolean }> {
    if (val === undefined) {
      return of({
        value: undefined,
        loading: true,
      });
    }
    return isObservable(val)
      ? (val.pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map((value: any) => ({ loading: false, value })),
          startWith({ loading: true }),
          catchError((error) => of({ loading: false, error })),
        ) as Observable<{ value?: T; loading: boolean }>)
      : of({
          value: val,
          loading: false,
        });
  }
}
