import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpInterceptor, provideHttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { SharedModule } from './shared/shared.module';
import { AppErrorHandler } from './app-error-handler';
import { FullscreenLoginDialogComponent } from './common/fullscreen-login-dialog/fullscreen-login-dialog.component';
import { SocketIoModule } from 'ngx-socket-io';
import { UserNotificationsComponent } from './common/user-notifications/user-notifications.component';
import { NotificationsDialogComponent } from './common/user-notifications/notifications-dialog/notifications-dialog.component';
import { UnhealthyDialogComponent } from './common/unhealthy-dialog/unhealthy-dialog.component';
import { CmsDialogService } from './services/cms-dialog.service';
import { UserSessionInterceptor } from './common/interceptor/user-session.interceptor';
import { ServerErrorInterceptor } from './common/interceptor/server-error.interceptor';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';
import {
  AuthInterceptor,
  AuthService,
  EventService,
  KfdTheme,
  LoaderComponent,
  UserIconComponent,
} from '@kfd/web-core';

@NgModule({
  declarations: [
    AppComponent,
    FullscreenLoginDialogComponent,
    UserNotificationsComponent,
    NotificationsDialogComponent,
    // ErrorReportingDialogComponent,
    // FormComponent,
    UnhealthyDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    SocketIoModule,
    UserIconComponent,
    LoaderComponent,
  ],
  providers: [
    provideAnimationsAsync(),
    provideHttpClient(),
    providePrimeNG({
      theme: {
        preset: KfdTheme,
        options: {
          //remove to enable dark mode by default
          darkModeSelector: '.p-dark',
        },
      },
    }),
    ConfirmationService,
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (
        eventService: EventService,
        cmsDialogService: CmsDialogService,
        authService: AuthService,
      ): HttpInterceptor =>
        new UserSessionInterceptor(eventService, cmsDialogService, authService, {
          urlsToIgnore: ['/api/auth'],
        }),
      multi: true,
      deps: [EventService, CmsDialogService, AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (eventService: EventService, cmsDialogService: CmsDialogService): HttpInterceptor =>
        new ServerErrorInterceptor(eventService, cmsDialogService),
      multi: true,
      deps: [EventService, CmsDialogService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
