import { Component, OnInit } from '@angular/core';
import { CMS_APP_ROUTES, ICON, UserNotification } from '@kfd/core';
import { NotificationService } from '../../services/notification.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'kfd-user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.scss'],
  standalone: false,
})
export class UserNotificationsComponent implements OnInit {
  protected readonly ICON = ICON;

  protected viewData$:
    | Observable<{
        notifications: UserNotification[];
      }>
    | undefined;
  protected readonly CMS_APP_ROUTES = CMS_APP_ROUTES;

  constructor(private notificationService: NotificationService) {
    this.viewData$ = this.notificationService.notificationsList().pipe(
      map((notifications) => ({
        notifications: notifications.filter((n) => !n.readDate),
      })),
    );
  }

  public ngOnInit() {
    this.notificationService.initialize();
  }

  protected markAsRead(id: string) {
    this.notificationService.markAsRead(id).subscribe();
  }
}
