import {
  CLS,
  CmsConfigurator,
  CmsField,
  CmsFieldGroup,
  CmsPage,
  Configuration,
  ConfiguratorField,
  ConfiguratorFieldGroup,
  ConfiguratorPage,
  Create,
  Field,
  FieldConfig,
  FieldGroup,
  NewEntry,
  Page,
} from '../../index';

export interface CfgBaseBuilder<T> {
  build(): T;
}

export class CfgBuilder implements CfgBaseBuilder<Configuration> {
  private readonly configuration: Configuration;

  private pageBuilderList: CfgPageBuilder[] = [];

  private constructor(configuration: Partial<Configuration> = {}) {
    this.configuration = {
      cls: CLS.CONFIGURATION,
      versions: {
        current: 1,
      },
      name: configuration.name || '',
      label: configuration.label || '',
      description: configuration.description || '',
      settings: configuration.settings || {
        cls: CLS.CONFIGURATION_SETTINGS,
      },
      children: [],
    };
  }

  public static create(configuration: Partial<Configuration> = {}): CfgBuilder {
    return new CfgBuilder(configuration);
  }

  public static toCms(
    configuration: Configuration,
    properties: {
      code: string;
    },
  ): NewEntry<CmsConfigurator> {
    return {
      ...configuration,
      code: properties.code,
      versions: {
        current: configuration.versions.current,
        drafted: 0,
        published: 0,
      },
      meta: Create.entryMeta(),
      children: [],
    };
  }

  public addPage(pageBuilder: CfgPageBuilder): CfgBuilder {
    this.pageBuilderList.push(pageBuilder);
    return this;
  }

  public build(): Configuration {
    const data = {
      ...this.configuration,
      children: this.pageBuilderList.map((pageBuilder) => pageBuilder.build()),
    };
    return data;
  }
}

export class CfgPageBuilder implements CfgBaseBuilder<ConfiguratorPage> {
  private configuratorPage: ConfiguratorPage;
  private childBuilderList: (CfgGroupBuilder | CfgFieldBuilder)[] = [];

  private constructor(pageConfig: Partial<Page> = {}) {
    this.configuratorPage = this.buildConfig(pageConfig);
  }

  public static create(page: Partial<Page> = {}): CfgPageBuilder {
    return new CfgPageBuilder(page);
  }

  public static toCmsPage(configuratorPage: ConfiguratorPage): NewEntry<CmsPage> {
    return {
      meta: Create.entryMeta(),
      ...configuratorPage.entry,
      children: [],
    };
  }

  public setConfig(pageConfig: Partial<Page> = {}): CfgPageBuilder {
    this.configuratorPage = this.buildConfig(pageConfig);
    return this;
  }

  public addGroup(groupBuilder: CfgGroupBuilder): CfgPageBuilder {
    this.childBuilderList.push(groupBuilder);
    return this;
  }

  public addField(fieldBuilder: CfgFieldBuilder): CfgPageBuilder {
    this.childBuilderList.push(fieldBuilder);
    return this;
  }

  public build(): ConfiguratorPage {
    return {
      ...this.configuratorPage,
      entry: {
        ...this.configuratorPage.entry,
        children: this.childBuilderList.map((builder) => builder.build()),
      },
    };
  }

  public toCmsPage(): NewEntry<CmsPage> {
    return CfgPageBuilder.toCmsPage(this.build());
  }

  private buildConfig(pageConfig: Partial<Page> = {}): ConfiguratorPage {
    const page: Page = {
      cls: CLS.PAGE,
      name: pageConfig.name || '',
      label: pageConfig.label || '',
      hideLabel: pageConfig.hideLabel || false,
      hide: pageConfig.hide || false,
      info: pageConfig.info || undefined,
      noNavNext: pageConfig.noNavNext || false,
      noNavPrev: pageConfig.noNavPrev || false,
      children: [],
    };
    return {
      cls: CLS.PAGE_WRAPPER,
      entry: page,
    };
  }
}

export class CfgGroupBuilder implements CfgBaseBuilder<ConfiguratorFieldGroup> {
  private configuratorFieldGroup: ConfiguratorFieldGroup;

  private constructor(groupConfig: Partial<FieldGroup> = {}) {
    this.configuratorFieldGroup = this.buildConfig(groupConfig);
  }

  public static create(fieldGroup: Partial<FieldGroup> = {}): CfgGroupBuilder {
    return new CfgGroupBuilder(fieldGroup);
  }

  public static toCmsFieldGroup(configuratorFieldGroup: ConfiguratorFieldGroup): NewEntry<CmsFieldGroup> {
    return {
      ...configuratorFieldGroup.entry,
      meta: Create.entryMeta(),
      children: [],
    };
  }

  public build(): ConfiguratorFieldGroup {
    return this.configuratorFieldGroup;
  }

  public toCmsFieldGroup(): NewEntry<CmsFieldGroup> {
    return CfgGroupBuilder.toCmsFieldGroup(this.build());
  }

  private buildConfig(pageConfig: Partial<FieldGroup> = {}): ConfiguratorFieldGroup {
    const group: FieldGroup = {
      cls: CLS.FIELD_GROUP,
      name: pageConfig.name || '',
      label: pageConfig.label || '',
      hideLabel: pageConfig.hideLabel || false,
      hide: pageConfig.hide || false,
      info: pageConfig.info || undefined,
      hint: pageConfig.hint || '',
      children: [],
    };
    return {
      cls: CLS.FIELD_GROUP_WRAPPER,
      entry: group,
    };
  }
}

export type BuilderFieldConfig = Partial<Field> & { config: FieldConfig };

export class CfgFieldBuilder implements CfgBaseBuilder<ConfiguratorField> {
  private configuratorField: ConfiguratorField;

  private constructor(fieldConfig: BuilderFieldConfig) {
    this.configuratorField = this.buildConfig(fieldConfig);
  }

  public static create(fieldConfig: BuilderFieldConfig): CfgFieldBuilder {
    return new CfgFieldBuilder(fieldConfig);
  }

  public static toCmsField(configuratorField: ConfiguratorField): NewEntry<CmsField> {
    return {
      ...configuratorField.entry,
      meta: Create.entryMeta(),
    };
  }

  public build(): ConfiguratorField {
    return this.configuratorField;
  }

  public toCmsField(): NewEntry<CmsField> {
    return CfgFieldBuilder.toCmsField(this.build());
  }

  private buildConfig(fieldConfig: BuilderFieldConfig): ConfiguratorField {
    const field: Field = {
      cls: CLS.FIELD,
      name: fieldConfig.name || '',
      label: fieldConfig.label || '',
      hideLabel: fieldConfig.hideLabel || false,
      hide: fieldConfig.hide || false,
      info: fieldConfig.info || undefined,
      config: fieldConfig.config,
    };
    return {
      cls: CLS.FIELD_WRAPPER,
      entry: field,
    };
  }
}
