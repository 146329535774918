<form (ngSubmit)="verify()">
  <p-inputgroup [styleClass]="vertical ? 'vertical' : null">
    <p-inputgroup-addon>
      <span class="code">{{ confirmationCode }}</span>
    </p-inputgroup-addon>
    <p-inputgroup-addon styleClass="input">
      <p-inputNumber
        [(ngModel)]="enteredCode"
        [disabled]="confirmed"
        name="confirmation-code"
        placeholder="Bestätigungscode"
      />
    </p-inputgroup-addon>
    <p-inputgroup-addon>
      <p-button
        (click)="verify()"
        [disabled]="confirmed"
        [label]="label"
        icon="pi pi-exclamation-triangle"
        severity="danger"
      ></p-button>
    </p-inputgroup-addon>
  </p-inputgroup>
  @if (invalidCode) {
    <small class="color-error">Der Bestätigungscode ist nicht korrekt.</small>
  } @else if (!enteredCode) {
    <small>Geben Sie den Bestätigungscode ein um fortzufahren.</small>

  }
</form>
