import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ClientInputError, ForbiddenError, NotFoundError, UnauthorizedError, UnknownError } from '../../index';
import { DeleteEndpoint, Endpoint, EndpointService, GetEndpoint, PostEndpoint, Service } from '@kfd/core';
import { catchError } from 'rxjs/operators';

export interface EndpointServiceOptions {
  services: Partial<Record<Service, string>>;
}

export const ENDPOINT_SERVICE_TOKEN = new InjectionToken<EndpointServiceOptions>('ENDPOINT_SERVICE_TOKEN');

export type QueryParams =
  | HttpParams
  | {
      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };

export interface RequestOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  params?: QueryParams;
  withCredentials?: boolean;
  noErrorHandling?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class WebEndpointService {
  protected endpointService: EndpointService;

  constructor(
    private httpClient: HttpClient,
    @Inject(ENDPOINT_SERVICE_TOKEN) private options: EndpointServiceOptions,
  ) {
    this.endpointService = new EndpointService(this.options.services);
  }

  /**
   * returns the absolute url for the given endpoint
   * @param endpoint
   * @param params
   */
  public url(endpoint: Endpoint<unknown>, params: (string | number)[] = []): string {
    return this.endpointService.absoluteUrl(endpoint, params).href;
  }

  public get<T>(
    endpoint: GetEndpoint<T>,
    params: (string | number)[] = [],
    options: RequestOptions = {},
  ): Observable<T> {
    let obs = this.httpClient.get<T>(this.endpointService.absoluteUrl(endpoint, params).href, {
      withCredentials: options.withCredentials ?? true,
      ...options,
    });

    if (options.noErrorHandling !== true) {
      obs = obs.pipe(catchError(this.handleError));
    }

    return obs;
  }

  public post<RequestBody, Response>(
    endpoint: PostEndpoint<RequestBody, Response>,
    params: (string | number)[] = [],
    body?: RequestBody,
    options: RequestOptions = {},
  ): Observable<Response> {
    return this.httpClient
      .post<Response>(this.endpointService.absoluteUrl(endpoint, params).href, body, {
        withCredentials: true,
        ...options,
      })
      .pipe(catchError(this.handleError));
  }

  public put<RequestBody, Response>(
    endpoint: PostEndpoint<RequestBody, Response>,
    params: (string | number)[] = [],
    body: RequestBody,
    options: RequestOptions = {},
  ): Observable<Response> {
    return this.httpClient
      .put<Response>(this.endpointService.absoluteUrl(endpoint, params).href, body, {
        withCredentials: true,
        ...options,
      })
      .pipe(catchError(this.handleError));
  }

  public delete<Response>(
    endpoint: DeleteEndpoint<Response>,
    params: (string | number)[] = [],
    options: RequestOptions = {},
  ): Observable<Response> {
    return this.httpClient
      .delete<Response>(this.endpointService.absoluteUrl(endpoint, params).href, {
        withCredentials: true,
        ...options,
      })
      .pipe(catchError(this.handleError));
  }

  protected handleError(response: unknown): Observable<never> {
    if (response instanceof HttpErrorResponse) {
      switch (response.status) {
        case 401:
          return throwError(() => new UnauthorizedError());
        case 403:
          return throwError(() => new ForbiddenError());
        case 404:
          return throwError(() => new NotFoundError());
        case 406:
          return throwError(() => new ClientInputError(response?.error?.message, response?.error?.code));
        default:
          return throwError(() => new UnknownError(response?.error?.message, response?.error?.code));
      }
    } else {
      return throwError(() => new UnknownError());
    }
  }
}
