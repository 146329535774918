<kfd-dialog-layout (reject)="close()" [loading]="loading" [showAcceptBtn]="true" dialogTitle="Konfiguration löschen">
  <div class="p-fluid" data-automationId="dialog-delete-cfg">

    @if (isPublished) {
      <p-message
        class="mb-2"
        data-automationId="dialog-delete-published-warning"
        severity="warn"
        text="Die Konfiguration wurde bereits publiziert"
      >
      </p-message>

      <p>Die Konfiguration wird in den Papierkorb verschoben und nach 14 Tagen unwiderruflich gelöscht.</p>
    } @else {
      <p-message
        class="mb-2"
        data-automationId="dialog-delete-unpublished-warning"
        severity="warn"
        text="Das Formular wird unwiderruflich gelöscht"
      >
      </p-message>
    }
    <p>Das Löschen umfasst die Konfiguration, sowie alle dazugehörigen Anfragen und Statistiken.</p>

    <kfd-confirmation-code
      (confirm)="remove()"
      data-automationId="confirmation-code"
      label="Konfiguration löschen"
    ></kfd-confirmation-code>
  </div>
</kfd-dialog-layout>
