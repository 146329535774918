import { Component, OnInit } from '@angular/core';
import { AuthService } from '@kfd/web-core';
import { map, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CMS_APP_ROUTE_PARTS_ROOT, COMMON_WEB_APP_ROUTES, ICON, JwtUser, SETTINGS_KEYS } from '@kfd/core';
import { LoginService } from './services/login.service';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { CmsMenuService } from './services/cms-menu.service';
import { ApiProjectUserService } from './services/api/api-project-user.service';

@Component({
  selector: 'kfd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit {
  protected readonly ICON = ICON;
  protected readonly CMS_APP_ROUTE_PARTS_ROOT = CMS_APP_ROUTE_PARTS_ROOT;
  protected viewData$:
    | Observable<{
        showNavigation: boolean;
        currentUser: JwtUser | undefined;
        menuItems: MenuItem[];
        metaMenuItems: MenuItem[];
      }>
    | undefined;

  protected readonly COMMON_WEB_APP_ROUTES = COMMON_WEB_APP_ROUTES;
  protected readonly SETTINGS_KEYS = SETTINGS_KEYS;

  constructor(
    private readonly authService: AuthService,
    private readonly loginService: LoginService,
    private readonly router: Router,
    private readonly apiProjectUserService: ApiProjectUserService,
    private readonly cmsMenuService: CmsMenuService,
  ) {}

  ngOnInit() {
    this.viewData$ = this.authService.onSignInChange().pipe(
      mergeMap((signedIn) => {
        if (!signedIn) {
          if (this.authService.signOutReason === 'time') {
            return this.loginService.showLogin('Aufgrund von Inaktivität wurden Sie automatisch abgemeldet.');
          }
        }
        return of(signedIn);
      }),
      mergeMap((signedIn) => (signedIn ? this.authService.currentUser() : of(undefined))),
      mergeMap((currentUser) =>
        this.cmsMenuService.getMenu(currentUser).pipe(
          map((menu) => ({
            showNavigation: menu.main.length > 0,
            currentUser,
            menuItems: menu.main,
            metaMenuItems: menu.meta,
          })),
        ),
      ),
      // map(([currentUser, menu]) => ({
      //   showNavigation: true,
      //   currentUser,
      //   menuItems: menu.main,
      //   metaMenuItems: menu.meta,
      // })),
    );
  }

  protected togglePreviewMode(enable: boolean): void {
    // this.userService.togglePreviewMode(enable);
    this.apiProjectUserService.saveSetting(SETTINGS_KEYS.FEATURE_PREVIEW, enable ? 'true' : 'false').subscribe({
      next: () => {
        // nothing to do
      },
    });
  }
}
