import { Injectable } from '@angular/core';
import { WebEndpointService } from '@kfd/web-core';
import { Observable } from 'rxjs';
import { cmsServiceEP, ExportData, ImportOverwrites } from '@kfd/core';

@Injectable({
  providedIn: 'root',
})
export class ImExportService {
  constructor(private readonly webEndpointService: WebEndpointService) {}

  public exportConfiguration(projectId: string, configurationId: string): Observable<ExportData> {
    return this.webEndpointService.get(cmsServiceEP.imexport.export, [projectId, configurationId]);
  }

  public import(
    projectId: string,
    configurationDataExport: ExportData,
    overwrites?: ImportOverwrites,
  ): Observable<typeof cmsServiceEP.imexport.import.response> {
    return this.webEndpointService.post(cmsServiceEP.imexport.import, [projectId], {
      export: configurationDataExport,
      overwrites,
    });
  }

  public validate(
    projectId: string,
    configurationDataExport: ExportData,
  ): Observable<typeof cmsServiceEP.imexport.validate.response> {
    return this.webEndpointService.post(cmsServiceEP.imexport.validate, [projectId], configurationDataExport);
  }
}
