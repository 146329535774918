import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Field, FIELD_TYPES, FormValue, ObjectUtil, unvTimeout, ValidationError } from '@kfd/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigurationStateService, ConfigurationValidationService } from '../../service';

@Component({
  selector: 'kfd-configurator-field',
  templateUrl: './configurator-field.component.html',
  styleUrls: ['./configurator-field.component.scss'],
})
export class ConfiguratorFieldComponent {
  protected value$: Observable<FormValue | undefined> = of(undefined);
  protected validationError$: Observable<ValidationError | undefined> = of(undefined);
  protected fieldTypes = FIELD_TYPES;
  // indicates if the field has been changed by the user
  private dirty = false;

  constructor(
    private readonly configurationStateService: ConfigurationStateService,
    private readonly configurationValidationService: ConfigurationValidationService,
    private ref: ChangeDetectorRef,
  ) {}

  private _field: Field | undefined;

  get field(): Field | undefined {
    return this._field;
  }

  @Input()
  set field(field: Field | undefined) {
    if (!field || !field.config) {
      return;
    }
    if (this._field !== undefined && ObjectUtil.equals(this._field, field)) {
      return;
    }

    this._field = ObjectUtil.clone(field);
    this.value$ = of(undefined);
    //run init to ensure the value will be reinitialized for example when setting default value in editor
    unvTimeout(() => {
      this.init();
      this.ref.detectChanges();
    }, 0);
  }

  protected onValueChange(value: FormValue) {
    if (!this.field) {
      return;
    }
    this.dirty = true;
    this.configurationStateService.setValue(this.field.name, value);
  }

  private init(): void {
    this.dirty = false;
    const fieldName = this._field?.name;
    if (!fieldName) {
      return;
    }
    this.validationError$ = this.configurationValidationService
      .onValidationFor(fieldName)
      .pipe(map((validationResult) => validationResult.validation));

    this.value$ = this.configurationStateService.onValueChange(fieldName).pipe(
      map((value) => {
        if (this.dirty) {
          this.configurationValidationService.validateField(fieldName);
        }
        return value;
      }),
    );
  }
}
