import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject, combineLatestWith, mergeMap, Observable, switchMap, tap } from 'rxjs';
import { ArrayUtil, ICON, MyProject, SETTINGS_KEYS, USER_SCOPES } from '@kfd/core';
import { ApiProjectService } from '../../../services/api/api-project.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiProjectUserService } from '../../../services/api/api-project-user.service';

interface SelectionProject extends MyProject {
  favorite: boolean;
  joined: boolean;
}

@Component({
  selector: 'kfd-project-selection',
  templateUrl: './project-selection.component.html',
  styleUrls: ['./project-selection.component.scss'],
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSelectionComponent implements OnInit {
  @Output()
  public selection = new EventEmitter<MyProject>();
  @Output()
  public listChange = new EventEmitter<MyProject[]>();
  @Input()
  public scopes: USER_SCOPES[] = [];
  @Input()
  public excludeScopes: USER_SCOPES[] = [];
  @Input()
  public navigateOnSelection = false;
  @Input()
  public onlyFavorites = false;
  protected refreshProjects = new BehaviorSubject<true>(true);
  protected projects$: Observable<SelectionProject[]> | undefined;

  protected readonly ICON = ICON;

  constructor(
    private readonly router: Router,
    private projectService: ApiProjectService,
    private readonly apiProjectUserService: ApiProjectUserService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    this.projects$ = this.refreshProjects.pipe(
      //switch map ensure that the data will be reloaded on refresh projects trigger
      switchMap(() =>
        this.projectService
          .listProjects()
          .pipe(combineLatestWith(this.apiProjectUserService.getFavoriteProjects(false))),
      ),
      map(([projects, favorites]) =>
        projects.map((project) => ({
          ...project,
          favorite: favorites.includes(project._id.toString()),
          joined: project.scopes.some((scopes) => !scopes.includes(USER_SCOPES.OWNER)),
        })),
      ),
      tap((projects) => this.listChange.emit(projects)),
    );
  }

  ngOnInit(): void {
    this.loadProjects();
  }

  public loadProjects(): void {
    this.refreshProjects.next(true);
  }

  protected select(project: MyProject): void {
    if (this.navigateOnSelection) {
      this.router.navigate([`/project/${project._id}`]);
    }
    this.selection.emit(project);
  }

  protected filterAndSort(projects: SelectionProject[]): SelectionProject[] {
    let projectList = projects.sort((a, b) => a.name.localeCompare(b.name));
    if (this.onlyFavorites) {
      const favoriteList = projectList.filter((p) => p.favorite);
      if (favoriteList.length > 4) {
        return favoriteList;
      }
      const otherProjects = projectList.filter((p) => !p.favorite);
      return [...favoriteList, ...otherProjects.slice(0, 4 - favoriteList.length)];
    }

    projectList = projects.sort((a, b) => (a.favorite && !b.favorite ? -1 : !a.favorite && b.favorite ? 1 : 0));

    return projectList.filter((project) => {
      if (this.scopes.length > 0 && !project.scopes.some((scope) => this.scopes.includes(scope))) {
        return false;
      }

      if (this.excludeScopes.length > 0 && !project.scopes.some((scope) => !this.excludeScopes.includes(scope))) {
        return false;
      }

      return true;
    });
  }

  protected removeFavorite(project: SelectionProject): void {
    this.apiProjectUserService
      .getFavoriteProjects()
      .pipe(
        map((favorites) => ArrayUtil.removeItem(favorites, project._id.toString())),
        mergeMap((favorites) =>
          favorites.length > 0
            ? this.apiProjectUserService.saveSetting(SETTINGS_KEYS.FAVORITE_PROJECTS, favorites.join(','))
            : this.apiProjectUserService.removeSetting(SETTINGS_KEYS.FAVORITE_PROJECTS),
        ),
      )
      .subscribe({
        next: () => {
          project.favorite = false;
          this.changeDetectorRef.markForCheck();
        },
      });
  }

  protected addFavorite(project: SelectionProject): void {
    this.apiProjectUserService
      .getFavoriteProjects()
      .pipe(
        map((favorites) => ArrayUtil.pushUnique(favorites, project._id.toString())),
        mergeMap((favorites) =>
          this.apiProjectUserService.saveSetting(SETTINGS_KEYS.FAVORITE_PROJECTS, favorites.join(',')),
        ),
      )
      .subscribe({
        next: () => {
          project.favorite = true;
          this.changeDetectorRef.markForCheck();
        },
      });
  }
}
