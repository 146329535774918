@if (viewData$ | async; as viewData) {
  <p-overlaybadge [severity]="viewData.notifications.length > 0 ? 'warn' : 'secondary'"
                  [value]="viewData.notifications.length > 0 ? viewData.notifications.length : ''">
    <p-button
      (click)="op.toggle($event)"
      [outlined]="true"
      [rounded]="true"
      icon="pi {{ ICON.NOTIFICATION }}"
    ></p-button>
  </p-overlaybadge>

  <p-popover #op styleClass="allow-full-height">

    @if (viewData.notifications.length > 0) {
      <kfd-placeholder-msg
        [infoIcon]="ICON.NOTIFICATION"
        [infoTitle]="'Es gibt ' + viewData.notifications.length + ' ungelesene Nachricht' + (viewData.notifications.length > 1 ? 'en' : '')"
      ></kfd-placeholder-msg>
      @for (notification of viewData.notifications; track notification._id) {
        <div class="notification" [ngClass]="{unread: !notification.readDate}">
          <div class="flex-1 flex">
            <div class="content flex-1">
              <div>
                <span class="text-xs color-disabled">
                  {{ notification.date | date: 'short' }}
                </span>
                <span class="font-medium mt-1">
                  - {{ notification.subject }}
                </span>
              </div>
              <div class="text-sm mt-2">
                {{ notification.message }}
              </div>
            </div>
            <div class="flex justify-content-end align-items-center ml-4">
              @if (notification.link) {
                <p-button
                  [label]="notification.link.text"
                  [routerLink]="notification.link.target"
                  [icon]="ICON.LINK"
                  [outlined]="true"
                  [rounded]="true"
                  (click)="markAsRead(notification._id)"
                ></p-button>
              }
              @if (!notification.readDate) {
                <p-button
                  [icon]="ICON.ACCEPT"
                  class="ml-2"
                  pTooltip="Als gelesen markieren"
                  [rounded]="true"
                  [outlined]="true"
                  (click)="markAsRead(notification._id)"
                ></p-button>
              }
            </div>
          </div>
        </div>
      }
    } @else {
      <kfd-placeholder-msg
        [infoIcon]="ICON.NOTIFICATION"
        infoText="Keine neuen Nachrichten"
      ></kfd-placeholder-msg>
    }

    <div class="mt-4 flex justify-content-center">
      <p-button
        label="Alle anzeigen"
        [routerLink]="CMS_APP_ROUTES.main.notifications.path"
        [icon]="ICON.LINK"
        [outlined]="true"
        [rounded]="true"
        (click)="op.hide()"
      ></p-button>
    </div>
  </p-popover>

}
