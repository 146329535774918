import { Injectable } from '@angular/core';
import { FeCache, WebEndpointService } from '@kfd/web-core';
import { BaseDataTemplate, BaseDataTemplateValue, cmsServiceEP, Id, SelectOption } from '@kfd/core';
import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseDataService } from '../base-data.service';

@Injectable({
  providedIn: 'root',
})
export class ApiBaseDataTemplateService {
  private cache = new FeCache();

  constructor(
    private readonly webEndpointService: WebEndpointService,
    private readonly baseDataService: BaseDataService,
  ) {}

  public createBaseDataTemplate(
    projectId: Id,
    data: typeof cmsServiceEP.baseDataTemplate.create.requestBody,
  ): Observable<typeof cmsServiceEP.baseDataTemplate.create.response> {
    return this.webEndpointService
      .post(cmsServiceEP.baseDataTemplate.create, [projectId.toString()], data)
      .pipe(tap(() => this.cache.clear()));
  }

  public updateBaseDataTemplate(
    projectId: Id,
    oldTemplateName: string,
    data: typeof cmsServiceEP.baseDataTemplate.update.requestBody,
  ): Observable<typeof cmsServiceEP.baseDataTemplate.update.response> {
    return this.webEndpointService.put(
      cmsServiceEP.baseDataTemplate.update,
      [projectId.toString(), oldTemplateName],
      data,
    );
  }

  public listBaseDataTemplates(
    projectId: Id,
    useCache = false,
  ): Observable<typeof cmsServiceEP.baseDataTemplate.list.response> {
    const key = 'base-data-tpl-' + projectId;
    const obs$ = this.webEndpointService.get(cmsServiceEP.baseDataTemplate.list, [projectId.toString()]);
    return useCache === true ? this.cache.cacheObs(obs$, key) : obs$;
  }

  public getBaseDataTemplate(projectId: Id, name: string, useCache = false): Observable<BaseDataTemplate | undefined> {
    return this.listBaseDataTemplates(projectId, useCache).pipe(
      map((templates) => templates.find((tpl) => tpl.name === name)),
    );
  }

  public getTemplateValues(
    projectId: Id,
    name: string,
    useCache = false,
  ): Observable<SelectOption<BaseDataTemplateValue>[]> {
    return this.getBaseDataTemplate(projectId, name, useCache).pipe(
      map(
        (tpl) =>
          tpl?.values.map((value) => ({
            value: value,
            identifier: value.name,
            label: value.label,
          })) ?? [],
      ),
    );
  }

  public deleteBaseDataTemplates(
    projectId: Id,
    name: string,
  ): Observable<typeof cmsServiceEP.baseDataTemplate.delete.response> {
    return this.webEndpointService.delete(cmsServiceEP.baseDataTemplate.delete, [projectId.toString(), name]);
  }
}
