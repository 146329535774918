import { Component } from '@angular/core';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LoggingService } from '../../../services/logging.service';
import { CfgStateService, PersistenceState } from '../../cfg-state.service';
import { CfgCalculation, Create, ICON, Is } from '@kfd/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { LABEL_NAME_CONTROLS } from '../../../shared/components/label-name-input/label-name-input.component';
import { of } from 'rxjs';

@Component({
  selector: 'kfd-calculation-manager',
  templateUrl: './calculation-manager.component.html',
  styleUrl: './calculation-manager.component.scss',
})
export class CalculationManagerComponent extends BaseDialogComponent {
  protected readonly ICON = ICON;
  protected selectionMode = false;

  protected calculationToEdit: CfgCalculation | undefined;
  // defines the calculation to highlight in the list
  protected calculationToHighlight: string | undefined;
  protected selectedCalculation: string | undefined;
  protected calculationToEditValid = false;
  protected cfgCalculationList: CfgCalculation[] = [];
  protected labelNameForm: FormGroup | undefined;

  constructor(
    protected readonly dialogRef: DynamicDialogRef,
    protected readonly dialogConfig: DynamicDialogConfig,
    protected readonly formBuilder: FormBuilder,
    protected readonly loggingService: LoggingService,
    protected readonly cfgStateService: CfgStateService,
  ) {
    super(dialogRef, dialogConfig);
    this.cfgCalculationList = this.cfgStateService.getCfgUtil().getCfg().calculations ?? [];
    this.selectionMode = this.dialogConfig.data.selectionMode === true;
    if (Is.calculationRef(this.dialogConfig.data.calculation)) {
      this.calculationToHighlight = this.dialogConfig.data.calculation.name;
      this.selectedCalculation = this.dialogConfig.data.calculation.name;
    }
  }

  protected createCalculation() {
    this.calculationToEdit = {
      name: '',
      label: '',
      calculation: Create.calculation(),
    };
    this.labelNameForm = this.formBuilder.group({
      ...LABEL_NAME_CONTROLS(this.valueNameUniqueValidator.bind(this)),
    });
  }

  protected editCalculation(cfgCalculation: CfgCalculation) {
    this.calculationToEdit = cfgCalculation;
    this.calculationToHighlight = cfgCalculation.name;
    this.labelNameForm = this.formBuilder.group({
      ...LABEL_NAME_CONTROLS(this.valueNameUniqueValidator.bind(this), cfgCalculation.label, cfgCalculation.name),
    });
  }

  protected deleteCalculation(cfgCalculation: CfgCalculation) {
    this.cfgCalculationList = this.cfgCalculationList.filter((calculation) => calculation.name !== cfgCalculation.name);
    this.save();
  }

  protected saveChanges(): void {
    if (!this.calculationToEdit) {
      return;
    }
    const updatedCalculation = {
      ...this.calculationToEdit,
      label: this.labelNameForm?.get('label')?.value,
      name: this.labelNameForm?.get('name')?.value,
    };
    const existing = this.cfgCalculationList.find((calculation) => calculation.name === this.calculationToEdit.name);
    if (existing) {
      this.cfgCalculationList = this.cfgCalculationList.map((calculation) => {
        if (calculation.name === this.calculationToEdit.name) {
          return updatedCalculation;
        }
        return calculation;
      });
    } else {
      this.cfgCalculationList.push(updatedCalculation);
    }
    this.calculationToEdit = undefined;
    this.calculationToHighlight = updatedCalculation.name;
    this.save();
  }

  protected save(): void {
    const configuration = this.cfgStateService.getCfgUtil().getCfg();
    this.loading = true;
    configuration.calculations = this.cfgCalculationList;
    this.cfgStateService.changeConfiguration(configuration);
    this.cfgStateService.onPersistenceChange.subscribe((state) => {
      if (state === PersistenceState.FINISHED) {
        this.loading = false;
      }
    });
  }

  protected selectCalculation(cfgCalculation: CfgCalculation) {
    this.close(Create.calculationRef(cfgCalculation.name));
  }

  private valueNameUniqueValidator(control: AbstractControl): ValidationErrors | null {
    // console.log(control);
    if (!control.value) {
      return of(null);
    }
    const name = control.value.toString();
    if (name.length < 3) {
      return of(null);
    }
    // console.log(this.calculationToEdit.name, name);
    if (this.calculationToEdit.name === name) {
      return of(null);
    }
    if (this.cfgCalculationList.find((cfgCalculation) => cfgCalculation.name === name)) {
      return of({ invalidValue: true, message: 'Der Name wird bereits verwendet.' });
    }
    return of(null);
  }
}
