import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CmsContextService } from '../../services/cms-context.service';
import { ApiProjectService } from '../../services/api/api-project.service';

/**
 * usage
 * [title]="configuration._id | cfgname | async"
 */
@Pipe({
  name: 'reqStatName',
  standalone: false,
})
export class RequestStatusNamePipe implements PipeTransform {
  constructor(
    private mandantService: ApiProjectService,
    private ctx: CmsContextService,
  ) {}

  transform(value: string, emptyString = undefined): Observable<string | undefined> {
    if (value === undefined || value === '') {
      return of(emptyString);
    }
    return this.mandantService.getRequestStatus().pipe(
      map((statusList) => {
        const entry = statusList.find((requestStatus) => requestStatus.value === value);

        if (!entry) {
          return value;
        }
        return entry.label;
      }),
    );
  }
}
