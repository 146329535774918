import { Component, Input, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';

@Component({
  selector: 'kfd-header-toolbar',
  templateUrl: './header-toolbar.component.html',
  styleUrls: ['./header-toolbar.component.scss'],
  standalone: false,
})
export class HeaderToolbarComponent {
  @ViewChild('headerMenu') public headerMenu: ContextMenu | undefined;

  @Input()
  headline = '';

  @Input()
  subTitle = '';

  @Input()
  docuPage = '';

  @Input()
  docuSection = '';

  @Input()
  showDocu = true;

  @Input()
  linkTarget = '';

  @Input()
  menuItems: MenuItem[];
}
