import { DeleteEndpoint, GetEndpoint, PostEndpoint, PutEndpoint, Service } from './endpoints';
import {
  ApiMandantForMembers,
  Asset,
  AssetUsage,
  BaseDataTemplate,
  CmsConfigurator,
  CmsLicence,
  CmsProjectCI,
  CmsSelectionData,
  ConditionGroup,
  CreateAssetData,
  CreateBaseData,
  CreateConfigurationData,
  CreateProjectData,
  CustomerRequest,
  DataEntryUsage,
  DBUser,
  ExportData,
  FormValueRecord,
  ImportDataBody,
  ImportResult,
  InsertResult,
  LicenceOptions,
  LogData,
  MyProject,
  Notification,
  ProjectInfo,
  PublishedConfigurationEntry,
  REQ_PARAMS,
  SelectOption,
  UserSetting,
} from '../dtos';
import { CFG_CTRL_EP } from './cfg-service.endpoints';
import { StatusLogBody } from './params';

export enum CMS_CTRL_EP {
  CONFIGURATIONS = 'configurations',
  BASE_DATA = 'base-data',
  PROJECTS = 'projects',
  CUSTOMER_REQUESTS = 'customer-requests',
  LOGS = 'logs',
  ASSETS = 'assets',
  REQUESTS = 'requests',
  IMEXPORT = 'im-export',
  NOTIFICATIONS = 'notifications',
}

export const cmsServiceEP = {
  project: {
    /**
     * creates a new project
     */
    create: new PostEndpoint<CreateProjectData, InsertResult>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/`,
      null as unknown as CreateProjectData,
      null as unknown as InsertResult,
    ),
    /**
     * returns a list of projects for the current user
     */
    getMyProjects: new GetEndpoint<MyProject[]>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}`,
      null as unknown as MyProject[],
    ),
    /**
     * returns a specific project
     */
    getSingle: new GetEndpoint<ApiMandantForMembers>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}`,
      null as unknown as ApiMandantForMembers,
    ),
    /**
     * returns a specific project
     */
    setCorporateIdentity: new PutEndpoint<CmsProjectCI, boolean>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/corporate-identity`,
      null as unknown as CmsProjectCI,
      null as unknown as boolean,
    ),
  },
  log: {
    /**
     * adds a log message
     */
    addLog: new PostEndpoint<LogData, void>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.LOGS}/`,
      null as unknown as LogData,
      null as unknown as void,
    ),
  },
  admin: {
    /**
     * returns a user by id
     */
    getUser: new GetEndpoint<DBUser | undefined>(
      Service.CMS_SERVICE,
      `/admin/users/:${REQ_PARAMS.ENTRY_ID}`,
      null as unknown as DBUser | undefined,
    ),
    /**
     * returns a user by id
     */
    searchUser: new GetEndpoint<DBUser | undefined>(
      Service.CMS_SERVICE,
      `/admin/users/search/:${REQ_PARAMS.ENTRY_ID}`,
      null as unknown as DBUser | undefined,
    ),
    /**
     * updates project info
     */
    updateUser: new PutEndpoint<DBUser, boolean>(
      Service.CMS_SERVICE,
      `/admin/users/:${REQ_PARAMS.ENTRY_ID}`,
      null as unknown as DBUser,
      null as unknown as boolean,
    ),
    /**
     * sets a new project licence
     */
    setProjectLicence: new PostEndpoint<LicenceOptions, boolean>(
      Service.CMS_SERVICE,
      `/admin/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/licence`,
      null as unknown as LicenceOptions,
      null as unknown as boolean,
    ),
    /**
     * updates project info
     */
    updateProjectInfo: new PutEndpoint<ProjectInfo, boolean>(
      Service.CMS_SERVICE,
      `/admin/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/info`,
      null as unknown as ProjectInfo,
      null as unknown as boolean,
    ),
    /**
     * updates a (running) project licence
     */
    updateProjectLicence: new PutEndpoint<CmsLicence, boolean>(
      Service.CMS_SERVICE,
      `/admin/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/licence`,
      null as unknown as CmsLicence,
      null as unknown as boolean,
    ),
    /**
     * sets a project licence
     */
    republish: new PostEndpoint<void, boolean>(
      Service.CMS_SERVICE,
      `/admin/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.CONFIGURATIONS}/:${REQ_PARAMS.CONFIGURATION_ID}/republish`,
      null as unknown as void,
      null as unknown as boolean,
    ),
  },
  invitation: {
    /**
     * accepts a project invitation
     */
    accept: new PostEndpoint<void, void>(
      Service.CMS_SERVICE,
      `/invitation/:${REQ_PARAMS.INVITATION_ID}/accept`,
      null as unknown as void,
      null as unknown as void,
    ),
  },
  configuration: {
    /**
     * creates a new configuration
     */
    create: new PostEndpoint<CreateConfigurationData, InsertResult>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.CONFIGURATIONS}`,
      null as unknown as CreateConfigurationData,
      null as unknown as InsertResult,
    ),
    listProjectConfigurations: new GetEndpoint<CmsConfigurator[]>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.CONFIGURATIONS}`,
      null as unknown as CmsConfigurator[],
    ),
    countProjectConfigurations: new GetEndpoint<number>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.CONFIGURATIONS}/count`,
      null as unknown as number,
    ),
    saveEntryCondition: new PutEndpoint<ConditionGroup, boolean>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.CONFIGURATIONS}/:${REQ_PARAMS.CONFIGURATION_ID}/entry/:${REQ_PARAMS.ENTRY_ID}/child/:${REQ_PARAMS.CHILD_ENTRY_ID}/condition`,
      null as unknown as ConditionGroup,
      null as unknown as boolean,
    ),
    removeEntryCondition: new DeleteEndpoint<boolean>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.CONFIGURATIONS}/:${REQ_PARAMS.CONFIGURATION_ID}/entry/:${REQ_PARAMS.ENTRY_ID}/child/:${REQ_PARAMS.CHILD_ENTRY_ID}/condition`,
      null as unknown as boolean,
    ),
    // returns the current (resolved) cms-configuration for use in cms editor
    readLatestCmsConfiguration: new GetEndpoint<CmsConfigurator>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.CONFIGURATIONS}/:${REQ_PARAMS.CONFIGURATION_ID}`,
      null as unknown as CmsConfigurator,
    ),
    // returns the current (resolved) cms-configuration for use in cms editor
    readPublishedConfiguration: new GetEndpoint<PublishedConfigurationEntry>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.CONFIGURATIONS}/:${REQ_PARAMS.CONFIGURATION_ID}/published/:${REQ_PARAMS.VERSION}`,
      null as unknown as PublishedConfigurationEntry,
    ),
    toTrash: new PostEndpoint<void, boolean>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.CONFIGURATIONS}/:${REQ_PARAMS.CONFIGURATION_ID}/to-trash`,
      null as unknown as void,
      null as unknown as boolean,
    ),
    recycle: new PostEndpoint<void, boolean>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.CONFIGURATIONS}/:${REQ_PARAMS.CONFIGURATION_ID}/recycle`,
      null as unknown as void,
      null as unknown as boolean,
    ),
    delete: new DeleteEndpoint<void>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.CONFIGURATIONS}/:${REQ_PARAMS.CONFIGURATION_ID}`,
      null as unknown as void,
    ),
    // // returns the current configuration export for use in forms
    // readLatestConfigurationExport: new GetEndpoint<ConfigurationExportResponse>(
    //   Service.CMS_SERVICE,
    //   `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.CONFIGURATIONS}/:${REQ_PARAMS.CONFIGURATION_ID}/export`,
    //   null as unknown as ConfigurationExportResponse,
    // ),
  },
  user: {
    /**
     * returns a list of settings for the current users
     */
    settings: new GetEndpoint<UserSetting[]>(Service.CMS_SERVICE, `/user/settings`, null as unknown as UserSetting[]),
    saveSetting: new PostEndpoint<UserSetting, boolean>(
      Service.CMS_SERVICE,
      `/user/settings/:${REQ_PARAMS.KEY}`,
      null as unknown as UserSetting,
      null as unknown as boolean,
    ),
  },
  baseData: {
    create: new PostEndpoint<CreateBaseData, InsertResult>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.BASE_DATA}`,
      null as unknown as CreateBaseData,
      null as unknown as InsertResult,
    ),
    update: new PutEndpoint<CreateBaseData, boolean>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.BASE_DATA}/:${REQ_PARAMS.ENTRY_ID}`,
      null as unknown as CreateBaseData,
      null as unknown as boolean,
    ),
    list: new GetEndpoint<CmsSelectionData[]>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.BASE_DATA}`,
      null as unknown as CmsSelectionData[],
    ),
    count: new GetEndpoint<number>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.BASE_DATA}`,
      null as unknown as number,
    ),
    entryByName: new GetEndpoint<CmsSelectionData | undefined>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.BASE_DATA}/entry/:${REQ_PARAMS.ENTRY_ID}`,
      null as unknown as CmsSelectionData,
    ),
    entriesByNames: new GetEndpoint<CmsSelectionData[]>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.BASE_DATA}/names/:${REQ_PARAMS.KEY}`,
      null as unknown as CmsSelectionData[],
    ),
    dataByFilter: new GetEndpoint<CmsSelectionData[]>(
      Service.CMS_SERVICE,
      `/${CFG_CTRL_EP.PUBLISHED}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.BASE_DATA}/filter`,
      null as unknown as CmsSelectionData[],
    ),
    delete: new DeleteEndpoint<void>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.BASE_DATA}/:${REQ_PARAMS.ENTRY_ID}`,
      null as unknown as void,
    ),
    tagsList: new GetEndpoint<string[]>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.BASE_DATA}/tags`,
      null as unknown as string[],
    ),
    entryUsageInfo: new GetEndpoint<DataEntryUsage>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.BASE_DATA}/entry/:${REQ_PARAMS.KEY}/usage-info`,
      null as unknown as DataEntryUsage,
    ),
  },
  baseDataTemplate: {
    create: new PostEndpoint<BaseDataTemplate, boolean>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/base-data-templates`,
      null as unknown as BaseDataTemplate,
      null as unknown as boolean,
    ),
    update: new PutEndpoint<BaseDataTemplate, boolean>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/base-data-templates/:${REQ_PARAMS.KEY}`,
      null as unknown as BaseDataTemplate,
      null as unknown as boolean,
    ),
    list: new GetEndpoint<BaseDataTemplate[]>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/base-data-templates`,
      null as unknown as BaseDataTemplate[],
    ),
    delete: new DeleteEndpoint<boolean>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/base-data-templates/:${REQ_PARAMS.ENTRY_ID}`,
      null as unknown as boolean,
    ),
  },
  assets: {
    get: new GetEndpoint<Asset>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.ASSETS}/:${REQ_PARAMS.ENTRY_ID}`,
      null as unknown as Asset,
    ),
    list: new GetEndpoint<Asset[]>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.ASSETS}/`,
      null as unknown as Asset[],
    ),
    create: new PostEndpoint<CreateAssetData, InsertResult>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.ASSETS}/`,
      null as unknown as CreateAssetData,
      null as unknown as InsertResult,
    ),
    update: new PutEndpoint<CreateAssetData, boolean>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.ASSETS}/:${REQ_PARAMS.ENTRY_ID}`,
      null as unknown as CreateAssetData,
      null as unknown as boolean,
    ),
    usages: new GetEndpoint<AssetUsage>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.ASSETS}/:${REQ_PARAMS.ENTRY_ID}/usages`,
      null as unknown as AssetUsage,
    ),
    delete: new DeleteEndpoint<void>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.ASSETS}/:${REQ_PARAMS.ENTRY_ID}`,
      null as unknown as void,
    ),
    load: new GetEndpoint<void>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.ASSETS}/load/:${REQ_PARAMS.KEY}`,
      null as unknown as void,
    ),
    thumbnail: new GetEndpoint<void>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.ASSETS}/thumbnail/:${REQ_PARAMS.KEY}`,
      null as unknown as void,
    ),
  },
  customerRequests: {
    list: new GetEndpoint<CustomerRequest[]>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.CUSTOMER_REQUESTS}/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}`,
      null as unknown as CustomerRequest[],
    ),
    count: new GetEndpoint<number>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.CUSTOMER_REQUESTS}/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/count`,
      null as unknown as number,
    ),
    filterOptions: new GetEndpoint<SelectOption[]>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.CUSTOMER_REQUESTS}/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/filter-options/:${REQ_PARAMS.KEY}`,
      null as unknown as SelectOption[],
    ),
    details: new GetEndpoint<CustomerRequest>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.CUSTOMER_REQUESTS}/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/:${REQ_PARAMS.REQUEST_CODE}`,
      null as unknown as CustomerRequest,
    ),
    addStatusLogEntry: new PutEndpoint<StatusLogBody, boolean>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.CUSTOMER_REQUESTS}/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/:${REQ_PARAMS.REQUEST_CODE}/status-log`,
      null as unknown as StatusLogBody,
      null as unknown as boolean,
    ),
    updateFormData: new PutEndpoint<FormValueRecord, boolean>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.CUSTOMER_REQUESTS}/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/:${REQ_PARAMS.REQUEST_ID}/form-data`,
      null as unknown as FormValueRecord,
      null as unknown as boolean,
    ),
  },
  notifications: {
    list: new GetEndpoint<Notification[]>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.NOTIFICATIONS}`,
      null as unknown as Notification[],
    ),
    markAsRead: new PostEndpoint<void, boolean>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.NOTIFICATIONS}/:${REQ_PARAMS.ENTRY_ID}/mark-as-read`,
      null as unknown as void,
      null as unknown as boolean,
    ),
    markAllAsRead: new PostEndpoint<void, boolean>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.NOTIFICATIONS}/mark-all-as-read`,
      null as unknown as void,
      null as unknown as boolean,
    ),
  },
  imexport: {
    validate: new PostEndpoint<ExportData, ImportResult>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.IMEXPORT}/validate`,
      null as unknown as ExportData,
      null as unknown as ImportResult,
    ),
    export: new GetEndpoint<ExportData>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.IMEXPORT}/export/configuration/:${REQ_PARAMS.CONFIGURATION_ID}`,
      null as unknown as ExportData,
    ),
    import: new PostEndpoint<ImportDataBody, ImportResult>(
      Service.CMS_SERVICE,
      `/${CMS_CTRL_EP.PROJECTS}/:${REQ_PARAMS.PROJECT_ID}/${CMS_CTRL_EP.IMEXPORT}/import`,
      null as unknown as ImportDataBody,
      null as unknown as ImportResult,
    ),
  },
};
