import { Injectable } from '@angular/core';
import { CmsContextService } from './cms-context.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  ArrayUtil,
  CMS_APP_ROUTE_PARTS_PROJECT,
  CMS_APP_ROUTE_PARTS_PROJECT_SETTINGS,
  CMS_APP_ROUTE_PARTS_ROOT,
  CMS_APP_ROUTES,
  EndpointService,
  Id,
  REQ_PARAMS,
} from '@kfd/core';

@Injectable({
  providedIn: 'root',
})
export class ContextRoutingService {
  constructor(
    private ctx: CmsContextService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
  ) {}

  set editMode(value: boolean) {
    this.router.navigate([], {
      queryParams: {
        edit: value,
      },
    });
  }

  public toConfigurationEditMode(id: string): void {
    this.router.navigate(this.configurationBase(id), {
      queryParams: {
        edit: true,
      },
    });
  }

  public toConfiguration(id: Id, navigate = true): void {
    if (navigate) {
      this.router.navigate(this.configurationBase(id), {
        queryParams: this.activatedRoute.snapshot.queryParams,
      });
    } else {
      this.location.go(
        this.router
          .createUrlTree(this.configurationBase(id), {
            queryParams: this.activatedRoute.snapshot.queryParams,
          })
          .toString(),
      );
    }
  }

  public toConfigurationRoot(navigate = true): void {
    if (navigate) {
      this.router.navigate(this.configurationBase(this.ctx.configuratorId));
    } else {
      this.location.go(this.router.createUrlTree(this.configurationBase(this.ctx.configuratorId)).toString());
    }
  }

  public toConfigurationEntry(configuratorId: Id, entryId: Id, navigate = true): void {
    if (this.activatedRoute.snapshot.paramMap.has(REQ_PARAMS.ENTRY_ID)) {
      if (this.activatedRoute.snapshot.paramMap.get(REQ_PARAMS.ENTRY_ID) === entryId) {
        return;
      }
    }
    const newPath = [...this.configurationBase(configuratorId), entryId.toString()];
    if (navigate) {
      this.router.navigate(newPath, {
        queryParams: this.activatedRoute.snapshot.queryParams,
      });
    } else {
      this.location.go(
        this.router
          .createUrlTree(newPath, {
            queryParams: this.activatedRoute.snapshot.queryParams,
          })
          .toString(),
      );
    }
  }

  /**
   * @deprecated not used anymore
   * @param tabName
   */
  changeTab(tabName: string) {
    const target = this.configurationBase(this.ctx.configuratorId);
    if (this.ctx.hasEntryId()) {
      target.push(this.ctx.entryId);
    }
    target.push(tabName);
    this.router.navigate(target);
  }

  public toProjectSettings(projectId?: string, target?: CMS_APP_ROUTE_PARTS_PROJECT_SETTINGS): void {
    const targetProjectId = projectId || this.ctx.projectId;
    this.router.navigate([
      '/',
      CMS_APP_ROUTE_PARTS_ROOT.PROJECT,
      targetProjectId,
      CMS_APP_ROUTE_PARTS_PROJECT.SETTINGS,
      target,
    ]);
  }

  public toProjectConfigurations(projectId?: string): void {
    const targetProjectId = projectId || this.ctx.projectId;
    this.router.navigate(
      ArrayUtil.filterUndefined([
        '/',
        CMS_APP_ROUTE_PARTS_ROOT.PROJECT,
        targetProjectId,
        CMS_APP_ROUTE_PARTS_PROJECT.CONFIGURATIONS,
      ]),
    );
  }

  public toProjectBaseData(
    config: {
      projectId?: Id;
      entryId?: string;
      navigate?: boolean;
      queryParams?: { key: string; value: unknown }[];
    } = {},
  ): void {
    const targetProjectId = config.projectId || this.ctx.projectId;
    const target = config.entryId
      ? EndpointService.relativePath(CMS_APP_ROUTES.project.baseData.data.show.path, [targetProjectId, config.entryId])
      : EndpointService.relativePath(CMS_APP_ROUTES.project.baseData.data.root.path, [targetProjectId]);

    const queryParams = config.queryParams
      ? '?' + config.queryParams.map((qp) => `${qp.key}=${JSON.stringify(qp.value)}`).join('&')
      : '';
    if (config.navigate) {
      this.router.navigateByUrl(target + queryParams);
    } else {
      this.location.go(target + queryParams);
    }
  }

  public toProjectBaseDataTemplates(projectId?: Id, navigate?: boolean): void {
    const targetProjectId = projectId || this.ctx.projectId;
    const target = EndpointService.relativePath(CMS_APP_ROUTES.project.baseData.template.root.path, [targetProjectId]);
    if (navigate) {
      this.router.navigateByUrl(target);
    } else {
      this.location.go(target);
    }
  }

  public toCustomerRequests(
    config: {
      projectId?: Id;
      navigate?: boolean;
      queryParams?: { key: string; value: unknown }[];
    } = {},
  ): void {
    const targetProjectId = config.projectId || this.ctx.projectId;
    const target = EndpointService.relativePath(CMS_APP_ROUTES.project.customerRequests.root, [targetProjectId]);

    const queryParams = config.queryParams
      ? '?' + config.queryParams.map((qp) => `${qp.key}=${JSON.stringify(qp.value)}`).join('&')
      : '';
    if (config.navigate) {
      this.router.navigateByUrl(target + queryParams);
    } else {
      this.location.go(target + queryParams);
    }
  }

  private configurationBase(cfgId: Id): string[] {
    return [
      '/',
      CMS_APP_ROUTE_PARTS_ROOT.PROJECT,
      this.ctx.projectId,
      CMS_APP_ROUTE_PARTS_PROJECT.CONFIGURATIONS,
      'edit',
      cfgId.toString(),
    ];
  }
}
