import { Component, OnInit } from '@angular/core';
import { mergeMap, Observable, of } from 'rxjs';
import { CLS, CmsChildWrapper, CmsGenericEntry, ICON } from '@kfd/core';
import { CfgStateService } from '../cfg-state.service';
import { MenuItem } from 'primeng/api';
import { EntryMenuService } from './entry-menu.service';
import { CfgEditorService } from '../cfg-editor.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'kfd-cfg-settings',
  templateUrl: './settings-area.component.html',
  styleUrls: ['./settings-area.component.scss'],
})
export class SettingsAreaComponent implements OnInit {
  public menuItems: MenuItem[] = [];
  public cls = CLS;
  public icon = ICON;
  public isFirst = false;
  public isLast = false;
  public loading = false;
  protected viewData$:
    | Observable<{
        entry: CmsChildWrapper;
      }>
    | undefined;
  private updateEntryTimeout: number | undefined;

  constructor(
    private readonly cfgStateService: CfgStateService,
    private readonly cfgEditorService: CfgEditorService,
    private readonly entryMenuService: EntryMenuService,
  ) {}

  ngOnInit(): void {
    this.viewData$ = this.cfgEditorService.onSelectionChange.pipe(
      mergeMap((entry) => {
        this.loading = false;
        if (!entry) {
          return of(undefined);
        }
        const parentEntry = this.cfgStateService.getCfgUtil().getParentEntry(entry.name);
        this.updateMenu(entry, parentEntry?.name);

        this.isFirst = this.cfgStateService.getCfgUtil().isFirst(entry.name);
        this.isLast = this.cfgStateService.getCfgUtil().isLast(entry.name);

        this.loading = false;
        return this.cfgStateService.onEntryChange(entry.name);
      }),
      map((entry) => ({
        entry,
      })),
    );
  }

  updateMenu(entry: CmsGenericEntry, parentName: string) {
    if (!entry) {
      this.menuItems = [];
      return;
    }

    this.menuItems = this.entryMenuService.getMenu(entry, parentName);
  }

  public delete(entry: CmsGenericEntry): void {
    if (!entry._id) {
      this.cfgStateService.deleteEntry(entry.name);
      this.cfgEditorService.removeNewElement();
    }
  }

  public entryChange(entryName: string, entry: CmsGenericEntry): void {
    if (!entry._id) {
      this.loading = true;
    }
    if (this.updateEntryTimeout) {
      clearTimeout(this.updateEntryTimeout);
    }
    this.updateEntryTimeout = window.setTimeout(() => {
      this.cfgStateService.changeEntry(entryName, entry);
      this.cfgEditorService.selectEntry(entry.name);
      this.cfgEditorService.removeNewElement();
    }, 200);
  }
}
