<div data-automationId="sort-input">
  @if (fieldOptions.length === 0) {
    <div class="p-fluid flex justify-content-center">
      <i class="color-disabled">Keine Sortierungs-Optionen verfügbar</i>
    </div>
  } @else {
    @if (sort.length === 0) {
      <div class="p-fluid flex justify-content-center">
        <p-button
          icon="pi {{ ICON.ADD }}"
          label="Sortierung hinzufügen"
          [rounded]="true"
          [outlined]="true"
          (click)="addSort()"
        ></p-button>
      </div>
    } @else {
      @for (item of sort; track item.field) {
        <div class="flex">
          <p-select
            class="flex-1 mr-1"
            appendTo="body"
            [options]="fieldOptions"
            [(ngModel)]="item.field"
            (onChange)="onSortChange()"
          ></p-select>
          <p-selectButton
            [options]="directionOptions"
            [(ngModel)]="item.direction"
            [allowEmpty]="false"
            (onChange)="onSortChange()"
          ></p-selectButton>
          <p-button icon="pi {{ ICON.DELETE }}" [text]="true" (click)="removeSort(item.field)"></p-button>
        </div>
      }
      <div class="flex justify-content-end">
        <p-button
          icon="pi {{ ICON.ADD }}"
          [disabled]="sort.length >= fieldOptions.length"
          [rounded]="true"
          [outlined]="true"
          (click)="addSort()"
        ></p-button>
      </div>
    }
  }
</div>
