import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of, zip } from 'rxjs';
import { AuthService } from '@kfd/web-core';

/**
 * usage
 * *ngIf="'feature-name' | feature | async"
 */
@Pipe({
  name: 'username',
  standalone: false,
})
export class UserNamePipe implements PipeTransform {
  constructor(private authService: AuthService) {}

  transform(userId: string | string[], emptyString = ''): Observable<string> {
    if (userId == undefined) {
      return of(emptyString);
    }

    const userIds = Array.isArray(userId) ? userId : [userId];
    const obs$ = userIds.map((userId) =>
      this.authService.specificUserInfo(userId).pipe(
        map((user) => {
          if (!user) {
            return emptyString;
          }
          return user.name ? user.name : emptyString;
        }),
      ),
    );
    return zip(...obs$).pipe(map((names) => names.join(', ')));
  }
}
