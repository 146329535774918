import { Component, ElementRef, EventEmitter, Inject, Input, Output } from '@angular/core';
import {
  clearUnvTimeout,
  Configuration,
  Field,
  FormValueUtil,
  ProjectCI,
  projectCiToVariables,
  ProjectInfo,
  unvTimeout,
  UnvTimeoutValue,
} from '@kfd/core';
import { ConfigurationStateService, PERSISTENCE_TOKEN } from './service/configuration-state.service';
import { ConfigurationFieldRefResolver } from './service/configuration-fieldref-resolver';
import { ConfigurationValidationService } from './service/configuration-validation.service';
import { Observable, of, tap } from 'rxjs';
import { CFG_CONTEXT_SERVICE, CfgContextService } from './service/cfg-context.service';
import { SessionPersistence } from './service/persistence/session.persistence';
import { catchError, map } from 'rxjs/operators';
import { DATA_PROVIDER, DataProvider } from './service/data-provider';
import { ConfigurationService } from './service/configuration.service';
import { PaginationService } from './service/PaginationService';
import { v4 as uuidv4 } from 'uuid';
import { ConfigurationConditionService } from './service/configuration-condition.service';
import { CalculationService } from './service/calculation-service';
import { EventService } from './service/event.service';
import { EVENT_SUBMITTED } from './components/cfg-field-submit-btn/cfg-field-submit-btn.component';
import { CfgSettingsService } from './service/cfg-settings.service';
import { ConfigurationCalculationRefResolver } from './service/configuration-calculation-ref-resolver';
import { CfgException } from '../common/cfg-exception';

export class Intro {
  title = '';
  subtitle = '';
  btnText = 'Starten';
}

export enum CFG_ERROR_CODES {
  UNKNOWN,
  INVALID_PARAMS,
  CANNOT_LOAD_PROJECT,
  PROJECT_NOT_FOUND,
  PROJECT_NOT_VERIFIED,
  CANNOT_LOAD_CFG,
  CFG_NOT_FOUND,
  AUTH_REQUIRED,
}

export interface CfgError {
  code: number;
  msg: string;
}

export const CFG_ERRORS: { [key: string]: CfgError } = {};
CFG_ERRORS[CFG_ERROR_CODES.UNKNOWN] = {
  code: CFG_ERROR_CODES.UNKNOWN,
  msg: 'Unbekannter Fehler',
};
CFG_ERRORS[CFG_ERROR_CODES.INVALID_PARAMS] = {
  code: CFG_ERROR_CODES.INVALID_PARAMS,
  msg: 'Ungültige Parameter',
};
CFG_ERRORS[CFG_ERROR_CODES.CANNOT_LOAD_PROJECT] = {
  code: CFG_ERROR_CODES.CANNOT_LOAD_PROJECT,
  msg: 'Projekt kann nicht geladen werden',
};
CFG_ERRORS[CFG_ERROR_CODES.PROJECT_NOT_FOUND] = {
  code: CFG_ERROR_CODES.PROJECT_NOT_FOUND,
  msg: 'Projekt wurde nicht gefunden',
};
CFG_ERRORS[CFG_ERROR_CODES.PROJECT_NOT_VERIFIED] = {
  code: CFG_ERROR_CODES.PROJECT_NOT_VERIFIED,
  msg: 'Projekt wurde noch nicht verifiziert',
};
CFG_ERRORS[CFG_ERROR_CODES.CANNOT_LOAD_CFG] = {
  code: CFG_ERROR_CODES.CANNOT_LOAD_CFG,
  msg: 'Konfiguration kann nicht geladen werden',
};
CFG_ERRORS[CFG_ERROR_CODES.CFG_NOT_FOUND] = {
  code: CFG_ERROR_CODES.CFG_NOT_FOUND,
  msg: 'Konfiguration wurde nicht gefunden',
};
CFG_ERRORS[CFG_ERROR_CODES.AUTH_REQUIRED] = {
  code: CFG_ERROR_CODES.AUTH_REQUIRED,
  msg: 'Nur für autorisierte Benutzer sichtbar',
};

@Component({
  selector: 'kfd-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
  providers: [
    {
      provide: PERSISTENCE_TOKEN,
      useFactory: () => {
        const persistence = new SessionPersistence();
        persistence.useKey(uuidv4());
        return persistence;
      },
    },
    ConfigurationService,
    ConfigurationConditionService,
    ConfigurationFieldRefResolver,
    ConfigurationValidationService,
    CalculationService,
    PaginationService,
    EventService,
    ConfigurationCalculationRefResolver,
  ],
})
export class ConfigurationComponent {
  @Output()
  submitted: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  initFailed: EventEmitter<CFG_ERROR_CODES> = new EventEmitter<CFG_ERROR_CODES>();
  @Input()
  hidePreviewFlag = false;
  @Input()
  data: Record<string, string | number | boolean> = {};
  status: 'loading' | 'error' | 'ready' = 'loading';
  projectLoaded: boolean | undefined;
  configurationLoaded: boolean | undefined;
  cfgError: CfgError | undefined;
  configurationData: Configuration | undefined;
  projectInfo: ProjectInfo | undefined;
  configurationAvailable: boolean | undefined;
  private _projectId: string | undefined;
  private _configurationId: string | undefined;
  private checkCfgTimeout: UnvTimeoutValue | undefined;

  constructor(
    private elementRef: ElementRef,
    @Inject(CFG_CONTEXT_SERVICE) private contextService: CfgContextService,
    private configService: CfgSettingsService,
    private configurationService: ConfigurationService,
    @Inject(DATA_PROVIDER) private dataProvider: DataProvider,
    private configurationStateService: ConfigurationStateService,
    private configurationValidationService: ConfigurationValidationService,
    private eventService: EventService,
  ) {
    this.eventService.subscribe(EVENT_SUBMITTED, (id) => {
      this.submitted.emit(id);
    });
    this.checkConfiguration();
  }

  _seamless = false;

  @Input()
  set seamless(value: string | boolean) {
    this._seamless = String(value).toLowerCase() == 'true';
  }

  @Input()
  set project(projectId: string) {
    this._projectId = projectId;
    this.contextService.projectId = this._projectId;
    this.checkConfiguration();
  }

  @Input()
  set configuration(configurationId: string) {
    this._configurationId = configurationId;
    this.contextService.configuratorId = this._configurationId;
    this.configurationAvailable = undefined;
    this.checkConfiguration();
  }

  get preview(): boolean {
    return this.dataProvider.preview;
  }

  @Input()
  set preview(preview: boolean) {
    this.dataProvider.preview = preview;
    if (preview) {
      this.checkConfiguration();
    }
  }

  @Input()
  set embedded(embedded: boolean) {
    this.dataProvider.embedded = embedded;
    this.configService.embedded = embedded;
  }

  private checkConfiguration() {
    if (this.checkCfgTimeout) {
      clearUnvTimeout(this.checkCfgTimeout);
    }
    this.checkCfgTimeout = unvTimeout(() => {
      if (this._projectId === undefined || this._configurationId === undefined) {
        this.setError(CFG_ERROR_CODES.INVALID_PARAMS);
        return;
      }
      this.loadProject().subscribe((mandant) => {
        if (mandant) {
          this.loadConfiguration();
        }
      });
    }, 100);
  }

  private updateState() {
    if (this.cfgError !== undefined) {
      this.status = 'error';
      return;
    }
    if (this.projectLoaded && this.configurationLoaded) {
      this.status = 'ready';
      return;
    }
    if (this.projectLoaded === undefined && this.configurationLoaded === undefined) {
      this.status = 'loading';
      return;
    }
  }

  private loadProject(): Observable<ProjectInfo | undefined> {
    if (!this._projectId) {
      return of(undefined);
    }
    this.projectLoaded = undefined;
    return this.dataProvider.getProjectInfo(this._projectId).pipe(
      tap((publicProject) => this.applyStyles(publicProject.ci)),
      tap((publicProject) => {
        this.projectInfo = publicProject.info;
        this.projectLoaded = true;
        this.updateState();
      }),
      map((publicProject) => publicProject.info),
      catchError((response) => {
        switch (response.status) {
          case 404:
            this.setError(CFG_ERROR_CODES.PROJECT_NOT_FOUND);
            break;
          case 406:
            this.setError(CFG_ERROR_CODES.PROJECT_NOT_VERIFIED);
            break;
          case 500:
            this.setError(CFG_ERROR_CODES.CANNOT_LOAD_PROJECT);
            break;
          default:
            this.setError(CFG_ERROR_CODES.UNKNOWN);
        }
        this.projectLoaded = false;
        this.updateState();
        return of(undefined);
      }),
    );
  }

  private loadConfiguration() {
    if (!this._projectId || !this._configurationId) {
      return;
    }

    this.configurationStateService.persistence.useKey(this._projectId + '-' + this._configurationId);

    this.configurationLoaded = undefined;
    this.dataProvider
      .getForm(this._projectId, this._configurationId)
      .pipe(
        tap((requestForm) => (this.configurationService.configuration = requestForm.configuration)),
        tap(() => {
          //set data from input
          if (this.data) {
            for (const [key, value] of Object.entries(this.data)) {
              const entry = this.configurationService.cfgUtil.getEntryByName<Field>(key);
              if (!entry) {
                continue;
              }
              const formValue = FormValueUtil.createFormValueForField(entry, value);
              this.configurationStateService.setValue(key, formValue);
            }
          }
        }),
        catchError((response) => {
          switch (response.status) {
            case 401:
              this.setError(CFG_ERROR_CODES.AUTH_REQUIRED);
              break;
            case 404:
            case 406:
              this.setError(CFG_ERROR_CODES.CFG_NOT_FOUND);
              break;
            case 500:
              this.setError(CFG_ERROR_CODES.CANNOT_LOAD_CFG);
              break;
            default:
              this.setError(CFG_ERROR_CODES.UNKNOWN);
          }
          this.configurationLoaded = false;
          this.updateState();
          return of(undefined);
        }),
      )
      .subscribe((requestForm) => {
        if (!requestForm) {
          return;
        }
        this.contextService.requestId = requestForm.id;
        this.configurationData = requestForm.configuration;
        this.configurationLoaded = true;
        this.updateState();
      });
  }

  private setError(code: CFG_ERROR_CODES) {
    if (CFG_ERRORS[code]) {
      this.cfgError = CFG_ERRORS[code];
    } else {
      throw new CfgException('Unknown error code: ' + code);
    }
    this.initFailed.emit(code);
    this.updateState();
  }

  private applyStyles(projectCI: ProjectCI) {
    const colors = projectCiToVariables(projectCI);
    for (const color of Object.entries(colors)) {
      this.elementRef.nativeElement.style.setProperty(color[0], color[1]);
    }
  }
}
