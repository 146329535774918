import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, Subscriber, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FullscreenLoginDialogComponent } from '../fullscreen-login-dialog/fullscreen-login-dialog.component';
import { Events } from '../../shared/global';
import { DynamicDialogRef } from 'primeng/dynamicdialog/dynamicdialog-ref';
import { CmsDialogService } from '../../services/cms-dialog.service';
import { AuthService, EventService } from '@kfd/web-core';

export class SessionExpired extends Error {}

export interface UserSessionInterceptorConfig {
  urlsToIgnore?: string[];
}

export const userSessionInterceptorConfigInjectionToken = new InjectionToken<UserSessionInterceptorConfig>(
  'user-session-interceptor-config',
);

// we do not react on 403 to avoid problems with csrf protection (which handles the 403)
const relevantStatusCodes = [401];

/**
 * intercepts http requests to handle responses which are unauthorized
 **/
@Injectable()
export class UserSessionInterceptor implements HttpInterceptor {
  dynamicDialogRef: DynamicDialogRef | undefined;

  constructor(
    private readonly eventService: EventService,
    private readonly cmsDialogService: CmsDialogService,
    private authService: AuthService,
    @Inject(userSessionInterceptorConfigInjectionToken) private readonly config?: UserSessionInterceptorConfig,
  ) {}

  public intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (Array.isArray(this.config.urlsToIgnore) && this.config.urlsToIgnore.length > 0) {
      for (const url of this.config.urlsToIgnore) {
        if (httpRequest.url.includes(url)) {
          return next.handle(httpRequest);
        }
      }
    }
    return next.handle(httpRequest).pipe(
      catchError((httpErrorResponse) => {
        return this.handleAuthError$(httpErrorResponse).pipe(switchMap(() => next.handle(httpRequest)));
      }),
    );
  }

  private handleAuthError$(httpErrorResponse: HttpErrorResponse): Observable<void> {
    if (relevantStatusCodes.includes(httpErrorResponse.status)) {
      return this.authService.signOut().pipe(switchMap(() => this.showErrorDialog()));
    }
    return throwError(() => httpErrorResponse);
  }

  private showErrorDialog(): Observable<void> {
    return new Observable((subscriber) => {
      if (this.dynamicDialogRef) {
        this.handleDialogClose(subscriber);
        return;
      }
      this.dynamicDialogRef = this.cmsDialogService.open(
        FullscreenLoginDialogComponent,
        {},
        {
          // styleClass: 'full-screen-dialog',
          dismissibleMask: false,
          fullScreen: true,
        },
      );
      this.handleDialogClose(subscriber);
    });
  }

  private handleDialogClose(subscriber: Subscriber<void>) {
    if (!this.dynamicDialogRef) {
      subscriber.next();
    }
    this.dynamicDialogRef.onClose.subscribe(() => {
      this.dynamicDialogRef = undefined;
      subscriber.next();
      this.eventService.broadcast(Events.Auth);
    });
  }
}
