import { Component, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CalculationService,
  CFG_CONTEXT_SERVICE,
  CfgContextService,
  CfgModule,
  ConfigurationBasedataResolver,
  ConfigurationConditionService,
  ConfigurationFieldRefResolver,
  ConfigurationService,
  ConfigurationStateService,
  ConfigurationValidationService,
  DATA_PROVIDER,
  EventService,
  MemoryPersistence,
  PaginationService,
} from '@kfd/cfg-core';
import { ConfigurationDataService } from '../../../services/configuration-data.service';
import { BASE_DATA_RESOLVER, CmsConfigurator, Create, ProjectCI, projectCiToVariables, StringUtil } from '@kfd/core';
import { ApiConfigurationService } from '../../../services/api/api-configuration.service';
import { CmsContextService } from '../../../services/cms-context.service';
import { ApiProjectService } from '../../../services/api/api-project.service';

@Component({
  selector: 'kfd-cfg-draft-view',
  imports: [CommonModule, CfgModule],
  templateUrl: './cfg-draft-view.component.html',
  styleUrls: ['../../../../../../../libs/cfg-core/src/theme.scss', './cfg-draft-view.component.scss'],
  providers: [
    ConfigurationService,
    CalculationService,
    ConfigurationFieldRefResolver,
    ConfigurationValidationService,
    ConfigurationConditionService,
    PaginationService,
    EventService,
    {
      provide: ConfigurationStateService,
      useFactory: () => {
        return new ConfigurationStateService(new MemoryPersistence());
      },
      deps: [],
    },
    {
      provide: DATA_PROVIDER,
      useClass: ConfigurationDataService,
    },
    {
      provide: CFG_CONTEXT_SERVICE,
      useClass: CfgContextService,
    },
    {
      provide: BASE_DATA_RESOLVER,
      useClass: ConfigurationBasedataResolver,
    },
  ],
})
export class CfgDraftViewComponent implements OnInit {
  @Input({
    required: true,
  })
  public projectId: string;
  @Input({
    required: true,
  })
  public configurationId: string;
  protected loading = false;
  protected cmsConfigurator: CmsConfigurator;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly ctx: CmsContextService,
    private readonly projectService: ApiProjectService,
    private readonly apiConfigurationService: ApiConfigurationService,
    private readonly cfgConfigurationService: ConfigurationService,
    @Inject(CFG_CONTEXT_SERVICE) private readonly cfgCtx: CfgContextService,
  ) {}

  ngOnInit() {
    this.loading = true;

    this.apiConfigurationService.getConfiguration(this.projectId, this.configurationId, false).subscribe({
      next: (cmsConfigurator) => {
        this.cmsConfigurator = cmsConfigurator;
        this.cfgConfigurationService.configuration = cmsConfigurator;
        this.cfgCtx.projectId = this.projectId;
        this.cfgCtx.configuratorId = this.configurationId;
        this.cfgCtx.requestId = StringUtil.rand(5);
        this.loading = false;
      },
    });

    this.projectService.getProject(this.ctx.projectId).subscribe((project) => {
      this.applyStyles(project.ci ?? Create.projectCI());
    });
  }

  private applyStyles(projectCI: Omit<ProjectCI, 'logo'>) {
    const colors = projectCiToVariables(projectCI);
    for (const color of Object.entries(colors)) {
      this.elementRef.nativeElement.style.setProperty(color[0], color[1]);
    }
  }
}
