@if (inline) {
  <div class="flex">
    <ng-container *ngTemplateOutlet="fieldRefSelection"></ng-container>
    @if (showAcceptBtn) {
      <p-button icon="pi {{ ICON.ACCEPT }}" [outlined]="true" (click)="save()"></p-button>
    }
  </div>
} @else {
  <div class="flex">
    @if (originFieldRef.name) {
      <div class="flex align-items-center mr-2">
        {{ originFieldRef.name }}

        @if (originFieldRef.property) {
          → {{ originFieldRef.property }}
        }
      </div>
    }
    <p-button
      label="{{ originFieldRef.name ? '' : 'Feld auswählen' }}"
      [text]="true"
      [rounded]="true"
      icon="pi {{ ICON.EDIT }}"
      (onClick)="fieldRefSelectionOverlay.toggle($event)"
    ></p-button>
  </div>
}

<p-overlayPanel #fieldRefSelectionOverlay [dismissable]="false" appendTo="body" styleClass="padding-sm">
  <ng-container *ngTemplateOutlet="fieldRefSelection"></ng-container>

  <p-button
    (onClick)="save(); fieldRefSelectionOverlay.hide()"
    [outlined]="true"
    [rounded]="true"
    class="block mt-1"
    icon="pi {{ ICON.ACCEPT }}"
    label="Übernehmen"
  ></p-button>
</p-overlayPanel>

<ng-template #fieldRefSelection>
  @if (fieldSelectOptions.length === 0) {
    <div class="my-1">
      <small class="color-error">
        @if (emptyMsg) {
          {{ emptyMsg }}
        } @else {
          ---
        }
      </small>
    </div>
  } @else {
    <div class="flex-1">
      <!--      <label>Feld</label>-->
      <p-dropdown (onChange)="onSelectField($event)" [ngModel]="fieldRef.name" [options]="fieldSelectOptions"
                  appendTo="body">
        <ng-template let-item pTemplate="item">
          <div class="flex align-items-center gap-2">
            <i class="pi {{ item.icon }}"></i>
            <div>{{ item.label }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    @if (allowProperties) {
      @if (propertiesSelectOptions$ | async; as propertiesSelectOptions) {
        <div class="flex-1 mt-2">
          <label>Wert</label>
          <p-dropdown
            [disabled]="propertiesSelectOptions.length === 0"
            (onChange)="onSelectProperty($event)"
            [ngModel]="fieldRef.property"
            [options]="propertiesSelectOptions"
            appendTo="body"
          ></p-dropdown>
        </div>
      }
    }
    @if (allowDefaultValue) {
      <div class="flex-1 mt-2" [kfdBlock]="!defaultValueDataType">
        <label>Standardwert</label>
        <kfd-data-value [type]="defaultValueDataType" [(value)]="fieldRef.defaultValue"></kfd-data-value>
      </div>
    }
  }
</ng-template>
