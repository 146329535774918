@if (viewData$ | async; as viewData) {
  @if (viewData.showNavigation) {
    <kfd-user-icon>
      <div *kfdOnlyAdmin class="flex mt-2 align-items-center">
        @if (SETTINGS_KEYS.FEATURE_PREVIEW | setting | async; as featurePreview) {
          <p-inputSwitch (ngModelChange)="togglePreviewMode($event)"
                         [ngModel]="featurePreview === 'true'"></p-inputSwitch>
          <span class="ml-1">Preview enabled</span>
        }
      </div>
    </kfd-user-icon>
    <kfd-side-navigation [menuItems]="viewData.menuItems"
                         [metaMenuItems]="viewData.metaMenuItems"></kfd-side-navigation>
  }

  <div class="page-wrapper flex flex-column p-flex-1" [ngClass]="{'limited-width': !viewData.showNavigation}">
    <div class="main-content">

      @if (viewData.currentUser) {
        <kfd-user-messages [profileLink]="COMMON_WEB_APP_ROUTES.user.activation.root"></kfd-user-messages>
      }
      <router-outlet></router-outlet>
    </div>

    <p-confirmdialog />
    <p-toast key="error" position="bottom-center"></p-toast>
    <p-toast key="info" position="bottom-center"></p-toast>
  </div>
  <kfd-user-notifications></kfd-user-notifications>
} @else {
  <kfd-loader class="flex-1"></kfd-loader>
}
