import { EventEmitter, Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { unvTimeout, WsMessage, WsMessageType } from '@kfd/core';
import { Observable } from 'rxjs';
import { NotificationSocket } from './notification-socket';

@Injectable({
  providedIn: 'root',
})
export class MessagesGatewayService {
  private notificationSocket: NotificationSocket;
  private message = new EventEmitter<WsMessage>();
  private _retries = 0;
  private _connectionStatus: 'connected' | 'disconnected' | 'pending' = 'pending';

  /**
   * initializes the socket connection if not already done
   */
  public initialize(): void {
    if (this.notificationSocket) {
      return;
    }
    //creates custom Notification socket
    this.notificationSocket = new NotificationSocket();

    this.notificationSocket.on('connect', () => {
      //use timeout to ensure the connection won't be closed from the server
      unvTimeout(() => {
        if (this.notificationSocket.connected) {
          this._connectionStatus = 'connected';
          this._retries = 0;
        }
      }, 1000);
    });
    this.notificationSocket.on('disconnect', () => {
      if (this._retries < 3) {
        unvTimeout(() => {
          this.connect();
        }, 1000);
      } else {
        this._connectionStatus = 'disconnected';
      }
    });
    this.connect();
  }

  public connectionStatus(): 'connected' | 'disconnected' | 'pending' {
    return this._connectionStatus;
  }

  public onMessageType<T>(type: WsMessageType): Observable<T> {
    return this.message.pipe(
      filter((message) => message.type === type),
      map((message) => message.data as T),
    );
  }

  private connect(): void {
    this._retries = this._retries + 1;
    this._connectionStatus = 'pending';
    this.notificationSocket.connect();
    this.notificationSocket.fromEvent('msgToClient').subscribe((message: WsMessage) => {
      this.message.emit(message);
    });
  }
}
