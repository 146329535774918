import { Injectable } from '@angular/core';
import { AuthService, BaseHttpService, InsertResponse } from '@kfd/web-core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { AVAILABLE_FEATURES, BaseFilter, CustomerRequestFilter, SETTINGS_KEYS } from '@kfd/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _features: AVAILABLE_FEATURES[] | undefined;
  private featuresSubject$ = new BehaviorSubject<AVAILABLE_FEATURES[]>([]);
  private previewEnabled = false;
  private httpService: BaseHttpService;

  constructor(
    readonly httpClient: HttpClient,
    private readonly authService: AuthService,
  ) {
    this.httpService = new BaseHttpService(httpClient, environment.services.cms);
    this.authService.onSignInChange().subscribe({
      next: (signedIn) => {
        if (signedIn) {
          this.loadFeatures();
        } else {
          this._features = [];
          this.featuresSubject$.next(this._features);
        }
      },
    });
  }

  get features(): AVAILABLE_FEATURES[] | undefined {
    return this._features;
  }

  public togglePreviewMode(enable: boolean): void {
    this.previewEnabled = enable;
    if (this.features) {
      this.featuresSubject$.next(this.features);
    }
  }

  public getCustomerRequestFilters(projectId: string): Observable<BaseFilter<CustomerRequestFilter>[]> {
    return this.httpService.get<BaseFilter<CustomerRequestFilter>[]>(
      `/user/settings/${projectId}/customer-request-filter`,
    );
  }

  public saveCustomerRequestFilters(
    projectId: string,
    filterList: BaseFilter<CustomerRequestFilter>[],
  ): Observable<InsertResponse> {
    return this.httpService.post<InsertResponse>(`/user/settings/${projectId}/customer-request-filter`, {
      filterList,
    });
  }

  public getSetting<T>(key: SETTINGS_KEYS): Observable<T> {
    return this.httpService.get<T>(`/user/setting/${key}`);
  }

  public getFeatures(): Observable<AVAILABLE_FEATURES[]> {
    if (this.features === undefined) {
      this.loadFeatures();
    }
    return this.featuresSubject$.asObservable();
  }

  /**
   * Check if a feature is enabled (async)
   * @param feature
   */
  public userHasFeature(feature: AVAILABLE_FEATURES): Observable<boolean> {
    return this.getFeatures().pipe(map((features) => this.isFeatureEnabled(features, feature)));
  }

  /**
   * Check if a feature is enabled (sync)
   * @param features
   * @param feature
   */
  public isFeatureEnabled(features: AVAILABLE_FEATURES[], feature: AVAILABLE_FEATURES): boolean {
    if (!this.previewEnabled && feature === AVAILABLE_FEATURES.PREVIEW) {
      return false;
    }
    return Array.isArray(features) && features.indexOf(feature) >= 0;
  }

  public loadFeatures(): void {
    this.httpService.get<AVAILABLE_FEATURES[]>(`/user/features`).subscribe((features) => {
      this._features = features;
      this.featuresSubject$.next(this._features);
    });
  }
}
