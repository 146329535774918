import { ApplicationRef, ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog/dynamicdialog-ref';
import { ClientCodeError, EventService, ForbiddenError, MessageService } from '@kfd/web-core';
import { Events } from './shared/global';
import { CmsDialogService } from './services/cms-dialog.service';
import { LoggingService } from './services/logging.service';
import { Exception } from './shared/exceptions';
import { unvTimeout } from '@kfd/core';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  dynamicDialogRef: DynamicDialogRef | undefined;

  constructor(@Inject(Injector) private readonly injector: Injector) {}

  private get getDialogService(): CmsDialogService {
    return this.injector.get(CmsDialogService);
  }

  private get applicationRef(): ApplicationRef {
    return this.injector.get(ApplicationRef);
  }

  private get eventService(): EventService {
    return this.injector.get(EventService);
  }

  private get messageService(): MessageService {
    return this.injector.get(MessageService);
  }

  private get loggingService(): LoggingService {
    return this.injector.get(LoggingService);
  }

  handleError(error: Error) {
    if (error instanceof ForbiddenError) {
      this.messageService.showError('Keine Berechtigung', 'Sie sind nicht berechtigt die Inhalte zu sehen');
      // this.applicationRef.tick();
      return;
    }

    if (error instanceof ClientCodeError) {
      this.messageService.showError(
        'Fehler beim speichern',
        error.message ? error.message : 'Bitte prüfen Sie Ihre Eingaben',
      );
      this.loggingService.addLog({
        type: 'error',
        message: 'Fehler beim speichern',
        code: error['code'],
      });
      // this.applicationRef.tick();
      return;
    }

    if (error instanceof Exception) {
      // eslint-disable-next-line no-console
      console.error(error.message, error.details, error.stack);

      unvTimeout(() => {
        // this.messageService.showError('Unbekannter Fehler', 'Unbekannter Fehler, bitte versuchen Sie es erneut');
        let details = '';
        try {
          details = JSON.stringify(error.details);
        } catch (_) {
          // nothing do to
        }
        this.loggingService.addError({
          type: 'unknown-error',
          message: error.message,
          details,
          stack: error.stack,
        });
        // this.applicationRef.tick();
        this.eventService.broadcast(Events.Error);
      }, 100);
      return;
    }

    // eslint-disable-next-line no-console
    console.error('Error from global error handler', error);

    unvTimeout(() => {
      // this.messageService.showError('Unbekannter Fehler', 'Unbekannter Fehler, bitte versuchen Sie es erneut');
      this.loggingService.addError({
        type: 'unknown-error',
        message: 'Unbekannter Fehler',
        stack: error.stack,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        code: error['code'],
      });
      // this.applicationRef.tick();
      //   // this.eventService.broadcast(Events.Error);
    }, 100);
  }
}
