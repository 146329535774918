import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationComponent } from './configuration/configuration.component';
import { ConfiguratorPageComponent } from './configuration/components/configurator-page/configurator-page.component';
import { ConfiguratorQuestionComponent } from './configuration/components/configurator-question/configurator-question.component';
import { ConfiguratorFieldSelectComponent } from './configuration/components/configurator-field-select/configurator-field-select.component';
import { ToArrayPipe } from './configuration/pipes/to-array.pipe';
import { DataValuePipe } from './configuration/pipes/data-value.pipe';
import { ConfiguratorCalculationComponent } from './configuration/components/configurator-calculation/configurator-calculation.component';
import { ConfiguratorFieldComponent } from './configuration/components/configurator-field/configurator-field.component';
import { ConfiguratorInfoComponent } from './configuration/components/configurator-info/configurator-info.component';
import { ConfiguratorConditionComponent } from './configuration/components/configurator-condition/configurator-condition.component';
import { ConfiguratorFieldNumericComponent } from './configuration/components/configurator-field-numeric/configurator-field-numeric.component';
import { ConfiguratorFieldTextComponent } from './configuration/components/configurator-field-text/configurator-field-text.component';
import { ConfiguratorFieldBoolComponent } from './configuration/components/configurator-field-bool/configurator-field-bool.component';
import { ConfiguratorFieldDateComponent } from './configuration/components/configurator-field-date/configurator-field-date.component';
import { ConfiguratorFieldReadonlyComponent } from './configuration/components/configurator-field-readonly/configurator-field-readonly.component';
import { CfgSettingsService, ScreenSize } from './configuration/service/cfg-settings.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CfgLayoutPageComponent } from './configuration/components/cfg-layout-page/cfg-layout-page.component';
import { CfgFieldSubmitBtnComponent } from './configuration/components/cfg-field-submit-btn/cfg-field-submit-btn.component';
import { CfgFieldSummaryComponent } from './configuration/components/cfg-field-summary/cfg-field-summary.component';
import { ValidationErrorComponent } from './configuration/components/validation-error/validation-error.component';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonModule } from 'primeng/button';
import { CfgResetBtnComponent } from './configuration/components/cfg-reset-btn/cfg-reset-btn.component';
import { NumberFormatPipe } from './configuration/pipes/number-format.pipe';
import { FormValuePipe } from './configuration/pipes/form-value.pipe';
import { ValueListComponent } from './configuration/components/value-list/value-list.component';
import { TextareaComponent } from './configuration/components/common/textarea/textarea.component';
import { ValidationErrorTextPipe } from './configuration/pipes/validation-error-text.pipe';
import { LoadingPipe } from './configuration/pipes/loading.pipe';
import { CheckboxModule } from 'primeng/checkbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InlineSelectionComponent } from './common/inline-selection/inline-selection.component';
import { BreakpointObserver } from '@angular/cdk/layout';

export interface ModuleOptions {
  config?: {
    embedded?: boolean;
    screenSize?: ScreenSize;
    privacyLink?: string;
  };
}

const CFG_MOD_OPTIONS = new InjectionToken<ModuleOptions>('forRoot() configuration.');

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,

    //prime
    ButtonModule,
    CheckboxModule,
    DropdownModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OverlayPanelModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    SelectButtonModule,
    CalendarModule,
    InlineSelectionComponent,
  ],
  declarations: [
    ConfigurationComponent,
    ConfiguratorPageComponent,
    ConfiguratorQuestionComponent,
    ConfiguratorFieldComponent,
    ConfiguratorInfoComponent,
    ConfiguratorFieldSelectComponent,
    ConfiguratorConditionComponent,
    ConfiguratorFieldNumericComponent,
    ToArrayPipe,
    DataValuePipe,
    FormValuePipe,
    LoadingPipe,
    NumberFormatPipe,
    ValidationErrorTextPipe,
    ConfiguratorFieldTextComponent,
    ConfiguratorCalculationComponent,
    ConfiguratorFieldBoolComponent,
    ConfiguratorFieldDateComponent,
    ConfiguratorFieldReadonlyComponent,
    CfgLayoutPageComponent,
    CfgFieldSubmitBtnComponent,
    CfgFieldSummaryComponent,
    ValidationErrorComponent,
    CfgResetBtnComponent,
    ValueListComponent,
    TextareaComponent,
  ],
  exports: [
    ConfigurationComponent,
    CfgLayoutPageComponent,
    ConfiguratorPageComponent,
    ConfiguratorFieldComponent,
    ConfiguratorQuestionComponent,
    ConfiguratorConditionComponent,
    ValueListComponent,
    FormValuePipe,
  ],
  providers: [
    {
      provide: CfgSettingsService,
      useFactory: (options: ModuleOptions, breakpointObserver: BreakpointObserver) => {
        const configService = new CfgSettingsService(breakpointObserver);
        configService.embedded = options.config?.embedded ?? false;
        configService.screenSize = options.config?.screenSize ?? undefined;
        configService.privacyLink = options.config?.privacyLink ?? undefined;
        return configService;
      },
      deps: [CFG_MOD_OPTIONS, BreakpointObserver],
    },
  ],
})
export class CfgModule {
  static forRoot(options: ModuleOptions): ModuleWithProviders<CfgModule> {
    return {
      ngModule: CfgModule,
      providers: [
        {
          provide: CFG_MOD_OPTIONS,
          useValue: options,
        },
      ],
    };
  }
}
