@if (baseDataTemplates$ | async; as baseDataTemplates) {
  @if (baseDataTemplates.length > 1) {
    <p-select
      (ngModelChange)="onTemplateChange($event)"
      [ngModel]="template"
      [options]="baseDataTemplates"
      class="full-width"
      appendTo="body"
      data-automationId="basedata-template-selection"
      placeholder="Keine Vorlage ausgewählt"
    >
      <ng-template let-item pTemplate="item">
        <strong>{{ item.label }}</strong>
        @if (item.value?.description) {
          <div class="text-xs">{{ item.value.description }}</div>
        }
      </ng-template>
    </p-select>
  } @else if (emptyMessage) {
    <input class="flex-1" [value]="emptyMessage" [disabled]="true" pInputText>
  }
}
