<div class="header flex justify-content-between">
  <a class="logo" routerLink="/"></a>
</div>
<div class="flex flex-1 justify-content-center align-items-center scroll-area py-2">
  <ng-content></ng-content>
</div>
<div class="footer">
  <div class="page-limiter default-page-gap flex-column">
    <ul class="text-xs">
      <li><a [href]="environment.apps.web">Web</a></li>
      <li><a [href]="environment.apps.web + '/content/imprint'" class="ml-2">Impressum</a></li>
      <li><a [href]="environment.apps.web + KFD_WEB_APP_ROUTES.legal.privacy" class="ml-2">Datenschutz</a></li>
      <li><a [href]="environment.apps.web + '/content/contact'" class="ml-2">Kontakt</a></li>
      <li><a [href]="environment.apps.web + '/support'" class="ml-2">Support</a></li>
    </ul>
  </div>
</div>
