@if (disabled) {
  <ng-container *ngTemplateOutlet="content"></ng-container>
} @else {
  @if (licenceLimit$ | loading | async; as licenceLimit) {
    @if (licenceLimit.loading) {
      <kfd-loader></kfd-loader>
    } @else if (licenceLimit.value) {
      @if (licenceLimit.value.limitReached) {
        <kfd-action-message
          severity="warn"
          text="Limit erreicht"
          info="Es wurden alle verfügbaren {{ licenceOptionLabel }} ({{ licenceLimit.value.value }}) verbraucht."
          (action)="showInfo()"
        ></kfd-action-message>
      } @else if (showMessage) {
        @if (showIfLessThan > licenceLimit.value.value - licenceLimit.value.used) {
          <kfd-action-message
            severity="info"
            info="Verfügbare {{ licenceOptionLabel }}: {{ licenceLimit.value.value - licenceLimit.value.used }}"
            size="small"
            (action)="showInfo()"
          ></kfd-action-message>
          <div class="mt-2 flex-1 flex flex-column">
            <ng-container *ngTemplateOutlet="content"></ng-container>
          </div>
        } @else {
          <ng-container *ngTemplateOutlet="content"></ng-container>
        }
      }
    }
  }
}

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
