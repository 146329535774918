<p-select
  (onChange)="onTypeChanged($event.value)"
  [disabled]="disabled"
  [ngModel]="type"
  [options]="typeList"
  appendTo="body"
  data-automationId="data-value-type-dropdown"
  id="operation"
>
</p-select>
