export interface LoginRequestBody {
  username: string;
  password: string;
}

export interface AuthToken {
  accessToken: string;
  refreshToken: string;
  wsToken: string;
}

export type RefreshTokenResponseData = AuthToken;

export interface RegisterRequestBody {
  name: string;
  email: string;
  password: string;
  privacyAccepted: boolean;
}

export interface ResendActivationMailRequestBody {
  email: string;
  password: string;
}
