import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Create, ValueLabel } from '@kfd/core';

@Component({
  selector: 'kfd-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  standalone: false,
})
export class LabelComponent {
  @Output() labelChange: EventEmitter<ValueLabel | undefined> = new EventEmitter<ValueLabel | undefined>();

  private _label: ValueLabel = Create.valueLabel();

  public get label(): ValueLabel {
    return this._label;
  }

  @Input()
  public set label(value: ValueLabel) {
    if (!value) {
      return;
    }
    this._label = value;
  }

  valueChange() {
    if (this.label.text.length === 0) {
      this.labelChange.emit(undefined);
      return;
    }
    this.labelChange.emit(this.label);
  }

  removeValue() {
    this.label.text = '';
    this.labelChange.emit(undefined);
  }
}
