import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { catchError, Observable, of } from 'rxjs';
import { ApiConfigurationService } from '../../services/api/api-configuration.service';
import { CmsContextService } from '../../services/cms-context.service';

/**
 * usage
 * [title]="configuration._id | cfgname | async"
 */
@Pipe({
  name: 'cfgname',
  standalone: false,
})
export class CfgNamePipe implements PipeTransform {
  constructor(
    private configurationCmsService: ApiConfigurationService,
    private ctx: CmsContextService,
  ) {}

  transform(cfgId: string, emptyString = '---'): Observable<string> {
    return this.configurationCmsService.getConfiguration(this.ctx.projectId, cfgId).pipe(
      map((cmsConfiguration) => {
        if (!cmsConfiguration) {
          return emptyString;
        }
        return cmsConfiguration.label ? cmsConfiguration.label : cmsConfiguration.name;
      }),
      catchError(() => of(emptyString)),
    );
  }
}
