import { Injectable } from '@angular/core';
import { WebEndpointService } from '@kfd/web-core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ArrayUtil, cmsServiceEP, UserNotification, WsMessageType } from '@kfd/core';
import { startWith } from 'rxjs/operators';
import { MessagesGatewayService } from './messages-gateway.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private userNotifications: UserNotification[] = [];

  private notificationsChange = new BehaviorSubject<UserNotification[]>([]);
  private browserNotifications: PermissionState | undefined;

  constructor(
    private readonly webEndpointService: WebEndpointService,
    private readonly messagesGatewayService: MessagesGatewayService,
  ) {
    this.messagesGatewayService
      .onMessageType<UserNotification[]>(WsMessageType.NOTIFICATION)
      .subscribe((notifications) => {
        this.userNotifications = ArrayUtil.uniqueByKey([...notifications, ...this.userNotifications], '_id');
        this.notificationsChange.next(this.userNotifications);
        const unreadNotifications = this.userNotifications.filter((n) => !n.readDate).length;
        if (unreadNotifications > 0) {
          const notification = new Notification('Neue Nachrichten', {
            icon: '/assets/images/Logo_icon_konfidoo.svg',
            badge: '/assets/images/Logo_icon_konfidoo.svg',
            body: 'Neue Nachrichten: ' + unreadNotifications,
          });
          notification.onclick = function () {
            window.parent.parent.focus();
          };
        }
      });
    this.updatePermissionStatus();
  }

  public initialize() {
    this.messagesGatewayService.initialize();
  }

  public wsConnectionStatus(): 'connected' | 'disconnected' | 'pending' {
    return this.messagesGatewayService.connectionStatus();
  }

  notificationsList(): Observable<UserNotification[]> {
    return this.notificationsChange.pipe(startWith(this.userNotifications));
  }

  public markAsRead(notificationId: string): Observable<typeof cmsServiceEP.notifications.markAsRead.response> {
    this.userNotifications = this.userNotifications.map((notification) => {
      if (notification._id === notificationId) {
        return {
          ...notification,
          readDate: new Date(),
        };
      }
      return notification;
    });

    return this.webEndpointService
      .post(cmsServiceEP.notifications.markAsRead, [notificationId])
      .pipe(tap(() => this.notificationsChange.next(this.userNotifications)));
  }

  public markAllAsRead(): Observable<typeof cmsServiceEP.notifications.markAllAsRead.response> {
    return this.webEndpointService.post(cmsServiceEP.notifications.markAllAsRead, []);
  }

  public notificationStatus(): PermissionState {
    return this.browserNotifications;
  }

  public askNotificationPermission() {
    // Check if the browser supports notifications
    if (!('Notification' in window)) {
      return;
    }
    Notification.requestPermission().then(() => {
      // set the button to shown or hidden, depending on what the user answers
      // notificationBtn.style.display = permission === "granted" ? "none" : "block";
      this.updatePermissionStatus();
    });
  }

  private updatePermissionStatus(): void {
    navigator.permissions.query({ name: 'notifications' }).then((result) => {
      this.browserNotifications = result.state;
    });
  }
}
