import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { CfgStateService, PersistenceState } from '../cfg-state.service';
import { BehaviorSubject, combineLatestWith, Observable, Subject } from 'rxjs';
import { ALLOWED_CHILDREN, Area, CfgSkeleton, CLS, Create, D2, ICON, Page } from '@kfd/core';
import { DragDropService } from './drag-drop/drag-drop.service';
import { map, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { CfgEditorService } from '../cfg-editor.service';

const PAGE_WIDTH = 350;
const TRANSITION_TIME = 300;
const WRAPPER_MARGIN = 0;
const ACTIVE_AREA_POS = 'center';

interface BtnVisibility {
  prev: boolean;
  next: boolean;
}

@Component({
  selector: 'kfd-dnd-area',
  templateUrl: './dnd-area.component.html',
  styleUrls: ['./dnd-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DndAreaComponent implements OnDestroy {
  public dndActive$: Observable<boolean>;
  public persistenceRunning$: Observable<PersistenceState>;
  public pageWidth = PAGE_WIDTH;
  public pageHeight = 0;
  public transitionTime = 0;
  public positionAbs = new D2(0, 0);
  public positionRel = new D2(0, 0);
  public initialized = false;
  @HostBinding('class.visible')
  public showPreview = false;

  protected viewData$: Observable<{
    dndActive: string | undefined;
    editMode: boolean;
    btnVisibility: BtnVisibility;
    cfgSkeleton: CfgSkeleton | undefined;
    currentPage: Page | undefined;
  }>;

  protected readonly ICON = ICON;
  protected readonly CLS = CLS;
  protected readonly allowedChildren = ALLOWED_CHILDREN;

  private pageCount: number | undefined;
  private destroy$ = new Subject<boolean>();
  private activeArea: Area | undefined;
  private btnVisibilityChange = new BehaviorSubject<BtnVisibility>({ prev: false, next: false });

  // wrapper element
  private _dndAreaContainerEl: ElementRef<HTMLElement> | undefined;

  //inner element
  private _cfgContainerEl: ElementRef<HTMLElement> | undefined;

  constructor(
    private readonly cfgEditorService: CfgEditorService,
    private readonly cfgStateService: CfgStateService,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly dragDropService: DragDropService,
    private readonly cfRef: ChangeDetectorRef,
  ) {
    this.viewData$ = this.cfgStateService.onConfigurationChange.pipe(
      combineLatestWith(
        this.btnVisibilityChange,
        this.dragDropService.onDndActive,
        this.cfgEditorService.onEditModeChange,
        this.cfgStateService.onStructureChange.pipe(
          tap((cfgSkeleton) => {
            if (this.pageCount !== cfgSkeleton?.children.length) {
              this.pageCount = cfgSkeleton?.children.length ?? 0;
              this.checkInit();
              this.update();
            }
            if (this.cfgEditorService.currentSelection) {
              this.focusAreaByName(this.cfgEditorService.currentSelection);
              this.updateButtons();
            }
          }),
        ),
        this.cfgEditorService.onSelectionChange.pipe(
          tap((entry) => {
            if (entry === undefined) {
              return;
            }
            this.focusAreaByName(entry.name);
            this.updateButtons();
          }),
        ),
      ),
      map(([, btnVisibility, dndActive, editMode, cfgSkeleton, currentSelection]) => ({
        btnVisibility,
        dndActive,
        editMode,
        cfgSkeleton,
        currentPage: currentSelection
          ? this.cfgStateService.getCfgUtil().getParentPage(currentSelection.name)
          : undefined,
      })),
    );
    this.persistenceRunning$ = this.cfgStateService.onPersistenceChange;
    this.dndActive$ = this.dragDropService.onDndActive.pipe(map((active) => !!active));

    // this.currentPage$ = this.cfgEditorService.onSelectionChange.pipe(tap((entry) => {
    //     if (entry === undefined) {
    //       return;
    //     }
    //     this.focusAreaByName(entry.name);
    //     this.updateButtons();
    //   }),
    //   map((entry) => this.cfgStateService.getCfgUtil().getParentPage(entry.name)),
    // );
    // this.cfgStateService.onConfigurationChange.pipe(takeUntil(this.destroy$)).subscribe((cfg) => {
    //   this.activeArea = undefined;
    //   if (cfg) {
    //     this.cfgSkeleton$ = this.cfgStateService.onStructureChange.pipe(
    //       map((cfgSkeleton) => {
    //         if (this.pageCount !== cfgSkeleton?.children.length) {
    //           this.pageCount = cfgSkeleton?.children.length;
    //           this.checkInit();
    //           this.update();
    //         }
    //         if (this.cfgEditorService.currentSelection) {
    //           this.focusAreaByName(this.cfgEditorService.currentSelection);
    //           this.updateButtons();
    //         }
    //         return cfgSkeleton;
    //       }),
    //     );
    //   }
    // });
  }

  @ViewChild(forwardRef(() => 'configurationContainer'), {
    read: ElementRef,
    static: false,
  })
  public set configurationContainer(element: ElementRef<HTMLElement>) {
    this._cfgContainerEl = element;
    this.checkInit();
  }

  @ViewChild(forwardRef(() => 'dndAreaContainer'), {
    read: ElementRef,
    static: false,
  })
  public set dndAreaContainer(element: ElementRef<HTMLElement>) {
    this._dndAreaContainerEl = element;
    this.checkInit();
  }

  private get previewAreaElement(): HTMLElement {
    if (!this._dndAreaContainerEl) {
      throw new Error('No dnd wrapper element available');
    }
    return this._dndAreaContainerEl.nativeElement;
  }

  private get previewElement(): HTMLElement {
    if (!this._cfgContainerEl) {
      throw new Error('No container wrapper element available');
    }
    return this._cfgContainerEl.nativeElement;
  }

  /**
   * Returns the inner size of the preview area
   */
  private get previewElementSize(): D2 {
    return new D2(this.previewElement.clientWidth, this.previewElement.clientHeight);
  }

  /**
   * Returns the host (container) size
   */
  private get previewAreaSize(): D2 {
    return new D2(this.previewAreaElement.clientWidth, this.previewAreaElement.clientHeight);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public createFirstPage(): void {
    const newEntry = Create.cmsConfiguratorPage(
      Create.cmsPage({
        name: uuidv4(),
        label: 'Seite 1',
      }),
    );
    this.cfgStateService.createEntry(newEntry, {
      name: this.cfgStateService.getCfgUtil().getCfg().name,
      pos: 0,
    });
  }

  public select(entryName: string): void {
    this.cfgEditorService.selectEntry(entryName);
  }

  @HostListener('window:resize', ['$event'])
  protected onResize(): void {
    this.checkInit();
    this.update();
    this.pageHeight = this.previewAreaSize.y - WRAPPER_MARGIN;
  }

  protected prevPage(): void {
    this.toPage('prev');
  }

  protected nextPage(): void {
    this.toPage('next');
  }

  private toPage(dir: 'next' | 'prev') {
    const currentSelection = this.cfgEditorService.currentSelection;
    if (!currentSelection) {
      return;
    }
    const currentPage = this.cfgStateService.getCfgUtil().getParentPage(currentSelection);
    const pages = this.cfgStateService.getCfgUtil().getPages();
    const currentIndex = pages.findIndex((page) => page.name === currentPage.name);
    const nextPageIndex = dir === 'next' ? currentIndex + 1 : currentIndex - 1;
    const nextPage = pages[nextPageIndex] ?? undefined;
    if (nextPage) {
      this.cfgEditorService.selectEntry(nextPage.name);
    }
  }

  private updateButtons(): void {
    const currentSelection = this.cfgEditorService.currentSelection;
    if (!currentSelection) {
      return;
    }
    const currentPage = this.cfgStateService.getCfgUtil().getParentPage(currentSelection);
    if (!currentPage) {
      this.btnVisibilityChange.next({ prev: false, next: false });
      return;
    }
    const pages = this.cfgStateService.getCfgUtil().getPages();
    const currentIndex = pages.findIndex((page) => page.name === currentPage.name);
    this.btnVisibilityChange.next({ prev: currentIndex > 0, next: currentIndex < pages.length - 1 });
  }

  private checkInit(): void {
    if (this._dndAreaContainerEl === undefined) {
      this.initialized = false;
      return;
    }

    if (this._cfgContainerEl === undefined) {
      this.initialized = false;
      return;
    }

    if (this.previewElementSize.lowerValue() === 0) {
      this.initialized = false;
      return;
    }

    if (this.pageCount === undefined) {
      this.initialized = false;
      return;
    }

    this.initialized = true;

    window.setTimeout(() => {
      if (this.initialized === false) {
        return;
      }
      window.setTimeout(() => {
        if (this.initialized === false) {
          return;
        }
        this.showPreview = true;
        this.transitionTime = TRANSITION_TIME;
        this.focusCurrentSelection();
        this.cfRef.detectChanges();
      }, 200);
    }, 0);
  }

  private update(): void {
    if (!this.initialized) {
      return;
    }
    this.moveToActiveArea();
  }

  private moveTo(positionAbs: D2): void {
    if (!positionAbs.valid()) {
      throw new Error('invalid position: ' + JSON.stringify(positionAbs));
      return;
    }

    this.positionAbs = positionAbs;
    this.positionRel = this.getPositionRel(positionAbs);
  }

  private moveToActiveArea(): void {
    if (this.activeArea === undefined) {
      return;
    }
    const activeAreaAbs = new Area(this.getAbs(this.activeArea.start), this.getAbs(this.activeArea.end));
    this.moveAreaTo(activeAreaAbs, ACTIVE_AREA_POS);
  }

  private moveAreaTo(area: Area, pos: 'left' | 'center' = 'center'): void {
    if (pos === 'left') {
      this.moveAreaToLeft(area);
    } else {
      this.moveAreaToCenter(area);
    }
  }

  /**
   * moves the map to focus the active area in the center
   */
  private moveAreaToCenter(area: Area): void {
    if (!area.valid()) {
      throw new Error('Invalid area: ' + JSON.stringify(area));
    }

    if (this.previewAreaSize.subtract(this.previewElementSize).x >= 0) {
      // if the area is smaller than the host, we always show everything
      area = this.getElementArea(this.previewElement, this.previewAreaElement);
    }

    // calculate scroll position for the top left corner of the area
    const scrollPos = area.end
      // subtract end from start to get the area size
      .subtract(area.start)
      // calculates diff between area and host
      .subtract(this.previewAreaSize)
      // divide to get space for each side
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      .divide(2)
      // add area start to the free space on the top/left side to get corner position
      .add(area.start)
      .multiply(-1);

    const previewSizeDiff = this.previewAreaSize.subtract(this.previewElementSize);
    if (previewSizeDiff.x < 0) {
      // if the area is bigger than the wrapper, we do not show white space on left or right
      if (scrollPos.x > 0) {
        // stay on left end
        scrollPos.x = 0;
      } else if (scrollPos.x < previewSizeDiff.x) {
        // stay on right end
        scrollPos.x = previewSizeDiff.x - 100;
      }
    }

    this.moveTo(scrollPos);
  }

  /**
   * moves the map to focus the active area on the left border
   */
  private moveAreaToLeft(area: Area): void {
    if (!area.valid()) {
      throw new Error('Invalid area: ' + JSON.stringify(area));
    }
    const scrollPos = area.start.multiply(-1);
    this.moveTo(scrollPos);
  }

  /**
   * tries to move an element to the center
   */
  private focusCurrentSelection() {
    const entryName = this.cfgEditorService.currentSelection;
    if (entryName) {
      this.focusAreaByName(entryName);
    } else {
      this.focusFirstPage();
    }
  }

  /**
   * tries to move an element to the center
   */
  private focusAreaByName(name: string) {
    const elementToFocus: HTMLElement | null = this.elementRef.nativeElement.querySelector(
      `[data-entry-name="${name}"]`,
    );
    if (elementToFocus) {
      this.focusHtmlElement(elementToFocus);
    }
  }

  /**
   * moves the complete container to the center
   */
  private focusFirstPage() {
    const elementToFocus: HTMLElement | null = this.elementRef.nativeElement.querySelector('.first-page');
    if (elementToFocus) {
      this.focusHtmlElement(elementToFocus);
    }
  }

  /**
   * tries to move an element to the center
   */
  private focusHtmlElement(elementToFocus: HTMLElement): void {
    const area = this.getElementArea(elementToFocus, this.previewAreaElement);
    if (!area?.valid()) {
      return;
    }
    this.activeArea = area.safeDivide(this.previewElementSize);
    this.moveToActiveArea();
  }

  /**
   * returns a relative value to the scaled map size
   */
  private getPositionRel(mapPosAbs: D2): D2 {
    return mapPosAbs.divide(this.previewElementSize);
  }

  /**
   * returns a relative value to the scaled map size
   */
  private getAbs(relative: D2): D2 {
    return relative.multiply(this.previewElementSize);
  }

  /**
   * returns the area of the given element inside a reference element
   */
  private getElementArea(elementToFocus: HTMLElement, referenceElement: HTMLElement): Area | undefined {
    // Get elements absolute offsets
    const focusElementOffset = this.absOffset(elementToFocus);
    const mapElementOffset = this.absOffset(referenceElement);

    // Calculate focus area start and end position
    const start = focusElementOffset.subtract(mapElementOffset);
    const focusAreaSize = new D2(elementToFocus.clientWidth, elementToFocus.clientHeight);
    const end = start.add(focusAreaSize);
    return new Area(start, end);
  }

  /**
   * returns the absolute position of the element without considering transformations
   * which is not the case when using .top/.left
   * this is necessary for the position of nested map elements
   */
  private absOffset(element: HTMLElement): D2 {
    let el = element;
    const offset = new D2(0, 0);

    do {
      offset.x += el.offsetLeft;
      offset.y += el.offsetTop;

      if (el.offsetParent) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        el = el.offsetParent as HTMLElement;
      }
    } while (el.offsetParent);

    return offset;
  }
}
