import { Component, ElementRef, ViewChild } from '@angular/core';
import { environment } from '../environments/environment';
import { AuthService } from '@kfd/web-core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AVAILABLE_FEATURES, COMMON_WEB_APP_ROUTES, ICON } from '@kfd/core';
import { CmsContextService } from './services/cms-context.service';
import { ErrorReportingService } from './services/error-reporting.service';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { UserService } from './services/user.service';

@Component({
  selector: 'kfd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @ViewChild('serviceMenu') public serviceMenu: Menu | undefined;
  @ViewChild('serviceMenuBtn') public serviceMenuBtn: ElementRef | undefined;
  serviceMenuAutoClose = true;

  environment = environment;
  icon = ICON;
  features = AVAILABLE_FEATURES;
  signed$: Observable<{ in: boolean }>;
  projectId: string | undefined;

  serviceItems: MenuItem[] = [
    {
      icon: 'pi ' + ICON.DOCU,
      label: 'Dokumentation',
      command: () => {
        window.open(environment.apps.web + '/support/documentation', '_blank');
      },
    },
    {
      icon: 'pi ' + ICON.SERVICE,
      label: 'Kontakt & Support',
      command: () => {
        window.open(environment.apps.web + '/support/contact', '_blank');
      },
    },
    {
      icon: 'pi ' + ICON.WARN,
      styleClass: 'error-reporting',
      id: 'errorReporting',
      label: 'Fehler melden',
      command: () => {
        this.errorReportingService.showErrorReporting();
      },
    },
  ];
  protected readonly COMMON_WEB_APP_ROUTES = COMMON_WEB_APP_ROUTES;

  constructor(
    private readonly errorReportingService: ErrorReportingService,
    readonly authService: AuthService,
    readonly userService: UserService,
    readonly ctx: CmsContextService,
  ) {
    this.signed$ = authService.onSignInChange().pipe(map((signedId) => ({ in: signedId })));

    //check if we are in mandant scope or not
    ctx.onValueChange('projectId').subscribe({
      next: (value) => {
        this.projectId = value;
      },
    });

    //handle support speed dial error behavior
    // this.eventService.subscribe(Events.Error, () => {
    //   this.serviceMenuAutoClose = false;
    //
    //   if (this.serviceMenu && this.serviceMenuBtn) {
    //     this.serviceMenu.show({
    //       currentTarget: this.serviceMenuBtn.nativeElement,
    //     });
    //   }
    //   window.setTimeout(() => {
    //     const errorReportingElement = document.getElementById('errorReporting');
    //     if (errorReportingElement) {
    //       errorReportingElement.classList.add('highlight');
    //     }
    //   }, 100);
    //   window.setTimeout(() => {
    //     if (this.serviceMenu) {
    //       this.serviceMenu.hide();
    //     }
    //   }, 10000);
    // });
  }

  protected togglePreviewMode(enable: boolean): void {
    this.userService.togglePreviewMode(enable);
  }

  // onHideServiceMenu() {
  //   const errorReportingElement = document.getElementById('errorReporting');
  //   if (errorReportingElement) {
  //     errorReportingElement.classList.remove('highlight');
  //   }
  //   this.serviceMenuAutoClose = true;
  // }
}
