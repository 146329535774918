import { Injectable } from '@angular/core';
import { AuthService } from '@kfd/web-core';
import { Observable, Subject } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { ICON, JwtUser, USER_ROLES } from '@kfd/core';
import { map, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CmsMenuService {
  private projectMenuItems: MenuItem[] = [];
  private menuUpdate = new Subject<true>();

  constructor(private readonly authService: AuthService) {}

  public getMenu(jwtUser: JwtUser | undefined): Observable<{
    main: MenuItem[];
    meta: MenuItem[];
  }> {
    return this.menuUpdate.pipe(
      startWith(true),
      // withLatestFrom(this.authService.onSignInChange()),
      // mergeMap(([, signedIn]) => (signedIn ? this.authService.currentUser() : of(undefined))),
      map(() => ({
        main: this.updateMenu(jwtUser),
        meta: this.updateMetaMenu(jwtUser),
      })),
    );
  }

  public setProjectMenu(menuItems: MenuItem[]): void {
    this.projectMenuItems = menuItems;
    this.menuUpdate.next(true);
  }

  protected updateMenu(currentUser: JwtUser | undefined): MenuItem[] {
    const menuItems: MenuItem[] = [];

    if (currentUser) {
      menuItems.push({
        label: 'Dashboard',
        tooltip: 'Dashboard',
        routerLink: '/dashboard',
        icon: ICON.DASHBOARD,
        automationId: 'menu-btn-main-dashboard',
        routerLinkActiveOptions: { exact: true },
      });

      if (this.projectMenuItems.length === 0) {
        menuItems.push({
          label: 'Projekte',
          tooltip: 'Projektübersicht',
          routerLink: '/projects',
          icon: ICON.PROJECT,
          automationId: 'menu-btn-project-overview',
          routerLinkActiveOptions: { exact: true },
        });
      }
      return [...menuItems, ...this.projectMenuItems];
    }

    return [...menuItems];
  }

  protected updateMetaMenu(currentUser: JwtUser | undefined): MenuItem[] {
    const menuItems: MenuItem[] = [];

    menuItems.push({
      label: 'Account',
      tooltip: 'Accounteinstellungen',
      routerLink: '/auth/profile',
      icon: ICON.ACCOUNT,
      automationId: 'menu-btn-userprofile',
      routerLinkActiveOptions: { exact: true },
    });

    if (currentUser) {
      menuItems.push({
        label: 'Benachrichtigungen',
        tooltip: 'Benachrichtigungen',
        routerLink: '/notifications',
        icon: ICON.NOTIFICATION,
        automationId: 'menu-btn-notifications',
        routerLinkActiveOptions: { exact: true },
      });
    }

    menuItems.push({
      label: 'Hilfe',
      tooltip: 'Hilfestellungen',
      routerLink: '/service',
      icon: ICON.HELP,
      automationId: 'menu-btn-service',
      routerLinkActiveOptions: { exact: true },
    });

    if (currentUser?.roles.includes(USER_ROLES.ADMIN)) {
      menuItems.push({
        label: 'Administration',
        tooltip: 'Administration',
        routerLink: '/admin',
        icon: ICON.ADMINISTRATION,
        automationId: 'menu-btn-administration',
        routerLinkActiveOptions: { exact: true },
      });
    }

    return menuItems;
  }
}
