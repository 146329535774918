import { Id, USER_ROLES } from './core.models';

export interface DBUser {
  _id: Id;
  created: Date;
  email: string;
  name: string;
  password: string;
  emailUnverified?: {
    email: string;
    created: Date;
    code: string;
  };
  passwordReset?: {
    created: Date;
    code: string;
  };
  roles?: USER_ROLES[];
  refreshTokenHash?: string;
  validUntil?: Date;
  privacyAccepted?: Date;
  disabled?: true;
  lastLogin?: Date;
}
