<div class="page-wrapper flex flex-column p-flex-1">
  <div class="page-toolbar">
    <div class="page-limiter page-padding-x flex-column">
      <ng-container *ngIf="signed$ | async as signed">
        <p-toolbar styleClass="invisible no-border no-padding-x">
          <div class="p-toolbar-group-left">
            <!--            <a data-automationId="btn-home" routerLink="/" class="logo"></a>-->
            <a class="logo-full" data-automationId="btn-home" routerLink="/"></a>
            <!--            <a class="logo-full"></a>-->
            <!--            <a [href]="environment.apps.web">-->
            <!--              <p-avatar class="ml-2" icon="pi pi-globe" shape="circle"></p-avatar>-->
            <!--            </a>-->
            <!--            <a *ngIf="features.ADMIN_TOOLS | feature | async" routerLink="/admin">-->
            <!--              <p-avatar class="ml-2" icon="pi pi-shield" shape="circle"></p-avatar>-->
            <!--            </a>-->
          </div>
          <div class="p-toolbar-group-right">
            <kfd-user-icon>
              <p-button
                *ngIf="projectId"
                [routerLink]="'/project/' + projectId + '/settings/user-settings'"
                [text]="true"
                class="mt-2"
                icon="pi {{ icon.SETTINGS }}"
                label="Einstellungen"
              ></p-button>
              <div *kfdOnlyAdmin class="flex">
                <p-inputSwitch (onChange)="togglePreviewMode($event.checked)"></p-inputSwitch>
                <span>Preview enabled</span>
              </div>
            </kfd-user-icon>
            <kfd-user-notifications *ngIf="signed.in" class="ml-2"></kfd-user-notifications>
          </div>
        </p-toolbar>
      </ng-container>
    </div>
  </div>

  <div class="main-content page-limiter">
    <kfd-user-messages [profileLink]="COMMON_WEB_APP_ROUTES.user.activation.root"></kfd-user-messages>
    <router-outlet></router-outlet>
  </div>
  <!--  <div class="page-toolbar">-->
  <!--    <div class="page-limiter page-padding-x flex-column">-->
  <!--      <div class="text-xs my-2">-->
  <!--        <a [href]="environment.apps.web">Web</a>-->
  <!--        <a [href]="environment.apps.web + '/content/imprint'" class="ml-2">Impressum</a>-->
  <!--        <a [href]="environment.apps.web + '/content/privacy'" class="ml-2">Datenschutz</a>-->
  <!--        <a [href]="environment.apps.web + '/content/contact'" class="ml-2">Kontakt</a>-->
  <!--        <a [href]="environment.apps.web + '/support'" class="ml-2">Support</a>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

  <p-confirmDialog data-automationId="confirm-dialog"></p-confirmDialog>
  <p-toast key="error" position="bottom-center"></p-toast>
  <p-toast key="info" position="bottom-center"></p-toast>
</div>
