import { Injectable } from '@angular/core';
import { CmsContextService } from '../services/cms-context.service';
import { unvTimeout } from '@kfd/core';
import { CanActivate, CanDeactivate } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class UnsetCtxGuard implements CanActivate, CanDeactivate<boolean> {
  constructor(private ctx: CmsContextService) {}

  canActivate(): boolean {
    this.ctx.unset();
    return true;
  }

  canDeactivate(): boolean {
    //the timeout avoids unset before components has been destroyed and trying to access the properties
    unvTimeout(() => {
      this.ctx.unset();
    }, 100);
    return true;
  }
}
