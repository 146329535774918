import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Observable } from 'rxjs';
import { AVAILABLE_FEATURES } from '@kfd/core';

/**
 * usage
 * *ngIf="'feature-name' | feature | async"
 */
@Pipe({
  name: 'feature',
})
export class UserFeaturePipe implements PipeTransform {
  constructor(private userService: UserService) {}

  transform(feature: AVAILABLE_FEATURES): Observable<boolean> {
    return this.userService.userHasFeature(feature);
  }
}
