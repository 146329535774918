import { Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ICON } from '@kfd/core';
import { environment } from '../../../../environments/environment';
import { BreakpointService, WebScreenSize } from '@kfd/web-core';
import { ApiProjectUserService } from '../../../services/api/api-project-user.service';

const COLLAPSED_SIZES = [WebScreenSize.XS, WebScreenSize.MD, WebScreenSize.LG];

@Component({
  selector: 'kfd-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
  standalone: false,
})
export class SideNavigationComponent {
  protected readonly ICON = ICON;
  protected readonly environment = environment;
  protected collapsed = true;

  constructor(
    private readonly breakpointService: BreakpointService,
    private readonly apiProjectUserService: ApiProjectUserService,
  ) {
    this.breakpointService.change().subscribe((size) => {
      const manuallyChanged = this.apiProjectUserService.getLocalSetting('sidebarCollapsed-' + size);

      if (manuallyChanged) {
        this.collapsed = manuallyChanged === 'collapsed';
      } else {
        this.collapsed = COLLAPSED_SIZES.includes(size);
      }
    });
  }

  private _menuItems: MenuItem[] | undefined;

  get menuItems(): MenuItem[] | undefined {
    return this._menuItems;
  }

  @Input()
  set menuItems(value: MenuItem[] | undefined) {
    if (value !== undefined) {
      this._menuItems = value;
    }
    // this.updateMenu();
  }

  private _metaMenuItems: MenuItem[] = [];

  get metaMenuItems(): MenuItem[] | undefined {
    return this._metaMenuItems;
  }

  @Input()
  set metaMenuItems(value: MenuItem[] | undefined) {
    if (value !== undefined) {
      this._metaMenuItems = value;
    }
    // this.updateMenu();
  }

  protected toggleCollapse(): void {
    this.collapsed = !this.collapsed;
    const size = this.breakpointService.currentSize();
    this.apiProjectUserService.setLocalSetting('sidebarCollapsed-' + size, this.collapsed ? 'collapsed' : 'expanded');
  }
}
