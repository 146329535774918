<kfd-dialog-layout
  (reject)="close()"
  [hideFooter]="false"
  [showAcceptBtn]="false"
  dialogTitle="Projekt wählen"
>

  <kfd-project-selection
    (selection)="select($event)"
    data-automationId="project-selection-dialog"
  ></kfd-project-selection>

  <!--<div class="flex justify-content-center">-->
  <!--  <button-->
  <!--    (click)="select()"-->
  <!--    class="reload-btn m-2"-->
  <!--    icon="pi {{ icon.CONFIGURATIONS }}"-->
  <!--    label="Zur Übersicht"-->
  <!--    pButton-->
  <!--    type="button"-->
  <!--  ></button>-->
  <!--</div>-->
</kfd-dialog-layout>
