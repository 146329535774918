import { Injectable } from '@angular/core';
import { AuthService, CachedWebEndpointService, WebEndpointService } from '@kfd/web-core';
import { BehaviorSubject, mergeMap, Observable, of } from 'rxjs';
import {
  AVAILABLE_FEATURES,
  BaseFilter,
  CACHE_L,
  cmsServiceEP,
  CustomerRequestFilter,
  Id,
  SETTINGS_KEYS,
  UserSetting,
} from '@kfd/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiProjectUserService {
  private readonly cwes: CachedWebEndpointService;
  private featuresSubject$ = new BehaviorSubject<AVAILABLE_FEATURES[]>([]);

  private localSettings = new Map<string, string>();

  constructor(
    readonly webEndpointService: WebEndpointService,
    private readonly authService: AuthService,
  ) {
    this.cwes = new CachedWebEndpointService(webEndpointService, CACHE_L);
    this.authService.onSignInChange().subscribe({
      next: (signedIn) => {
        this.cwes.clearCache();
        if (signedIn) {
          this.loadFeatures();
        } else {
          this._features = [];
          this.featuresSubject$.next(this._features);
        }
      },
    });
  }

  private _features: AVAILABLE_FEATURES[] | undefined;

  get features(): AVAILABLE_FEATURES[] | undefined {
    return this._features;
  }

  public clearCache(): void {
    this.cwes.clearCache();
  }

  public getCustomerRequestFilters(projectId: Id): Observable<BaseFilter<CustomerRequestFilter>[]> {
    return this.cwes.get(cmsServiceEP.currentUser.customerRequestFilters, [projectId.toString()]);
  }

  public saveCustomerRequestFilters(
    projectId: Id,
    filterList: BaseFilter<CustomerRequestFilter>[],
  ): Observable<boolean> {
    return this.cwes.post(cmsServiceEP.currentUser.saveCustomerRequestFilters, [projectId.toString()], {
      filterList,
    });
  }

  public getFavoriteProjects(useCache = true): Observable<string[]> {
    return this.getSetting(SETTINGS_KEYS.FAVORITE_PROJECTS, useCache).pipe(
      map((value) => (value ? value.split(',') : [])),
    );
  }

  public getSettings(useCache = true): Observable<UserSetting[]> {
    return this.cwes.get(cmsServiceEP.currentUser.settings, [], {
      useCache,
    });
  }

  /**
   * get specific setting
   * @param key
   * @param useCache
   */
  public getSetting(key: SETTINGS_KEYS, useCache = true): Observable<string | undefined> {
    return this.getSettings(useCache).pipe(map((settings) => this.getSettingFromList(key, settings)));
  }

  public saveSetting(key: SETTINGS_KEYS, value: string, projectId?: Id): Observable<boolean> {
    return this.getSetting(key, true).pipe(
      mergeMap((savedValue) => {
        if (savedValue === value) {
          return of(true);
        }
        return this.cwes.post(cmsServiceEP.currentUser.saveSetting, [key], {
          projectId,
          key,
          value,
        });
      }),
    );
  }

  public removeSetting(key: SETTINGS_KEYS, projectId?: Id): Observable<boolean> {
    return projectId
      ? this.cwes.delete(cmsServiceEP.currentUser.removeProjectSetting, [projectId.toString(), key])
      : this.cwes.delete(cmsServiceEP.currentUser.removeSetting, [key]);
  }

  public getFeatures(): Observable<AVAILABLE_FEATURES[]> {
    if (this.features === undefined) {
      this.loadFeatures();
    }
    return this.featuresSubject$.asObservable();
  }

  /**
   * Check if a feature is enabled (async)
   * @param feature
   */
  public userHasFeature(feature: AVAILABLE_FEATURES): Observable<boolean> {
    return this.getFeatures().pipe(map((features) => this.isFeatureEnabled(features, feature)));
  }

  /**
   * Check if a feature is enabled (sync)
   * @param features
   * @param feature
   */
  public isFeatureEnabled(features: AVAILABLE_FEATURES[], feature: AVAILABLE_FEATURES): boolean {
    return Array.isArray(features) && features.indexOf(feature) >= 0;
  }

  /**
   *
   * @param key
   * @param settings
   */
  public getSettingFromList(key: SETTINGS_KEYS, settings: UserSetting[]): string | undefined {
    return settings.find((value) => value.key === key)?.value;
  }

  public loadFeatures(useCache = true): void {
    this.cwes
      .get(cmsServiceEP.currentUser.features, [], {
        useCache,
      })
      .subscribe((features) => {
        this._features = features;
        this.featuresSubject$.next(this._features);
      });
  }

  public setLocalSetting(key: string, value: string): void {
    this.localSettings.set(key, value);
  }

  public getLocalSetting(key: string): string | undefined {
    return this.localSettings.get(key);
  }
}
