import { Component, Input } from '@angular/core';
import { MetaData } from '@kfd/core';

@Component({
  selector: 'kfd-meta-view',
  templateUrl: './meta-view.component.html',
  styleUrls: ['./meta-view.component.scss'],
  standalone: false,
})
export class MetaViewComponent {
  @Input()
  public meta: MetaData | undefined;
}
