import { Observable } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { Injectable } from '@angular/core';
import { FullscreenLoginDialogComponent } from '../common/fullscreen-login-dialog/fullscreen-login-dialog.component';
import { AuthService } from '@kfd/web-core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private readonly dialogService: DialogService,
    private readonly authService: AuthService,
  ) {}

  public showLogin(info?: string): Observable<boolean> {
    const ref = this.dialogService.open(FullscreenLoginDialogComponent, {
      styleClass: 'full-screen-dialog',
      closable: false,
      data: { info },
    });

    if (ref) {
      return ref.onClose.pipe(map(() => this.authService.isLoggedIn));
    }
  }
}
