import { EventEmitter, Injectable } from '@angular/core';
import { ApiConfigurationService } from './api/api-configuration.service';

import {
  CLS,
  CmsChildWrapper,
  CmsConfigurator,
  CmsGenericEntry,
  CmsGenericParentEntry,
  CmsIdEntry,
  CmsParentEntry,
  ConditionGroup,
  ConfigurationUtil,
  Field,
  FIELD_TYPES,
  Id,
  Page,
  UsedBy,
} from '@kfd/core';
import { flatMap, map, mergeMap, Observable, tap } from 'rxjs';
import { Operation } from 'fast-json-patch';
import { CmsContextService } from './cms-context.service';
import { InsertResponse, UpdateResponse } from '@kfd/web-core';

export interface EntryPosition {
  pos: number;
  entries: number;
}

/**
 * @deprecated
 */
@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  configurationReset: EventEmitter<CmsConfigurator> = new EventEmitter<CmsConfigurator>();
  configurationChange: EventEmitter<CmsConfigurator> = new EventEmitter<CmsConfigurator>();
  entryChange: EventEmitter<CmsGenericEntry> = new EventEmitter<CmsGenericEntry>();
  configuration: CmsConfigurator | undefined;
  originConfiguration: CmsConfigurator | undefined;
  changedEntries = new Map<string, CmsGenericEntry>();

  constructor(
    public ctx: CmsContextService,
    private _cmsService: ApiConfigurationService,
  ) {}

  private static ref<T>(value: T, byRef = false): T | undefined {
    if (value === undefined) {
      return;
    }
    if (!byRef) {
      return JSON.parse(JSON.stringify(value));
    }
    return value;
  }

  public getConfiguration(): CmsConfigurator {
    if (this.configuration === undefined) {
      throw new Error('No configuration set');
    }
    return this.configuration;
  }

  init() {
    if (!this.ctx.hasConfiguratorId()) {
      throw new Error('Cannot initialize configuration without configuration id');
    }
    this.updateConfiguration().subscribe((configuration) => {
      this.ctx.configurationName = configuration.name;
      this.configurationReset.emit(configuration);
      this.changedEntries.clear();
    });
  }

  reset() {
    this.configuration = undefined;
    this.originConfiguration = undefined;
    this.changedEntries = new Map<string, CmsGenericEntry>();
  }

  public updateConfiguration(): Observable<CmsConfigurator> {
    return this._cmsService.initConfiguration(this.ctx.projectId, this.ctx.configuratorId).pipe(
      map((configuration) => {
        this.originConfiguration = JSON.parse(JSON.stringify(configuration));
        ConfigurationUtil.find(configuration, (entry: CmsGenericEntry) => {
          if (this.changedEntries.has(entry._id.toString())) {
            const changedEntry = this.changedEntries.get(entry._id.toString());
            /* tslint:disable-next-line */
            entry = Object.assign(entry, changedEntry);
          }
        });
        this.configuration = configuration;
        this.configurationChange.emit(configuration);
        return configuration;
      }),
    );
  }

  public onConfigurationReset(): Observable<CmsConfigurator> {
    return new Observable((observer) => {
      this.configurationReset.subscribe((configuration) => {
        observer.next(configuration);
      });
    });
  }

  public onConfigurationChange(initial = true): Observable<CmsConfigurator> {
    return new Observable((observer) => {
      if (initial && this.configuration) {
        observer.next(this.configuration);
      }
      this.configurationChange.subscribe((configuration) => {
        observer.next(configuration);
      });
    });
  }

  public onEntryUpdate<T extends CmsGenericEntry>(entryId: Id, initial?: boolean, dirty?: boolean): Observable<T> {
    return new Observable((observer) => {
      if (initial === true) {
        observer.next(this.getEntry<T>(entryId, false, dirty));
      }
      this.configurationChange.subscribe(() => {
        observer.next(this.getEntry<T>(entryId, false, dirty));
      });

      this.entryChange.subscribe((entry) => {
        if (!entryId || entry._id === entryId) {
          observer.next(this.getEntry<T>(entryId, false, dirty));
        }
      });
    });
  }

  public hasConditions(entryId: Id, childEntryId: string): boolean {
    return this.conditionSize(entryId, childEntryId) > 0;
  }

  public conditionSize(entryId: Id, childEntryId: Id): number {
    const entryChild = this.getEntryChild(entryId, childEntryId);
    return entryChild?.condition ? entryChild.condition.conditions.length : 0;
  }

  public isDirty(entryId: Id): boolean {
    const isSelfDirty = this.changedEntries.has(entryId.toString());
    if (isSelfDirty) {
      return true;
    }
    return this.hasDirtyChild(entryId);
  }

  public hasDirtyChild(entryId: Id): boolean {
    const children = this.getChildren(entryId);
    for (const child of children) {
      const isChildDirty = this.changedEntries.has(child.entry._id.toString());
      if (isChildDirty) {
        return true;
      }
      if (this.hasDirtyChild(child.entry._id.toString())) {
        return true;
      }
    }
    return false;
  }

  public getChangedEntries(): CmsGenericEntry[] {
    return Array.from(this.changedEntries.values());
  }

  public getEntry<T extends CmsIdEntry>(entryId: Id, byRef = false, dirty = false): T | undefined {
    if (dirty) {
      if (this.changedEntries.has(entryId.toString())) {
        const entry = this.changedEntries.get(entryId.toString()) as unknown as T;
        return ConfigurationService.ref<T>(entry, byRef);
      }
    }

    return ConfigurationUtil.find(this.getConfiguration(), (entry: T) => {
      if (entryId === entry._id) {
        return ConfigurationService.ref<T>(entry, byRef);
      }
      return;
    });
  }

  public getEntryByName<T extends CmsGenericEntry>(name: string, cls?: CLS): T | undefined {
    return ConfigurationUtil.find(this.getConfiguration(), (entry: T) => {
      if (name === entry.name) {
        if (cls) {
          if (cls === entry.cls) {
            return entry;
          }
        } else {
          return entry;
        }
      }
      return;
    });
  }

  public getPages(): Page[] {
    const pages: Page[] = [];
    ConfigurationUtil.find<Page>(this.getConfiguration(), (entry: Page) => {
      if (entry.cls === CLS.PAGE) {
        pages.push(entry);
      }
    });
    return pages;
  }

  public getFields(): Field[] {
    const fields: Field[] = [];
    ConfigurationUtil.find(this.getConfiguration(), (entry: Field) => {
      if (entry.cls === CLS.FIELD) {
        fields.push(entry);
      }
    });
    return fields;
  }

  public getFieldsOfType(type: FIELD_TYPES | FIELD_TYPES[], excludeList: string[] = []): Field[] {
    const types = Array.isArray(type) ? type : [type];

    const result: Field[] = [];
    this.getFields().forEach((field) => {
      if (excludeList.indexOf(field.name) >= 0) {
        return;
      }
      if (types.indexOf(field.config.type) >= 0) {
        result.push(field);
      }
    });
    return result;
  }

  public getEntryPosition(entryId: Id, childEntryId: string): EntryPosition | undefined {
    const parentEntry = this.getEntry<CmsParentEntry>(entryId);
    if (!parentEntry || !parentEntry.children) {
      return;
    }
    const index = parentEntry.children.findIndex((e) => e.entry._id === childEntryId);

    return {
      pos: index + 1,
      entries: parentEntry.children.length,
    } as EntryPosition;
  }

  public getEntryChild(entryId: Id, childEntryId: Id, byRef = false): CmsChildWrapper | undefined {
    const parentEntry = this.getEntry<CmsParentEntry>(entryId);
    if (!parentEntry || !parentEntry.children) {
      return;
    }
    const childEntry = parentEntry.children.find((e) => e.entry._id === childEntryId) as CmsChildWrapper;
    if (!childEntry) {
      return;
    }
    if (byRef) {
      return childEntry;
    }
    return JSON.parse(JSON.stringify(childEntry));
  }

  public getOriginEntry<T>(entryId: Id): T | undefined {
    return ConfigurationUtil.find(this.getOriginConfiguration(), (entry: CmsIdEntry) => {
      if (entryId === entry._id) {
        return JSON.parse(JSON.stringify(entry));
      }
    });
  }

  public resetEntry(entryId: Id): void {
    const originEntry = this.getOriginEntry(entryId);

    if (!originEntry) {
      this.changedEntries.delete(entryId.toString());
      return;
    }

    this.resetChangedEntry(entryId);
  }

  public getChildren(entryId: Id): CmsChildWrapper[] {
    const entry = this.getEntry<CmsGenericParentEntry>(entryId);
    if (!entry?.children) {
      return [];
    }
    return entry.children;
  }

  public changeEntry(entry: CmsGenericEntry): void {
    this.changedEntries.set(entry._id.toString(), entry);
    this.entryChange.emit(entry);
  }

  public resetChangedEntry(entryId: Id): void {
    this.changedEntries.delete(entryId.toString());
    this.entryChange.emit(this.getOriginEntry(entryId));
  }

  public removeConfiguration(): Observable<boolean> {
    this.changedEntries.clear();
    return this._cmsService.configurationToTrash(this.ctx.projectId, this.ctx.configuratorId);
  }

  public removeEntry(entryId: Id, parentId: string, removeChildren: boolean): Observable<boolean> {
    return this._cmsService
      .removeEntry(this.ctx.projectId, this.ctx.configuratorId, entryId, parentId, removeChildren)
      .pipe(flatMap((res) => this.updateConfiguration().pipe(map(() => res))));
  }

  createNewEntry(
    parentId: string,
    type: CLS,
    label: string,
    name: string,
    fieldType: FIELD_TYPES,
  ): Observable<InsertResponse> {
    return this._cmsService
      .createEntryByType(this.ctx.projectId, this.ctx.configuratorId, parentId, type, label, name, fieldType)
      .pipe(mergeMap((insertResponse) => this.updateConfiguration().pipe(map(() => insertResponse))));
  }

  saveEntryChanges(entryId: Id, operations: Operation[]): Observable<InsertResponse> {
    return this._cmsService.changeEntry(this.ctx.projectId, this.ctx.configuratorId, entryId, operations).pipe(
      tap(() => this.changedEntries.delete(entryId.toString())),
      flatMap((res) => this.updateConfiguration().pipe(map(() => res))),
    );
  }

  saveConditions(entryId: Id, childEntryId: Id, conditionGroup: ConditionGroup): Observable<UpdateResponse> {
    return this._cmsService
      .saveConditions(this.ctx.projectId, this.ctx.configuratorId, entryId, childEntryId, conditionGroup)
      .pipe(flatMap((res) => this.updateConfiguration().pipe(map(() => res))));
  }

  getEntryUsages(entryId: Id): Observable<Record<string, UsedBy[][]>> {
    return this._cmsService.getEntryUsages(this.ctx.projectId, this.ctx.configuratorId, entryId);
  }

  checkCfgName(value: string): Observable<boolean> {
    return this._cmsService.checkCfgName(this.ctx.projectId, value);
  }

  checkCfgCode(value: string): Observable<boolean> {
    return this._cmsService.checkCfgCode(this.ctx.projectId, value);
  }

  public draft(): Observable<InsertResponse> {
    return this._cmsService
      .draft(this.ctx.projectId, this.ctx.configuratorId)
      .pipe(flatMap((res) => this.updateConfiguration().pipe(map(() => res))));
  }

  public publish(): Observable<InsertResponse> {
    return this._cmsService
      .publish(this.ctx.projectId, this.ctx.configuratorId)
      .pipe(flatMap((res) => this.updateConfiguration().pipe(map(() => res))));
  }

  public removeDraft(): Observable<boolean> {
    return this._cmsService
      .removeDraft(this.ctx.projectId, this.ctx.configuratorId)
      .pipe(flatMap((res) => this.updateConfiguration().pipe(map(() => res))));
  }

  public removePublished(): Observable<boolean> {
    return this._cmsService
      .removePublished(this.ctx.projectId, this.ctx.configuratorId)
      .pipe(flatMap((res) => this.updateConfiguration().pipe(map(() => res))));
  }

  private getOriginConfiguration(): CmsConfigurator {
    if (this.originConfiguration === undefined) {
      throw new Error('No origin configuration set');
    }
    return this.originConfiguration;
  }
}
