import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

export enum ZoomDirections {
  In,
  Out,
  Default,
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[zoom]',
  standalone: false,
})
export class ZoomDirective {
  @Output()
  public readonly zoom = new EventEmitter<ZoomDirections>();

  @HostListener('wheel', ['$event'])
  private wheel(e: WheelEvent): void {
    if (!e.ctrlKey) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    if (e.deltaY < 0) {
      this.zoom.emit(ZoomDirections.In);
    } else {
      this.zoom.emit(ZoomDirections.Out);
    }
  }

  @HostListener('document:keydown.control.arrowUp', ['$event'])
  @HostListener('document:keydown.control.zoomIn', ['$event'])
  @HostListener('document:keydown.control.Add', ['$event'])
  @HostListener('document:keydown.control.+', ['$event'])
  @HostListener('document:keydown.Meta.arrowUp', ['$event'])
  @HostListener('document:keydown.Meta.zoomIn', ['$event'])
  @HostListener('document:keydown.Meta.Add', ['$event'])
  @HostListener('document:keydown.Meta.+', ['$event'])
  private zoomIn(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.zoom.emit(ZoomDirections.In);
  }

  @HostListener('document:keydown.control.arrowDown', ['$event'])
  @HostListener('document:keydown.control.zoomOut', ['$event'])
  @HostListener('document:keydown.control.Subtract', ['$event'])
  @HostListener('document:keydown.control.-', ['$event'])
  @HostListener('document:keydown.Meta.arrowDown', ['$event'])
  @HostListener('document:keydown.Meta.zoomOut', ['$event'])
  @HostListener('document:keydown.Meta.Subtract', ['$event'])
  @HostListener('document:keydown.Meta.-', ['$event'])
  private zoomOut(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.zoom.emit(ZoomDirections.Out);
  }

  @HostListener('document:keydown.control.0', ['$event'])
  @HostListener('document:keydown.Meta.0', ['$event'])
  private zoomDefault(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.zoom.emit(ZoomDirections.Default);
  }
}
