import { Component, Input } from '@angular/core';
import {
  Create,
  DateFieldConfig,
  DateHandler,
  DateInputValue,
  EmptyFormValue,
  FormValue,
  Is,
  LocalConfig,
  RelativeDate,
  SAVE_DATE_FORMAT,
  SingleFormValue,
} from '@kfd/core';
import { Observable, of, tap } from 'rxjs';
import dayjs from 'dayjs';
import { map } from 'rxjs/operators';
import { ConfigurationStateService } from '../../service';
import { SingleInputValueFieldComponent } from '../single-input-value-field.component';
import { CfgSettingsService } from '../../service/cfg-settings.service';
import { CfgException } from '../../../common/cfg-exception';

@Component({
  selector: 'kfd-configurator-field-date',
  templateUrl: './configurator-field-date.component.html',
  styleUrls: ['./configurator-field-date.component.scss'],
})
export class ConfiguratorFieldDateComponent extends SingleInputValueFieldComponent<DateFieldConfig, DateInputValue> {
  // @Output()
  // valueChange: EventEmitter<SingleFormValue<DateDataValue> | EmptyFormValue> = new EventEmitter();
  //
  dateObject: Date | undefined;
  public min$: Observable<Date | undefined> = of(undefined);
  public max$: Observable<Date | undefined> = of(undefined);

  public localConfig: LocalConfig;

  constructor(
    private configurationStateService: ConfigurationStateService,
    protected configService: CfgSettingsService,
  ) {
    super();
    this.localConfig = configService.localConfig();
  }

  @Input()
  public override set field(fieldConfig: DateFieldConfig | undefined) {
    if (!fieldConfig) {
      return;
    }
    this.fieldConfig = fieldConfig;

    if (fieldConfig?.min) {
      this.min$ = this.dateObjectFromConfig(fieldConfig.min).pipe(
        tap((date) => {
          if (this.dateObject && date > this.dateObject) {
            this.value = Create.dateFormValue(date);
          }
        }),
      );
    }
    if (fieldConfig?.max) {
      this.max$ = this.dateObjectFromConfig(fieldConfig.max).pipe(
        tap((date) => {
          if (this.dateObject && date < this.dateObject) {
            this.value = Create.dateFormValue(date);
          }
        }),
      );
    }
  }

  public onInputValueChange(value: string) {
    this.setFormValue(Create.dateFormValue(value));
    this.onValueChange();
  }

  protected override setFormValue(value: SingleFormValue<DateInputValue> | EmptyFormValue) {
    super.setFormValue(value);
    const formValue = this.getFormValue();
    if (Is.dateFormValue(formValue)) {
      this.updatedDateObject(formValue.input.value);
    } else {
      this.dateObject = undefined;
    }
  }

  protected newFormValue(value?: string): Observable<SingleFormValue<DateInputValue> | EmptyFormValue> {
    if (value) {
      this.updatedDateObject(value);
      return of(Create.dateFormValue(this.dateObject));
    }
    if (this.fieldConfig?.default && this.dateObject === undefined) {
      return this.dateObjectFromConfig(this.fieldConfig.default).pipe(
        map((calculatedDate) => {
          const date = Create.dateFormValue(calculatedDate);
          if (Is.singleFormValue(date)) {
            this.updatedDateObject(date.input.value);
          }
          return date;
        }),
      );
    }

    return of(Create.emptyFormValue());
  }

  private updatedDateObject(dateString: string | undefined) {
    if (!dateString) {
      this.dateObject = undefined;
      return;
    }
    const parsedDate = dayjs(dateString, SAVE_DATE_FORMAT);
    this.dateObject = parsedDate.isValid() ? parsedDate.toDate() : undefined;
  }

  /**
   * Creates a date object based on available configuration options
   * @param dateValueConfig
   * @private
   */
  private dateObjectFromConfig(dateValueConfig: DateInputValue | RelativeDate): Observable<Date> {
    if (Is.inputValue(dateValueConfig)) {
      return of(dayjs(dateValueConfig.value, SAVE_DATE_FORMAT).toDate());
    }
    if (Is.relativeDate(dateValueConfig)) {
      if (Is.fieldRef(dateValueConfig.start)) {
        return this.configurationStateService.onFieldValueChange(dateValueConfig.start.name, true).pipe(
          map((formValue: FormValue) => {
            if (Is.emptyFormValue(formValue)) {
              return dayjs().toDate();
            }
            if (Is.dateFormValue(formValue)) {
              return DateHandler.resolveByStartDate(dateValueConfig, formValue.input.value);
            }
            throw new CfgException(`Invalid formValue type "${formValue.type}" for date handling`);
          }),
        );
      }
      if (Is.dateRef(dateValueConfig.start)) {
        return of(DateHandler.resolveByStartDate(dateValueConfig));
      }
      throw new CfgException(`Unsupported relative date`);
    }

    throw new CfgException(`Unsupported configuration to get date value`);
  }
}
