@if (viewData$ | async; as viewData) {
  <kfd-dialog-layout
    (accept)="close()"
    (reject)="close()"
    [showRejectIcon]="true"
    [borderlessContent]="true"
    [showAcceptBtn]="false"
    [showRejectBtn]="false"
    acceptBtnLabel="OK"
    data-automationId="show-configuration-dialog"
    dialogTitle="Konfiguration anzeigen"
  >

    <p-tabs class="full-height" [value]="viewData.activeTab" (valueChange)="tabChange($any($event))">
      <p-tablist>
        <p-tab [value]="0">
          <span data-automationId="header-draft">Aktueller Entwurf</span>
        </p-tab>
        <p-tab [value]="1" [disabled]="!viewData.previewEnabled">
          <span data-automationId="header-preview">Vorschau</span>
        </p-tab>
        <p-tab [value]="2" [disabled]="!viewData.publishedEnabled">
          <span data-automationId="header-published">Veröffentlicht</span>
        </p-tab>
      </p-tablist>
      <p-tabpanels>
        <p-tabpanel [value]="0">
          @if (viewData.activeTab === 0) {
            <div class="smartphone flex-1 flex flex-column">
              <kfd-cfg-draft-view [projectId]="projectId" [configurationId]="configurationId"></kfd-cfg-draft-view>
            </div>
          }
        </p-tabpanel>
        <p-tabpanel [value]="1">
          @if (viewData.activeTab === 1) {
            <div class="smartphone flex-1 flex flex-column">
              <kfd-configuration
                [project]="projectId"
                [configuration]="configurationId"
                [preview]="true"
                class="flex-1"
                hidePreviewFlag="true"
                seamless="true"
              ></kfd-configuration>
            </div>
          }
        </p-tabpanel>
        <p-tabpanel [value]="2">
          @if (viewData.activeTab === 2) {
            <div class="smartphone flex-1 flex flex-column">
              <kfd-configuration
                [project]="projectId"
                [configuration]="configurationId"
                [preview]="false"
                class="flex-1"
                hidePreviewFlag="true"
                seamless="true"
              ></kfd-configuration>
            </div>
          }
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>


    <ng-container footerLeft>
      <p-button
        icon="pi {{ ICON.EDIT }}"
        label="Entwurf bearbeiten"
        class="mr-2"
        [routerLink]="viewData.editLink"
        [rounded]="true"
        [outlined]="true"
        (click)="editConfiguration()"
      ></p-button>

      @if (viewData.externalLink) {
        <a [href]="viewData.externalLink" target="_blank">
          <p-button
            icon="pi {{ ICON.LINK_EXTERNAL }}"
            label="In neuem Fenster öffnen"
            [rounded]="true"
            [outlined]="true"
          ></p-button>
        </a>
      }
    </ng-container>
  </kfd-dialog-layout>
}
