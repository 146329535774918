import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ICON, StringUtil } from '@kfd/core';
import { BaseDataService } from '../../../services/base-data.service';
import { MultiSelect } from 'primeng/multiselect';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'kfd-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TagInputComponent,
    },
  ],
  standalone: false,
})
export class TagInputComponent implements ControlValueAccessor {
  @ViewChild('multiTagSelection')
  public multiSelect: MultiSelect | undefined;

  @Output()
  public tagSelectionChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Input()
  public allowCreation = false;

  protected existingTagsOrigin: string[] = [];
  protected existingTags: string[] = [];
  protected newTagName = '';
  protected disabled = false;
  protected readonly ICON = ICON;
  private onChanged: CallableFunction | undefined;
  private onTouched: CallableFunction | undefined;

  constructor(private baseDataService: BaseDataService) {}

  private _projectId: string | undefined;

  public get projectId(): string | undefined {
    return this._projectId;
  }

  @Input()
  public set projectId(projectId: string | undefined) {
    if (projectId === undefined) {
      return;
    }
    this._projectId = projectId;

    this.baseDataService.listTags(projectId).subscribe((tags) => {
      this.existingTagsOrigin = [...tags];
      this.existingTags = [...tags];
    });
  }

  private _tagSelection: string[] = [];

  public get tagSelection(): string[] {
    return this._tagSelection;
  }

  @Input()
  public set tagSelection(value: string[]) {
    this._tagSelection = value;
  }

  public writeValue(value: string[]): void {
    this.tagSelection = value;
  }

  public registerOnChange(onChange: CallableFunction): void {
    this.onChanged = onChange;
  }

  public registerOnTouched(onChange: CallableFunction): void {
    this.onTouched = onChange;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  protected createTag() {
    const newTagName = StringUtil.toSaveString(this.newTagName);
    if (newTagName.length < 3) {
      return;
    }
    if (this.existingTags.indexOf(newTagName) === -1) {
      this.existingTags = [newTagName, ...this.existingTags];
    }
    if (this._tagSelection.indexOf(newTagName) === -1) {
      this._tagSelection.push(newTagName);
    }

    window.setTimeout(() => {
      if (this.multiSelect) {
        this.multiSelect.resetFilter();
        //this.multiSelect.updateLabel();
      }
      this.newTagName = '';
      const tagElement = document.querySelector(`[data-tag="${newTagName}"]`);
      if (tagElement) {
        tagElement.scrollIntoView();
      }
      this.onSelectionChange();
    }, 100);
  }

  protected onSelectionChange() {
    this.tagSelectionChange.emit(this._tagSelection);
    if (this.onTouched) {
      this.onTouched(this._tagSelection);
    }
    if (this.onChanged) {
      this.onChanged(this._tagSelection);
    }
  }
}
