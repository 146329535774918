import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  CFG_CONTEXT_SERVICE,
  CfgContextService,
  DATA_PROVIDER,
  MemoryPersistence,
  PERSISTENCE_TOKEN,
} from '@kfd/cfg-core';
import { BASE_DATA_RESOLVER, CFG_APP_ROUTES, CmsConfigurator, EndpointService, ICON } from '@kfd/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ConfigurationBasedataResolver } from '../../../../../../../libs/cfg-core/src/lib/configuration/service/configuration-basedata-resolver';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ConfigurationStateService } from '../../../../../../../libs/cfg-core/src/lib/configuration/service/configuration-state.service';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SessionPersistence } from '../../../../../../../libs/cfg-core/src/lib/configuration/service/persistence/session.persistence';
import { v4 as uuidv4 } from 'uuid';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { LoggingService } from '../../../services/logging.service';
import { ConfigurationDataService } from '@kfd/web-core';
import { ApiConfigurationService } from '../../../services/api/api-configuration.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject, combineLatestWith, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ContextRoutingService } from '../../../services/context-routing.service';

@Component({
  selector: 'kfd-show-configuration-dialog',
  templateUrl: './show-configuration-dialog.component.html',
  styleUrls: ['./show-configuration-dialog.component.scss'],
  providers: [
    {
      provide: DATA_PROVIDER,
      useClass: ConfigurationDataService,
    },
    {
      provide: CFG_CONTEXT_SERVICE,
      useClass: CfgContextService,
    },
    {
      provide: PERSISTENCE_TOKEN,
      useFactory: () => {
        if (SessionPersistence.available()) {
          const persistence = new SessionPersistence();
          persistence.useKey(uuidv4());
          return persistence;
        } else {
          return new MemoryPersistence();
        }
      },
    },
    {
      provide: BASE_DATA_RESOLVER,
      useClass: ConfigurationBasedataResolver,
    },
    ConfigurationStateService,
  ],
  standalone: false,
})
export class ShowConfigurationDialogComponent extends BaseDialogComponent {
  protected readonly ICON = ICON;
  protected projectId: string | undefined;
  protected configurationId: string | undefined;

  protected activeTab$ = new BehaviorSubject<number>(0);

  protected viewData$:
    | Observable<{
        previewEnabled: boolean;
        publishedEnabled: boolean;
        activeTab: number;
        configuration: CmsConfigurator;
        externalLink?: string;
        editLink?: string;
      }>
    | undefined;

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
    protected loggingService: LoggingService,
    // private configurationStateService: ConfigurationStateService,
    private readonly apiConfigurationService: ApiConfigurationService,
    private readonly contextRoutingService: ContextRoutingService,
  ) {
    super(dialogRef, dialogConfig);
    if (dialogConfig.data.projectId) {
      this.projectId = dialogConfig.data.projectId;
    }
    if (dialogConfig.data.configurationId) {
      this.configurationId = dialogConfig.data.configurationId;
    }

    if (!this.projectId || !this.configurationId) {
      throw new Error('No projectId and configurationId provided');
    }

    if (dialogConfig.data.activeTab) {
      this.activeTab$.next(dialogConfig.data.activeTab);
    }

    this.viewData$ = this.apiConfigurationService.getConfiguration(this.projectId, this.configurationId, false).pipe(
      combineLatestWith(this.activeTab$),
      map(([configuration, activeTab]) => {
        const previewEnabled = configuration.versions.drafted > 0;
        const publishedEnabled = configuration.versions.published > 0;

        if (activeTab === 1 && !previewEnabled) {
          activeTab = 0;
        }

        if (activeTab === 2 && !publishedEnabled) {
          activeTab = 0;
        }
        return {
          configuration,
          externalLink: this.getExternalLink(activeTab),
          previewEnabled,
          publishedEnabled,
          activeTab,
        };
      }),
    );
  }

  editConfiguration(): void {
    this.contextRoutingService.toConfigurationEditMode(this.configurationId);
    this.close();
  }

  // reset() {
  //   this.configurationStateService.removeAllValues();
  //   this.showCfg = false;
  //   window.setTimeout(() => {
  //     this.showCfg = true;
  //   });
  // }

  getExternalLink(activeTab: number): string | undefined {
    if (activeTab === 0 || !this.projectId || !this.configurationId) {
      return undefined;
    }
    const route =
      activeTab === 1 ? CFG_APP_ROUTES.project.configuration.previewForm : CFG_APP_ROUTES.project.configuration.form;
    return environment.apps.cfg + EndpointService.relativePath(route, [this.projectId, this.configurationId]);
  }

  protected tabChange(tabIndex: number): void {
    this.activeTab$.next(tabIndex);
  }
}
