import { Component, Input } from '@angular/core';
import { Asset, ICON, Id, Is } from '@kfd/core';
import { Observable, of } from 'rxjs';
import { AssetService } from '../../../services/asset.service';

@Component({
  selector: 'kfd-asset-viewer',
  templateUrl: './asset-viewer.component.html',
  styleUrl: './asset-viewer.component.scss',
  standalone: false,
})
export class AssetViewerComponent {
  @Input({ required: true })
  public projectId: Id;
  protected asset$: Observable<Asset | undefined> | undefined;
  protected readonly ICON = ICON;

  constructor(private readonly assetService: AssetService) {}

  @Input()
  public set assetId(value: Id | undefined) {
    if (!Is.id(value)) {
      this.asset$ = of(undefined);
      return;
    }

    this.asset$ = this.assetService.getAsset(this.projectId, value, true);
  }
}
