const specialCharReplacement: Record<string, string> = {
  ä: 'ae',
  ö: 'oe',
  ü: 'ue',
  ß: 'ss',
  ' ': '_',
};

export class StringUtil {
  /**
   * Converts a string to an uppercase code with a maximum
   * adds a number postfix if the last character is a number
   * @param input
   * @param maxLength
   */
  public static toCode(input: string, maxLength = 5, entropy = 1): string {
    if (typeof input !== 'string') {
      throw new Error('Invalid string');
    }

    input = input.toLowerCase().replace(/[^a-z0-9]/g, '');
    const code = StringUtil.shorten(input, maxLength, entropy);
    const numberPostfix = parseInt(input.charAt(input.length - 1));
    if (!isNaN(numberPostfix)) {
      return code.substring(0, maxLength - 1) + numberPostfix;
    }

    return code;
  }

  /**
   * Converts a string to a save identifier with a maximum length
   * adds a number postfix if the last character is a number
   * @param input
   * @param maxLength
   */
  public static toIdentifier(input: string, maxLength = 30): string {
    if (typeof input !== 'string') {
      throw new Error('Invalid string');
    }
    if (input.length <= maxLength) {
      return StringUtil.toSaveString(input, maxLength);
    }
    const saveString = StringUtil.toSaveString(input);
    const numberPostfix = parseInt(saveString.charAt(saveString.length - 1));
    if (!isNaN(numberPostfix)) {
      return StringUtil.toSaveString(saveString.substring(0, maxLength - 2) + '_' + numberPostfix);
    }

    return saveString.substring(0, maxLength);
  }

  /**
   * Converts a string to a save string with a maximum length
   * Uses allow list to only allow a-z, 0-9, _ and -
   * @param input
   * @param maxLength
   */
  public static toSaveString(input: string, maxLength = 300): string {
    if (typeof input !== 'string') {
      throw new Error('Invalid string');
    }
    input = input.trim().toLocaleLowerCase();
    input = StringUtil.replaceSpecial(input);
    input = input.replace(/[^a-z0-9_-]/g, '');
    return input.replace(/__/g, '_').substring(0, maxLength);
  }

  /**
   * Converts a string to a readable string with a capital first letter
   * reverts special characters to their original form (äöüß)
   * @param input
   */
  public static toReadableString(input: string): string {
    if (typeof input !== 'string') {
      throw new Error('Invalid string');
    }
    input = StringUtil.revertSpecial(input.trim());
    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  /**
   * Converts a string to a domain name
   * removes special characters and spaces
   * @param input
   */
  public static toDomainName(input: string): string {
    if (typeof input !== 'string') {
      throw new Error('Invalid string');
    }

    input = input.trim().toLocaleLowerCase();
    input = StringUtil.replaceSpecial(input);
    input = input.replace(/^https?:\/\/(.*)$/, '$1');
    input = input.replace(/[^a-z0-9.-]/g, '');
    return input;
  }

  /**
   * Converts a string to a valid url
   * adds a protocol if missing
   * @param input
   * @param protocol
   **/
  public static toValidUrl(input: string, protocol = 'https://'): string {
    if (typeof input !== 'string') {
      throw new Error('Invalid string');
    }

    input = input.trim().toLocaleLowerCase();
    input = protocol + input.replace(/^(https?:\/\/)?(.*?)$/, '$2');
    return input;
  }

  /**
   * Converts a string to a valid email
   * @param input
   */
  public static toEmail(input: string): string {
    if (typeof input !== 'string') {
      throw new Error('Invalid string');
    }

    input = input.trim().toLocaleLowerCase();
    input = StringUtil.replaceSpecial(input);
    input = input.replace(/[^a-z0-9!#$%&'*+-/=?^_`{|}~.@]/g, '');
    return input;
  }

  /**
   * Converts a string to a single line string by removing line breaks
   * @param input
   */
  public static singleLineString(input: string): string {
    if (typeof input !== 'string') {
      throw new Error('Invalid string');
    }
    return input.replace(/[\r\n]/g, ' ');
  }

  /**
   * Converts a string to a secure string by removing special characters
   * this is less secure than toSaveString because it uses deny list instead of allow list
   * @param input
   */
  public static secureString(input: string): string {
    if (typeof input !== 'string') {
      throw new Error('Invalid string');
    }
    return input.replace(/[{}:<>$]/g, '');
  }

  /**
   * Replaces special characters with their save representation
   * @see revertSpecial to revert it
   * @param input
   */
  public static replaceSpecial(input: string): string {
    if (typeof input !== 'string') {
      throw new Error('Invalid string');
    }
    input = input.toLowerCase();
    for (const key in specialCharReplacement) {
      input = input.replace(new RegExp(key, 'g'), specialCharReplacement[key]);
    }
    return input;
  }

  /**
   * Reverts special characters to their original form
   * @see replaceSpecial to replace it
   * @param input
   */
  public static revertSpecial(input: string): string {
    if (typeof input !== 'string') {
      throw new Error('Invalid string');
    }
    for (const key in specialCharReplacement) {
      input = input.replace(new RegExp(specialCharReplacement[key], 'g'), key);
    }
    return input;
  }

  /**
   * Generates a random string with a given length
   * @param length
   * @param uppercase
   */
  public static rand(length: number, uppercase = true): string {
    const value1 = Math.random().toString(36).substring(2);
    const value2 = Math.random().toString(36).substring(2);
    const result = (value1 + value2).substring(0, length);
    return uppercase ? result.toUpperCase() : result.toLowerCase();
  }

  /**
   * Shortens a string to a given length
   * adds entropy to the position to get a more random result
   * @param input
   * @param length
   * @param entropy
   */
  public static shorten(input: string, length: number, entropy = 1): string {
    let res = '';
    input = StringUtil.toSaveString(input);

    if (input.length < length) {
      length = input.length;
    }

    let pos = 0;
    for (let i = 0; i < length; i++) {
      pos = i === 0 ? 0 : pos + entropy;
      if (pos > input.length - 1) {
        pos = pos - input.length;
      }
      res += input.charAt(pos);
    }

    return res.toUpperCase();
  }
}
