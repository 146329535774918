<p-multiSelect
  #multiTagSelection
  (onChange)="onSelectionChange()"
  [(ngModel)]="tagSelection"
  [options]="existingTags"
  [showHeader]="false"
  appendTo="body"
  class="flex-1"
>
  <ng-template let-value pTemplate="selectedItems">
    <ng-container *ngFor="let selectedTag of tagSelection">
      <p-chip [label]="selectedTag" class="mr-1"></p-chip>
    </ng-container>
  </ng-template>
  <ng-template let-tag pTemplate="item">
    <div [attr.data-tag]="tag" class="tag-item">
      <div>{{ tag }}</div>
    </div>
  </ng-template>
  <ng-template *ngIf="allowCreation === true" pTemplate="footer">
    <div class="m-2 pb-2">
      <p-inputgroup>
        <p-inputgroup-addon>
          <i class="pi {{ ICON.TAG }}"></i>
        </p-inputgroup-addon>
        <input
          (keydown.enter)="createTag()"
          [(ngModel)]="newTagName"
          pInputText
          placeholder="Neuer Tag Name"
          type="text"
        />
        <p-inputgroup-addon>
          <p-button
            (click)="createTag()"
            [disabled]="newTagName.length < 3"
            [icon]="ICON.ACCEPT"
            severity="secondary"
          ></p-button>
        </p-inputgroup-addon>
      </p-inputgroup>
    </div>
  </ng-template>
</p-multiSelect>
