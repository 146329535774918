import { Pipe, PipeTransform } from '@angular/core';
import { CfgSettingsService } from '../service/cfg-settings.service';

@Pipe({
  name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
  constructor(public configService: CfgSettingsService) {}

  transform(value: number): string {
    const localeConfig = this.configService.localConfig();
    return new Intl.NumberFormat(localeConfig.code).format(Number(value));
  }
}
