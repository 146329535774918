import { Injectable } from '@angular/core';

import { mergeMap, Observable, of, tap } from 'rxjs';
import { AuthService } from '@kfd/web-core';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root',
})
export class UserGuard {
  constructor(
    private authService: AuthService,
    private loginService: LoginService,
  ) {}

  canActivate(): Observable<boolean> {
    return this.getUser();
  }

  getUser(): Observable<boolean> {
    return this.authService.currentUser(false).pipe(
      mergeMap((user) => {
        if (user === undefined) {
          return this.loginService.showLogin().pipe(tap(() => this.getUser()));
        }
        return of(true);
      }),
    );
  }
}
