<div [kfdBlock]="loading" class="flex flex-row justify-content-between" data-automationId="asset-selector">
  <div class="flex justify-content-center align-items-center">
    <div (click)="openSelection()" class="preview flex justify-content-center align-items-center">
      @if (asset) {
        <img [src]="asset.thumbnailUrl" />
      } @else if (error) {
        <span class="pi {{ ICON.WARN }} my-2 mx-4 color-error text-2xl" pTooltip="Asset nicht gefunden"></span>
      } @else {
        <span class="pi {{ ICON.IMAGE }} my-2 mx-4"></span>
      }
    </div>
    @if (asset) {
      <div class="ml-2 title" data-automationId="file-info" [pTooltip]="asset.label + ' - ' + asset.name">
        {{ asset.label ? asset.label : asset.name }}
      </div>
    } @else {
      <div class="ml-2 title" data-automationId="no-file-info"><i>Kein Datei ausgewählt.</i></div>
    }
  </div>
  <div class="ml-2 flex justify-content-center align-items-center overflow-hidden">
    @if (error || (asset && !loading)) {
      <p-button
        (click)="clearSelection()"
        [outlined]="true"
        [rounded]="true"
        class="ml-2 block"
        data-automationId="remove-asset-btn"
        icon="pi {{ ICON.DELETE }}"
        label="Datei entfernen"
      ></p-button>
    }
    <p-button
      (click)="openSelection()"
      [disabled]="loading"
      [outlined]="true"
      [rounded]="true"
      class="ml-2 block"
      data-automationId="open-asset-selection-btn"
      icon="pi {{ ICON.ASSET }}"
      label="Datei auswählen"
    ></p-button>
  </div>
</div>
