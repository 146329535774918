<div class="flex-1 flex flex-column grid-wrapper">
  @if (projects$ | async; as projects) {
    @if (filterAndSort(projects).length > 0) {
      <div class="grid flex-1">
        @for (project of filterAndSort(projects); track project._id) {
          <div class="col-12 sm:col-6 md:col-4 lg:col-3 ">
            <p-card
              (click)="select(project)"
              [attr.data-automationId]="'project-name-' + project.name"
              class="teaser m-1"
              styleClass="interactive"
            >
              <div class="logo mb-3">
                @if (project.ci?.logo) {
                  <kfd-asset-viewer [projectId]="project._id" [assetId]="project.ci.logo"></kfd-asset-viewer>
                } @else {
                  <i class="placeholder pi {{ICON.PROJECT}}"></i>
                }
              </div>
              <p-header>
                <div class="flex justify-content-between align-items-center">
                  <div class="card-title">
                    @if (project.joined) {
                      <i class="{{ICON.PROJECT_JOINED}} joined-icon mr-1"
                         pTooltip="Sie sind Mitarbeiter in diesem Projekt"></i>
                    }
                    {{ project.label }}
                  </div>
                  @if (project.favorite) {
                    <p-button
                      [icon]="ICON.FAVORITE_SELECTED"
                      pTooltip="Favorit entfernen"
                      tooltipPosition="top"
                      data-automationId="btn-favorites-remove"
                      [text]="true"
                      (click)="$event.stopPropagation(); removeFavorite(project)"
                    ></p-button>
                  } @else {
                    <p-button
                      [icon]="ICON.FAVORITE"
                      severity="secondary"
                      pTooltip="Aks Favorit markieren"
                      tooltipPosition="top"
                      data-automationId="btn-favorites-add"
                      [text]="true"
                      (click)="$event.stopPropagation(); addFavorite(project)"
                    ></p-button>
                  }
                </div>
              </p-header>
            </p-card>
          </div>
        }


      </div>
      <ng-content select="[selectionFooter]"></ng-content>
    } @else {
      <ng-content select="[emptyList]"></ng-content>
    }
  }
</div>
