import {
  BaseDataFilter,
  BaseDataRef,
  CfgCalculation,
  ChildWrapper,
  CLS,
  CmsConfigurator,
  CmsGenericEntry,
  ConditionalEntry,
  ConditionGroup,
  Configuration,
  DataHandler,
  DATAHANDLER_TYPES,
  DynamicDataHandler,
  EntryUsage,
  Field,
  FIELD_TYPES,
  FieldConfig,
  FieldGroup,
  FieldRef,
  GenericEntry,
  Id,
  Is,
  MovePosition,
  Page,
  ParentEntry,
  WRAPPER_CAN_MOVE_TO,
} from '../dtos/index';
import { Cache, JsonNode, JsonPatch, Jsonpath, ObjectUtil, unvTimeout } from '../common';
import { v4 as uuidv4 } from 'uuid';
import { Operation } from 'fast-json-patch';
import { isEqual, uniqWith } from 'lodash';

export type FieldSkeleton = {
  cls: CLS.FIELD;
  name: string;
};
export type GroupSkeleton = {
  cls: CLS.FIELD_GROUP;
  name: string;
  children: FieldSkeleton[];
};
export type PageSkeleton = {
  cls: CLS.PAGE;
  name: string;
  children: (GroupSkeleton | FieldSkeleton)[];
};
export type CfgSkeleton = {
  cls: CLS.CONFIGURATION;
  name: string;
  children: PageSkeleton[];
};

export class CfgUtil<U extends Configuration = Configuration> {
  private cache: Cache;

  protected constructor(configuration: U) {
    this._configuration = configuration;
    this.cache = new Cache();
  }

  private _configuration: U;

  get configuration(): U {
    return this._configuration;
  }

  set configuration(value: U) {
    this._configuration = value;
    this.cache.clear();
  }

  public static create<U extends Configuration>(configuration: U): CfgUtil<U> {
    const inst = new this(ObjectUtil.clone(configuration));
    //we do not call initialize here because it is optional and does not always make sense (only if used heavily several times)
    // inst.initialize()
    return inst;
  }

  /**
   * validates if path is part of any configuration element
   */
  private static isPathInConfiguration(path: string[]): boolean {
    return path.length > 1 && path[1] === 'children';
  }

  /**
   * validates if path is part of a global calculation
   */
  private static isPathInCalculations(path: string[]): boolean {
    return path.length > 1 && path[1] === 'calculations';
  }

  /**
   * validates if path is part of a global calculation
   */
  private static isPathInSettings(path: string[]): boolean {
    return path.length > 1 && path[1] === 'settings';
  }

  /**
   * returns the path to the deepest entry in the path
   */
  private static getLastEntryPath(path: string[]): string | undefined {
    // in case of conditions the entry is parallel to the condition item
    const conditionIndex = path.lastIndexOf('condition');
    if (conditionIndex >= 0) {
      const entryPath = path.slice(0, conditionIndex);
      return entryPath.join('.');
    }

    const lastEntry = path.lastIndexOf('entry');
    if (!lastEntry) {
      return;
    }
    const entryPath = path.slice(0, lastEntry);
    return entryPath.join('.');
  }

  private static clsExp(...cls: CLS[]): string {
    const clsMatch = cls.map((clsName) => `@.cls=="${clsName}"`).join(' || ');
    return `$..[?(${clsMatch})]`;
  }

  private static clsTypesExp(cls: CLS, types: FIELD_TYPES[], excludeNameList: string[] = []): string {
    const excludedNames = excludeNameList.map((excludeName) => `@.name!="${excludeName}"`).join(' || ') ?? '';
    const typeMatch = types.map((type) => `@.config.type=="${type}"`).join(' || ') ?? '';
    if (excludedNames) {
      return `$..[?((@.cls=="${cls.toString()}") && (${typeMatch}) && (${excludedNames}))]`;
    }
    return `$..[?((@.cls=="${cls.toString()}") && (${typeMatch}))]`;
  }

  private static clsNameExp(name: string, ...cls: CLS[]): string {
    const clsMatch = cls.map((clsName) => `@.cls=="${clsName}"`).join(' || ');
    return `$..[?((${clsMatch}) && @.name=="${name}")]`;
  }

  private static clsPropertyExp(propertyName: string, propertyValue: string, clsList: CLS[]): string {
    const clsMatch = clsList.map((clsName) => `@.cls=="${clsName}"`).join(' || ');
    return `$..[?((${clsMatch}) && @.${propertyName}=="${propertyValue}")]`;
  }

  private static wrapperClsNameExp(name: string, ...cls: CLS[]): string {
    const clsMatch = cls.map((clsName) => `@.entry.cls=="${clsName}"`).join(' || ');
    return `$..[?(@.entry && @.entry.name=="${name}" && (${clsMatch}))]`;
  }

  private static entryConditions(
    conditionalEntries: ConditionalEntry[],
    parentConditions: ConditionGroup[] = [],
  ): Map<string, ConditionGroup[]> {
    let conditionMap: Map<string, ConditionGroup[]> = new Map();

    for (const conditionalEntry of conditionalEntries) {
      const entryConditions = conditionalEntry.condition
        ? parentConditions.concat(conditionalEntry.condition)
        : parentConditions;
      if (entryConditions.length > 0) {
        conditionMap.set(conditionalEntry.entry.name, entryConditions);
      }

      if ('children' in conditionalEntry.entry && conditionalEntry.entry.children) {
        const subMap = this.entryConditions(conditionalEntry.entry.children, entryConditions);
        conditionMap = new Map([...Array.from(conditionMap.entries()), ...Array.from(subMap.entries())]);
      }
    }

    return conditionMap;
  }

  public updateCfg(configuration: U): CfgUtil<U> {
    if (JSON.stringify(this.configuration) === JSON.stringify(configuration)) {
      return this;
    }
    this.configuration = ObjectUtil.clone(configuration);
    this.initialize();
    return this;
  }

  public initialize(): void {
    unvTimeout(() => {
      this.cache.clear();
      this.getEntriesWithConditions();
    });
  }

  public getCfg(): U {
    return ObjectUtil.clone(this.configuration);
  }

  public getSkeleton(depth = 1): CfgSkeleton {
    return {
      cls: CLS.CONFIGURATION,
      name: this.configuration.name,
      children: depth === 0 ? [] : this.getPages().map((page) => this.getPageSkeleton(page, depth - 1)),
    };
  }

  public getPageSkeleton(page: Page, depth = 1): PageSkeleton {
    return {
      cls: CLS.PAGE,
      name: page.name,
      children:
        depth === 0
          ? []
          : page.children.map((groupOrField) => {
              if (Is.field(groupOrField.entry)) {
                return {
                  cls: CLS.FIELD,
                  name: groupOrField.entry.name,
                } as FieldSkeleton;
              }
              return this.getGroupSkeleton(groupOrField.entry, depth - 1);
            }),
    };
  }

  public getGroupSkeleton(fieldGroup: FieldGroup, depth = 1): GroupSkeleton {
    return {
      cls: CLS.FIELD_GROUP,
      name: fieldGroup.name,
      children:
        depth === 0
          ? []
          : fieldGroup.children.map((field) => {
              return {
                cls: CLS.FIELD,
                name: field.entry.name,
              };
            }),
    };
  }

  public getEntryById<T = GenericEntry>(id: Id, required?: true): T;

  public getEntryById<T = GenericEntry>(id: Id, required?: false): T | undefined;

  public getEntryById<T = GenericEntry>(id: Id, required = false): T | undefined {
    return this.getNodeById<T>(id.toString(), required === true ? required : undefined)?.value;
  }

  public getEntryByName<T = GenericEntry>(name: string, required?: true): T;

  public getEntryByName<T = GenericEntry>(name: string, required?: false): T | undefined;

  public getEntryByName<T = GenericEntry>(name: string, required = false): T | undefined {
    return this.getNodeByName<T>(name, required === true ? required : undefined)?.value;
  }

  public getEntryWrapperByName<T = ChildWrapper>(name: string, required?: true): T;

  public getEntryWrapperByName<T = ChildWrapper>(name: string, required?: false): T | undefined;

  public getEntryWrapperByName<T = ChildWrapper>(name: string, required = false): T | undefined {
    return this.getWrapperNodeByName<T>(name, required === true ? required : undefined)?.value;
  }

  public hasFieldWithName<T extends Field>(name: string): boolean {
    const node = this.getNodeByProperty<T>('name', name, [CLS.FIELD], false);
    return node !== undefined;
  }

  public getFieldByName<T extends Field>(name: string, required: true): T;
  public getFieldByName<T extends Field>(name: string): T | undefined;
  public getFieldByName<T extends Field>(name: string, required = false): T | undefined {
    return this.getNodeByProperty<T>('name', name, [CLS.FIELD], required === true ? required : undefined)?.value;
  }

  public getNodeByName<T = ChildWrapper>(name: string, required?: true): JsonNode<T>;

  public getNodeByName<T = ChildWrapper>(name: string, required?: false): JsonNode<T> | undefined;

  public getNodeByName<T = ChildWrapper>(name: string, required = false): JsonNode<T> | undefined {
    return this.getNodeByProperty<T>(
      'name',
      name,
      [CLS.CONFIGURATION, CLS.PAGE, CLS.FIELD_GROUP, CLS.FIELD],
      required === true ? required : undefined,
    );
  }

  // public addEntry(target,): CfgUtil {
  //   const node = this.getNodeByName(name, true);
  //   this.configuration = JsonPatch.apply(this.configuration, JsonPatch.removeOperation(node.path));
  //   return this;
  // }

  public getNodeById<T = ChildWrapper>(id: string, required?: true): JsonNode<T>;

  public getNodeById<T = ChildWrapper>(id: string, required?: false): JsonNode<T> | undefined;

  public getNodeById<T = ChildWrapper>(id: string, required = false): JsonNode<T> | undefined {
    return this.getNodeByProperty<T>(
      '_id',
      id,
      [CLS.CONFIGURATION, CLS.PAGE, CLS.FIELD_GROUP, CLS.FIELD],
      required === true ? required : undefined,
    );
  }

  /**
   * returns the position in the configuration
   *  format 1XY (X = page, Y = group)
   */
  public getAbsolutePosition(name: string): number {
    const node = this.getNodeByName(name, true);
    const numbers = node.path.filter((element, index) => node.path[index - 1] === 'children');
    const pageNumber = numbers.length > 0 ? numbers[0] : 0;
    const groupNumber = numbers.length > 1 ? numbers[1] : 0;
    return parseInt('1' + pageNumber + groupNumber);
  }

  /**
   * returns the position in the parent element
   */
  public getRelativePosition(name: string): MovePosition {
    const node = this.getNodeByName(name, true);
    const positionPath = node.path.slice(0, node.path.lastIndexOf('entry'));
    const parent = this.getParentEntry(name, true);

    const pos = parseInt(positionPath[positionPath.length - 1]);
    return {
      name: parent.name,
      pos,
    };
  }

  public getWrapperNodeByName<T = ChildWrapper>(name: string, required?: true): JsonNode<T>;

  public getWrapperNodeByName<T = ChildWrapper>(name: string, required = false): JsonNode<T> | undefined {
    const node = Jsonpath.node<T>(
      this.configuration,
      CfgUtil.wrapperClsNameExp(name, CLS.FIELD, CLS.PAGE, CLS.FIELD_GROUP),
    );
    if (required === true && node?.value === undefined) {
      throw new Error(`Could not find wrapper from entry with name "${name}"`);
    }
    return node;
  }

  public removeWrapperEntry(name: string): CfgUtil<U> {
    const node = this.getWrapperNodeByName(name, true);
    this.configuration = JsonPatch.apply(this.configuration, JsonPatch.removeOperation(node.path));
    return this;
  }

  public createEntry(entry: ChildWrapper, target: MovePosition): CfgUtil<U> {
    const targetNode = this.getNodeByName(target.name, true);
    const newPosition: string[] = [...targetNode.path, 'children', target.pos.toString()];
    this.configuration = JsonPatch.apply(this.configuration, JsonPatch.addOp(newPosition, entry));
    return this;
  }

  public patchEntry(entryName: string, entry: CmsGenericEntry): CfgUtil<U> {
    const diffs = this.getEntryDiff(entryName, entry, true);
    this.configuration = JsonPatch.applyMultiple(this.configuration, diffs);
    return this;
  }

  public updateEntry(entryName: string, entry: CmsGenericEntry): CfgUtil<U> {
    const entryNode = this.getNodeByName(entryName, true);

    this.configuration = JsonPatch.apply(this.configuration, JsonPatch.replaceOperation(entryNode.path, entry));
    return this;
  }

  public updateWrapperEntry(entryName: string, entry: ChildWrapper): CfgUtil<U> {
    const wrapperNode = this.getWrapperNodeByName(entryName, true);

    this.configuration = JsonPatch.apply(this.configuration, JsonPatch.replaceOperation(wrapperNode.path, entry));
    return this;
  }

  public patchWrapperEntry(entryName: string, entry: ChildWrapper): CfgUtil<U> {
    const diffs = this.getEntryWrapperDiff(entryName, entry);
    this.configuration = JsonPatch.applyMultiple(this.configuration, diffs);
    return this;
  }

  public moveEntry(sourceName: string, moveTarget: MovePosition): CfgUtil<U> {
    const sourceNode = this.getWrapperNodeByName(sourceName, true);
    const targetNode = this.getNodeByName(moveTarget.name, true);

    if (WRAPPER_CAN_MOVE_TO[sourceNode.value.cls].indexOf(targetNode.value.cls) === -1) {
      throw new Error(
        `Could not move entry "${sourceName}" with cls "${sourceNode.value.cls}" to parent with cls "${targetNode.value.cls}"`,
      );
    }

    const removeIdentifier = sourceName + '-' + uuidv4();
    this.configuration = JsonPatch.apply(
      this.configuration,
      JsonPatch.replaceOperation([...sourceNode.path, 'entry', 'name'], removeIdentifier),
    );
    const newPosition: string[] = [...targetNode.path, 'children', moveTarget.pos.toString()];
    this.configuration = JsonPatch.apply(this.configuration, JsonPatch.addOp(newPosition, sourceNode.value));
    this.removeWrapperEntry(removeIdentifier);
    return this;
  }

  public getParentEntry<T = GenericEntry>(entryName: string, required?: true): T;

  public getParentEntry<T = GenericEntry>(entryName: string, required?: false): T | undefined;

  public getParentEntry<T = GenericEntry>(entryName: string, required?: boolean): T | undefined;

  public getParentEntry<T = GenericEntry>(entryName: string, required = true): T | undefined {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const node = this.getNodeByName(entryName, required);
    if (node === undefined || !node.path.includes('entry')) {
      return;
    }
    const positionPath = node.path.slice(0, node.path.lastIndexOf('entry'));
    const parentEntryPath = positionPath.slice(0, positionPath.lastIndexOf('entry') + 1);
    const parentNode = Jsonpath.node<T>(this.configuration, parentEntryPath);
    if (!parentNode) {
      throw new Error(`Could not find entry position for node "${entryName}" - no parent node`);
    }
    return parentNode.value;
  }

  public getParentPage(entryName: string): Page | undefined {
    const entry = this.getEntryByName(entryName);
    if (!entry) {
      return;
    }
    if (Is.page(entry)) {
      return entry;
    }
    const parent = this.getParentEntry(entry.name, false);
    if (!parent) {
      return;
    }
    return this.getParentPage(parent.name);
  }

  public getEntryDiff(entryName: string, diffEntry: GenericEntry, absolute = false): Operation[] {
    const originEntry = this.getNodeByName(entryName);

    // update children to be ignored in diff
    const diffEntryClean = ObjectUtil.clone(diffEntry);
    if (Is.parentEntry(originEntry.value) && Is.parentEntry(diffEntryClean)) {
      diffEntryClean.children = originEntry.value.children;
    }

    const operations = JsonPatch.compare(
      originEntry.value as unknown as Record<string, unknown>,
      diffEntryClean as unknown as Record<string, unknown>,
    );

    if (absolute === true) {
      return operations.map((operation) => {
        operation.path = JsonPatch.pathToString([...originEntry.path, ...JsonPatch.stringToPath(operation.path)]);
        return operation;
      });
    }
    return operations;
  }

  public getEntryWrapperDiff(entryWrapperName: string, diffEntry: ChildWrapper): Operation[] {
    const originEntry = this.getWrapperNodeByName(entryWrapperName);
    // update entry to be ignored because we only care about wrapper changes
    const diffEntryClean = ObjectUtil.clone(diffEntry);
    diffEntryClean.entry = originEntry.value.entry;
    const operations = JsonPatch.compare(
      originEntry.value as unknown as Record<string, unknown>,
      diffEntryClean as unknown as Record<string, unknown>,
    );
    return operations.map((operation) => {
      operation.path = JsonPatch.pathToString([...originEntry.path, ...JsonPatch.stringToPath(operation.path)]);
      return operation;
    });
  }

  /**
   * return FieldRefs for all entries with references to other fields
   */
  public getFieldUsages(): EntryUsage<FieldRef>[] {
    const nodes = Jsonpath.nodes<FieldRef>(this.configuration, CfgUtil.clsExp(CLS.FIELD_REF));
    return this.convertNodesToEntryUsage(nodes);
  }

  public getEntryUsages(entryName: string): EntryUsage<FieldRef>[] {
    const nodes = Jsonpath.nodes<FieldRef>(this.configuration, CfgUtil.clsNameExp(entryName, CLS.FIELD_REF));
    return this.convertNodesToEntryUsage(nodes);
  }

  public getBaseDataReferencesByName(name: string): EntryUsage<BaseDataRef>[] {
    const nodes = Jsonpath.nodes<BaseDataRef>(this.configuration, CfgUtil.clsNameExp(name, CLS.BASE_DATA_REF));
    return this.convertNodesToEntryUsage<BaseDataRef>(nodes);
  }

  public getAllBaseDataReferences(): EntryUsage<BaseDataRef>[] {
    const nodes = Jsonpath.nodes<BaseDataRef>(this.configuration, CfgUtil.clsExp(CLS.BASE_DATA_REF));
    return this.convertNodesToEntryUsage<BaseDataRef>(nodes);
  }

  public getDynamicDataFilter(): BaseDataFilter[] {
    const filter = [];
    const expression = `$..*[?(@.cls=="${CLS.DATA_HANDLER}" && @.type=="${DATAHANDLER_TYPES.DYNAMIC}")]`;
    const nodes = Jsonpath.nodes<DynamicDataHandler>(this.configuration, expression);

    for (const res of nodes) {
      filter.push({
        templateName: res.value.templateName,
        tags: res.value.tags?.length ? res.value.tags : undefined,
      });
    }
    // filter out duplicates
    return uniqWith(filter, isEqual);
  }

  public filterUsage(): EntryUsage<DynamicDataHandler>[] {
    const expression = `$..*[?(@.cls=="${CLS.DATA_HANDLER}" && @.type=="${DATAHANDLER_TYPES.DYNAMIC}")]`;
    const nodes = Jsonpath.nodes<DynamicDataHandler>(this.configuration, expression);
    return this.convertNodesToEntryUsage<DynamicDataHandler>(nodes);
  }

  /**
   * @deprecated use filterUsage instead
   * @param tagName
   */
  public tagUsages(tagName: string): EntryUsage<DataHandler>[] {
    const expression = `$..*[?(@.cls=="${CLS.DATA_HANDLER}" && @.type=="dynamic" && @.tags.indexOf("${tagName}") != -1)]`;
    const nodes = Jsonpath.nodes<DataHandler>(this.configuration, expression);
    return this.convertNodesToEntryUsage<DataHandler>(nodes);
  }

  public getPages(): Page[] {
    const nodes = Jsonpath.nodes<Page>(this.configuration, CfgUtil.clsExp(CLS.PAGE));
    return nodes.map((node) => node.value);
  }

  public getFirstPage(): Page | undefined {
    return this.getPages()[0] ?? undefined;
  }

  public getGroups(): FieldGroup[] {
    const nodes = Jsonpath.nodes<FieldGroup>(this.configuration, CfgUtil.clsExp(CLS.FIELD_GROUP));
    return nodes.map((node) => node.value);
  }

  public getFields(): Field[] {
    const nodes = Jsonpath.nodes<Field>(this.configuration, CfgUtil.clsExp(CLS.FIELD));
    return nodes.map((node) => node.value);
  }

  public getFieldNames(): string[] {
    return this.getFields().map((field) => field.name);
  }

  public getFieldsOfType<T = FieldConfig>(type: FIELD_TYPES | FIELD_TYPES[], excludeList: string[] = []): Field<T>[] {
    const types = Array.isArray(type) ? type : [type];
    const nodes = Jsonpath.nodes<Field<T>>(this.configuration, CfgUtil.clsTypesExp(CLS.FIELD, types, excludeList));
    return nodes.map((node) => node.value);
  }

  public getPageFields(pageName: string): Field[] {
    const page = this.getEntryByName<Page>(pageName);
    if (!page) {
      return [];
    }
    const nodes = Jsonpath.nodes<Field>(page, CfgUtil.clsExp(CLS.FIELD));
    return nodes.map((node) => node.value);
  }

  public getNodeByProperty<T = ChildWrapper>(
    propertyName: string,
    propertyValue: string,
    clsList: CLS[],
    required?: true,
  ): JsonNode<T>;

  public getNodeByProperty<T = ChildWrapper>(
    propertyName: string,
    propertyValue: string,
    clsList: CLS[],
    required?: false,
  ): JsonNode<T> | undefined;

  public getNodeByProperty<T = ChildWrapper>(
    propertyName: string,
    propertyValue: string,
    clsList: CLS[],
    required = false,
  ): JsonNode<T> | undefined {
    const key = propertyName + propertyValue + clsList.join() + required;
    if (this.cache.has(key)) {
      return this.cache.get<JsonNode<T> | undefined>(key);
    }
    const node = Jsonpath.node<T>(
      // wrapping configuration to include root element
      [this.configuration],
      CfgUtil.clsPropertyExp(propertyName, propertyValue, clsList),
    );
    if (required === true && node?.value === undefined) {
      throw new Error(`Could not find entry by property "${propertyName}" with value "${propertyValue}"`);
    }
    let result = undefined;
    if (node !== undefined) {
      result = {
        //remove wrapper (see above) from path
        path: ['$', ...node.path.slice(2)],
        value: node?.value,
      };
      this.cache.set(key, result);
    }
    return result;
  }

  public isFirst(entryName: string, required = true): boolean {
    const parentEntry = this.getParentEntry<ParentEntry>(entryName, required);
    if (!parentEntry) {
      return false;
    }
    const entryIndex = parentEntry.children.findIndex((entry) => entry.entry.name === entryName);
    return entryIndex === 0;
  }

  public isLast(entryName: string, required = true): boolean {
    const parentEntry = this.getParentEntry<ParentEntry>(entryName, required);
    if (!parentEntry) {
      return false;
    }
    const entryIndex = parentEntry.children.findIndex((entry) => entry.entry.name === entryName);
    return entryIndex === parentEntry.children.length - 1;
  }

  /**
   * returns a list of all conditions groups belonging to an entry or its parents
   */
  public getEntryConditions(entryName: string): ConditionGroup[] {
    const key = 'getEntryConditions' + entryName;
    if (this.cache.has(key)) {
      return this.cache.get<ConditionGroup[]>(key);
    }
    let conditionGroups: ConditionGroup[] = [];
    const entry = this.getEntryWrapperByName<ConditionalEntry>(entryName, false);
    if (entry?.condition) {
      conditionGroups.push(entry.condition);
    }
    const parent = this.getParentEntry(entryName, false);
    if (parent) {
      conditionGroups = [...conditionGroups, ...this.getEntryConditions(parent.name)];
    }

    this.cache.set(key, conditionGroups);
    return conditionGroups;
  }

  /**
   * returns a map with all entries which have direct or indirect conditions
   */
  public getEntriesWithConditions(): Map<string, ConditionGroup[]> {
    const key = 'getEntriesWithConditions';
    if (this.cache.has(key)) {
      return this.cache.get<Map<string, ConditionGroup[]>>(key);
    }
    const result = CfgUtil.entryConditions(this.configuration.children);
    // this sets the caches for the getEntryConditions fn
    result.forEach((value, key) => {
      this.cache.set('getEntryConditions' + key, value);
    });
    this.cache.set(key, result);
    return result;
  }

  public getCalculations(): CfgCalculation[] {
    return this._configuration.calculations ?? [];
  }

  public getCalculation(name: string): CfgCalculation | undefined {
    return this.getCalculations().find((calculation) => calculation.name === name);
  }

  /**
   * converts the given nodes to list of EntryUsage objects
   */
  private convertNodesToEntryUsage<T>(nodes: JsonNode<T>[]): EntryUsage<T>[] {
    const entryUsages: EntryUsage<T>[] = [];

    for (const node of nodes) {
      if (CfgUtil.isPathInConfiguration(node.path)) {
        const entryPath = CfgUtil.getLastEntryPath(node.path);
        if (entryPath) {
          const queryResult = Jsonpath.queryFirst(this.configuration, entryPath);
          if (queryResult !== undefined) {
            if (Is.wrapperEntry(queryResult)) {
              entryUsages.push({
                usedBy: {
                  cls: queryResult.entry.cls,
                  name: queryResult.entry.name,
                  label: queryResult.entry.label,
                },
                entry: node.value,
              } as EntryUsage<T>);
              continue;
            }
            throw new Error('Not a valid entry usage path: ' + JSON.stringify(queryResult));
          }
        }
      }

      if (CfgUtil.isPathInSettings(node.path)) {
        entryUsages.push({
          usedBy: {
            cls: CLS.CONFIGURATION_SETTINGS,
            name: CLS.CONFIGURATION_SETTINGS,
            label: 'Settings',
          },
          entry: node.value,
        } as EntryUsage<T>);
        continue;
        // }
      }

      if (CfgUtil.isPathInCalculations(node.path)) {
        const calculation = this.getCalculationFromPath(node.path);
        if (!calculation) {
          throw new Error('Not a valid calculation path: ' + JSON.stringify(node));
        }
        entryUsages.push({
          usedBy: {
            cls: CLS.CALCULATION,
            name: calculation?.name,
            label: calculation?.label,
          },
          entry: node.value,
        } as EntryUsage<T>);
      }
    }
    return entryUsages;
  }

  /**
   * returns the path to the deepest entry in the path
   */
  private getCalculationFromPath(path: string[]): CfgCalculation | undefined {
    if (!CfgUtil.isPathInCalculations(path)) {
      throw new Error('Path is not part of a calculation');
    }
    const calculationPath = path.slice(0, 3);
    const queryResult = Jsonpath.queryFirst<unknown>(this.configuration, calculationPath);
    if (!Is.cfgCalculation(queryResult)) {
      throw new Error('Not a valid calculation path: ' + JSON.stringify(queryResult));
    }
    return queryResult;
  }
}

export class CmsCfgUtil extends CfgUtil<CmsConfigurator> {
  public static createCms(configuration: CmsConfigurator): CmsCfgUtil {
    return new CmsCfgUtil(ObjectUtil.clone(configuration));
  }

  public isPublished(): boolean {
    return this.configuration.versions.drafted > 0 || this.configuration.versions.published > 0;
  }
}
