import { QueryParams, RequestOptions, WebEndpointService } from './';
import { FeCache } from '../common';
import { CACHE_L, DeleteEndpoint, Endpoint, GetEndpoint, PostEndpoint } from '@kfd/core';
import { Observable, tap } from 'rxjs';

export interface CachedRequestOptions extends RequestOptions {
  useCache?: boolean;
}

export class CachedWebEndpointService {
  private readonly cache: FeCache;

  constructor(
    private webEndpointService: WebEndpointService,
    private cacheTtl = CACHE_L,
  ) {
    this.cache = new FeCache({ autoClean: true, defaultTtl: cacheTtl });
  }

  private static createKey<T>(endpoint: Endpoint<T>, params: (string | number)[], queryParams?: QueryParams): string {
    return `${endpoint.path}-${params.join('-')}-${JSON.stringify(queryParams)}`;
  }

  public get<T>(
    endpoint: GetEndpoint<T>,
    params: (string | number)[] = [],
    options: CachedRequestOptions = {},
  ): Observable<T> {
    const key = CachedWebEndpointService.createKey(endpoint, params, options.params);
    const obs$ = this.webEndpointService.get(endpoint, params, options);
    return options.useCache === true ? this.cache.cacheObs(obs$, key) : obs$;
  }

  public post<RequestBody, Response>(
    endpoint: PostEndpoint<RequestBody, Response>,
    params: (string | number)[] = [],
    body?: RequestBody,
    options: CachedRequestOptions = {},
  ): Observable<Response> {
    return this.webEndpointService.post(endpoint, params, body, options).pipe(tap(() => this.cache.clear()));
  }

  public put<RequestBody, Response>(
    endpoint: PostEndpoint<RequestBody, Response>,
    params: (string | number)[] = [],
    body: RequestBody,
    options: CachedRequestOptions = {},
  ): Observable<Response> {
    return this.webEndpointService.put(endpoint, params, body, options).pipe(tap(() => this.cache.clear()));
  }

  public delete<Response>(
    endpoint: DeleteEndpoint<Response>,
    params: (string | number)[] = [],
    options: CachedRequestOptions = {},
  ): Observable<Response> {
    return this.webEndpointService.delete(endpoint, params, options).pipe(tap(() => this.cache.clear()));
  }
}
