@if (viewData$ | async; as viewData) {
  @if (viewData.cfgSkeleton) {
    @if (viewData.cfgSkeleton?.children.length > 0) {
      <div
        #dndAreaContainer
        (resized)="onResize()"
        [kfdBlock]="!showPreview"
        [ngClass]="{ visible: true, 'dnd-active': dndActive$ | async }"
        class="preview-wrapper"
      >
        <kfd-dnd-area-configuration
          #configurationContainer
          [pageHeight]="pageHeight"
          [pageWidth]="pageWidth"
          [style.transform]="'translate3d(' + positionAbs.x + 'px, 0, 0)'"
          [style.transition]="'transform ease-out ' + transitionTime + 'ms'"
          [style.width]="pageWidth * viewData.cfgSkeleton.children.length + 'px'"
          class="configuration"
        >
        </kfd-dnd-area-configuration>
        <div class="deselect-bg" (click)="select(undefined)"></div>
      </div>

      @if (viewData.cfgSkeleton?.children.length > 1) {
        <div class="pagination" data-automationId="editorPagination">
          @for (page of viewData.cfgSkeleton.children; track page.name; let cfgPosition = $index) {
            <div
              class="selector"
              [ngClass]="{ selected: page.name === viewData.currentPage?.name }"
              [attr.data-automationId]="'selectPage-' + page.name"
              (click)="select(page.name)"
              [pTooltip]="page.name"
              tooltipPosition="top"
            >
              {{ cfgPosition + 1 }}
            </div>
          }
        </div>
      }
    } @else {
      <kfd-preview-dropzone
        [accepts]="allowedChildren[CLS.CONFIGURATION]"
        [area]="true"
        [parent]="viewData.cfgSkeleton.name"
      >
        <kfd-placeholder-msg
          (btnClick)="createFirstPage()"
          [btnVisible]="viewData.editMode"
          [infoText]="viewData.editMode ? 'Fügen Sie eine Seite hinzu um mit der Konfiguration zu beginnen.' : ''"
          btnLabel="Neue Seite"
          class="flex-1 justify-content-center"
          data-automationId="no-page-info-msg"
          infoTitle="Keine Seite vorhanden"
        ></kfd-placeholder-msg>
      </kfd-preview-dropzone>
    }
  } @else {
    <kfd-placeholder-msg
      [infoIcon]="ICON.CONFIGURATIONS"
      class="flex-1 justify-content-center editor-element"
      infoText="Seiten werden geladen"
    ></kfd-placeholder-msg>
  }

  @if (viewData.dndActive) {
    <div (pointerover)="prevPage()" class="scroll-zone left" [ngClass]="{ hidden: !viewData.btnVisibility.prev }">
      <i class="pi {{ ICON.BACK }} text-lg"></i>
    </div>
    <div (pointerover)="nextPage()" class="scroll-zone right" [ngClass]="{ hidden: !viewData.btnVisibility.next }">
      <i class="pi {{ ICON.NEXT }} text-lg"></i>
    </div>
  } @else {
    <div (click)="prevPage()" [ngClass]="{ hidden: !viewData.btnVisibility.prev }" class="btn-left">
      <i class="pi {{ ICON.BACK }} text-lg"></i>
    </div>
    <div (click)="nextPage()" [ngClass]="{ hidden: !viewData.btnVisibility.next }" class="btn-right">
      <i class="pi {{ ICON.NEXT }} text-lg"></i>
    </div>
  }
  <div class="shadow"></div>
}
