import { Component, Input } from '@angular/core';
import { AuthService } from '@kfd/web-core';
import { UserInfo } from '@kfd/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'kfd-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  standalone: false,
})
export class UserInfoComponent {
  @Input()
  public noUserMsg = '';
  protected user$: Observable<UserInfo | undefined> | undefined;

  constructor(private authService: AuthService) {}

  private _id: string | undefined;

  get id(): string | undefined {
    return this._id;
  }

  @Input()
  public set id(value: string | undefined) {
    this._id = value;
    if (!value) {
      this.user$ = undefined;
      return;
    }
    this.loadUser();
  }

  loadUser() {
    if (this.id === undefined) {
      return;
    }
    this.user$ = this.authService.specificUserInfo(this.id);
  }
}
