import { Socket } from 'ngx-socket-io';
import { environment } from '../../environments/environment';

/**
 * custom socket class for notifications connection
 * cannot be provided in app.module because the cookie may not be set on application load
 * it is necessary to create an instance after the required component is loaded
 */
export class NotificationSocket extends Socket {
  constructor() {
    super({
      url: environment.services.cms_websocket.url,
      options: {
        path: environment.services.cms_websocket.path,
        extraHeaders: {
          authorization: document.cookie.replace(/.*ws_token=([a-zA-Z0-9-_.]+),?/, '$1'),
        },
      },
    });
  }
}
