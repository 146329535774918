import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { map, Observable, Subject, throwError } from 'rxjs';
import {
  CACHE_L,
  CACHE_M,
  CACHE_S,
  CmsLicence,
  CmsProject,
  CmsProjectCI,
  cmsServiceEP,
  CreateProjectData,
  Id,
  LicenceConfigOptions,
  LicenceLimit,
  LicenceLimitValue,
  LicencePackage,
  OrderedLicence,
  ProjectInfo,
  RequestStatus,
  ScopedUser,
  SelectOption,
  USER_SCOPES,
} from '@kfd/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService, BaseHttpService, CachedWebEndpointService, FeCache, WebEndpointService } from '@kfd/web-core';
import { CmsContextService } from '../cms-context.service';
import { ScopeUtil } from '../../shared/scope.util';
import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ApiProjectService implements OnDestroy {
  private destroy$ = new Subject<boolean>();
  private httpService: BaseHttpService;
  private cache = new FeCache();
  private readonly cwes: CachedWebEndpointService;

  constructor(
    httpClient: HttpClient,
    readonly webEndpointService: WebEndpointService,
    private authService: AuthService,
    private ctx: CmsContextService,
    @Inject(PLATFORM_ID) platformId: object,
  ) {
    const isSSR = isPlatformServer(platformId);
    this.cwes = new CachedWebEndpointService(webEndpointService, CACHE_L, isSSR);
    this.httpService = new BaseHttpService(httpClient, environment.services.cms);
    this.authService.onSignInChange().subscribe(() => {
      this.cache.clear();
      this.cwes.clearCache();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public clearCache(): void {
    this.cwes.clearCache();
  }

  public listProjects(useCache = true): Observable<typeof cmsServiceEP.project.getMyProjects.response> {
    return this.cwes.get(cmsServiceEP.project.getMyProjects, [], {
      useCache,
    });
  }

  public getProject(projectId: string | undefined): Observable<typeof cmsServiceEP.project.getSingle.response> {
    if (!projectId) {
      return throwError(() => new Error('No project id given'));
    }
    return this.cwes.get(cmsServiceEP.project.getSingle, [projectId]);
  }

  public loadInfoChanges(projectId: string): Observable<ProjectInfo> {
    return this.httpService.get<ProjectInfo>(`/project/${projectId}/info-changes`);
  }

  public update(projectId: string, data: CmsProject): Observable<boolean> {
    return this.httpService.put<boolean>(`/project/${projectId}`, data);
  }

  public updateInfo(projectId: string, data: ProjectInfo): Observable<boolean> {
    return this.httpService.put<boolean>(`/project/${projectId}/info`, data);
  }

  public updateCi(projectId: string, data: CmsProjectCI): Observable<boolean> {
    return this.cwes.put(cmsServiceEP.project.setCorporateIdentity, [projectId], data);
  }

  public getUserWithScopes(projectId: string): Observable<ScopedUser> {
    // use native client to avoid error handling
    return this.httpService.httpClient.get<ScopedUser>(`${environment.services.cms}/project/${projectId}/user`, {
      withCredentials: true,
    });
  }

  public getUserScopesForCurrentProject(useCache = false): Observable<USER_SCOPES[]> {
    return this.getUserScopes(this.ctx.projectId, useCache);
  }

  public getUserScopes(projectId: string, useCache = false): Observable<USER_SCOPES[]> {
    return this.cwes.get(cmsServiceEP.project.userScopes, [projectId], { useCache });
  }

  public isScopeAllowed(projectId: string, allowedForScopes: USER_SCOPES[]): Observable<boolean> {
    return this.getUserScopes(projectId).pipe(
      map((userScopes) => {
        if (!userScopes) {
          return false;
        }
        return ScopeUtil.scopeAllowed(userScopes, allowedForScopes);
      }),
    );
  }

  public getRequestStatusListForService(useCache = true): Observable<RequestStatus[]> {
    const key = 'request-status-list-for-service' + this.ctx.projectId;
    const obs = this.httpService.get<RequestStatus[]>(`/project/${this.ctx.projectId}/request-status/service`);
    return useCache ? this.cache.cacheObs(obs, key, CACHE_M) : obs;
  }

  public getRequestStatus(useCache = true): Observable<RequestStatus[]> {
    const key = 'request-status-list-' + this.ctx.projectId;
    const obs = this.httpService.get<RequestStatus[]>(`/project/${this.ctx.projectId}/request-status`);
    return useCache ? this.cache.cacheObs(obs, key, CACHE_M) : obs;
  }

  public setRequestStatus(requestStatusList: RequestStatus[]): Observable<boolean> {
    return this.httpService.put<boolean>(`/project/${this.ctx.projectId}/request-status`, {
      requestStatus: requestStatusList,
    });
  }

  public getLicence(): Observable<CmsLicence> {
    return this.httpService.get<CmsLicence>(`/project/${this.ctx.projectId}/licence`);
  }

  public getLicenceLimits(): Observable<LicenceLimit> {
    return this.httpService.get<LicenceLimit>(`/project/${this.ctx.projectId}/licence/limits`);
  }

  public getLicenceLimit(projectId: Id, option: LicenceConfigOptions): Observable<LicenceLimitValue> {
    return this.cwes
      .get(cmsServiceEP.project.licenceLimits, [projectId.toString()])
      .pipe(map((licenceLimits) => licenceLimits.limits[option]));
  }

  public getOrderedLicence(): Observable<OrderedLicence> {
    return this.httpService.get<OrderedLicence>(`/project/${this.ctx.projectId}/licence/order`);
  }

  public deleteOrderedLicence(): Observable<boolean> {
    return this.httpService.delete<boolean>(`/project/${this.ctx.projectId}/licence/order`);
  }

  public orderLicence(licencePackage: LicencePackage, asap = false): Observable<boolean> {
    return this.httpService.post<boolean>(`/project/${this.ctx.projectId}/licence/order`, {
      licencePackage,
      asap,
    });
  }

  public checkName(name: string): Observable<boolean> {
    return this.cwes.get(cmsServiceEP.project.nameExists, [name]);
  }

  public createProject(projectData: CreateProjectData): Observable<typeof cmsServiceEP.project.create.response> {
    return this.cwes.post(cmsServiceEP.project.create, [], projectData);
  }

  public deleteProject(projectId: Id): Observable<boolean> {
    return this.cwes.delete(cmsServiceEP.project.delete, [projectId.toString()]);
  }

  public updateUser(projectId: string, user: ScopedUser): Observable<boolean> {
    return this.httpService.put<boolean>(`/project/${projectId}/user/${user._id}`, user);
  }

  public removeUser(projectId: string, userId: string): Observable<void> {
    return this.httpService.delete<void>(`/project/${projectId}/user/${userId}`);
  }

  public leaveMandant(projectId: string): Observable<boolean> {
    return this.httpService.delete(`/project/${projectId}/user`).pipe(map(() => true));
  }

  public customerServiceUserList(): Observable<SelectOption[]> {
    const key = FeCache.key('customer-service-users', this.ctx.projectId);
    const obs$ = this.httpService.get<SelectOption[]>(`/project/${this.ctx.projectId}/customer-service-users`);
    return this.cache.cacheObs(obs$, key, CACHE_S);
  }
}
