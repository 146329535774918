@if (fn) {
  <div>
    <label>Funktion</label>
    <p-dropdown (onChange)="change()" [(ngModel)]="fn.command" [options]="functionCommandList" appendTo="body">
    </p-dropdown>
  </div>
  <ng-container [ngSwitch]="fn.command">
    <ng-container *ngSwitchCase="functionCommand.ROUND">
      <div>
        <label>Nachkommastellen</label>
        <p-inputNumber (onInput)="change()" [(ngModel)]="fn.params[0]" [max]="9" [min]="0" [showButtons]="true">
        </p-inputNumber>
      </div>
    </ng-container>
  </ng-container>
}
