<div [ngClass]="{ collapsed: collapsed }" class="side-navigation" data-automationId="main-navigation">
  <div [ngClass]="{'flex-column': collapsed}" class="flex align-items-center logo-icon-container">
    @if (collapsed) {
      <a class="logo-icon" routerLink="/"></a>
    } @else {
      <a class="logo flex-1" routerLink="/"></a>
    }
  </div>

  <nav [kfdBlock]="!menuItems" class="left-menu">
    <p-menu [model]="menuItems" styleClass="auto-width invisible side-navigation">
      <ng-template let-item pTemplate="submenuheader">
        <ng-template [ngTemplateOutletContext]="{ item:item }" [ngTemplateOutlet]="menuItem"></ng-template>
      </ng-template>
      <ng-template let-item pTemplate="item">
        <ng-template [ngTemplateOutletContext]="{ item:item }" [ngTemplateOutlet]="menuItem"></ng-template>
      </ng-template>
    </p-menu>

    <div class="meta-menu mt-4">
      <p-menu [model]="metaMenuItems" styleClass="auto-width invisible side-navigation small mt-1">
        <ng-template let-item pTemplate="submenuheader">
          <ng-template [ngTemplateOutletContext]="{ item:item }" [ngTemplateOutlet]="menuItem"></ng-template>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <ng-template [ngTemplateOutletContext]="{ item:item }" [ngTemplateOutlet]="menuItem"></ng-template>
        </ng-template>
      </p-menu>

      <div class="flex justify-content-end m-2">
        <p-button
          (click)="toggleCollapse()"
          [icon]="'pi ' + (collapsed ? ICON.BURGER_MENU : ICON.BURGER_MENU_OPEN)"
          [pTooltip]="collapsed ? 'Menü anzeigen' : 'Menü ausblenden'"
          [text]="true"
          severity="secondary"
        ></p-button>
      </div>
    </div>
  </nav>
</div>

<ng-template #menuItem let-item="item">
  <!--  @if (item.styleClass === 'project') {-->
  <!--    <div-->
  <!--      [attr.data-automationId]="item.automationId"-->
  <!--      [attr.tabindex]="-1"-->
  <!--      [pTooltip]="collapsed ? item.tooltip : undefined"-->
  <!--      class="flex justify-content-between align-items-center px-3 pt-3"-->
  <!--    >-->
  <!--      <div class="no-wrap flex justify-content-end align-items-center">-->
  <!--        <span class="p-menuitem-icon {{ item.icon }}"></span>-->
  <!--        @if (!collapsed) {-->
  <!--          <span class="p-menuitem-text no-wrap">{{ item.label }}</span>-->
  <!--        }-->
  <!--      </div>-->
  <!--      <p-button-->
  <!--        [icon]="ICON.SELECT"-->
  <!--        pTooltip="Projekt wechseln"-->
  <!--        [outlined]="false"-->
  <!--        [text]="true"-->
  <!--        (click)="item.command()"-->
  <!--      ></p-button>-->
  <!--      &lt;!&ndash;      </div>&ndash;&gt;-->
  <!--    </div>-->
  <!--  } @else if (item.routerLink) {-->
  @if (item.routerLink) {
    <a
      *ngIf="!item?.url"
      [attr.data-automationId]="item.automationId"
      [attr.tabindex]="-1"
      [pTooltip]="collapsed ? item.tooltip : undefined"
      [routerLinkActiveOptions]="item.routerLinkActiveOptions ?? {}"
      [routerLinkActive]="'p-menuitem-link-active'"
      [routerLink]="item.routerLink"
      class="flex justify-content-between align-items-center menu-item p-menuitem-link {{item.styleClass}}"
    >
      <div class="overflow-hidden no-wrap flex align-items-center"
           [ngClass]="{'justify-content-center': collapsed, 'justify-content-end': !collapsed}">
        <span class="p-menuitem-icon {{ item.icon }}"></span>
        @if (!collapsed) {
          <span class="p-menuitem-text text-overflow-ellipsis">{{ item.label }}</span>
        }
      </div>
    </a>
  } @else if (!collapsed) {
    <div
      [attr.data-automationId]="item.automationId"
      [attr.tabindex]="-1"
      [pTooltip]="collapsed ? item.tooltip : undefined"
      class="flex justify-content-between align-items-center menu-item"
      (click)="item.command()"
    >
      <div class="overflow-hidden no-wrap flex align-items-center"
           [ngClass]="{'justify-content-center': collapsed, 'justify-content-end': !collapsed}">
        <span class="p-menuitem-icon {{ item.icon }}"></span>
        @if (!collapsed) {
          <span class="p-menuitem-text text-overflow-ellipsis">{{ item.label }}</span>
        }
      </div>
    </div>
  }
</ng-template>
