<kfd-fullscreen-layout>
  <div class="login-form">
    <h1>Anmeldung</h1>

    @if (infoText) {
      <p>{{ infoText }}</p>
    } @else {
      <p>Bitte melden Sie sich mit Ihren Zugangsdaten an um fortzufahren.</p>
    }

    <kfd-sign-in (loginSuccess)="close()" (pageChange)="close()" [emitLogin]="true" class="mt-6 block"></kfd-sign-in>
  </div>
</kfd-fullscreen-layout>
