import {
  BaseDataRef,
  BaseDataValueRef,
  BoolInputValue,
  CalculationRef,
  CLS,
  CONDITIONAL_OPERATOR,
  DATA_TYPES,
  DATA_VALUE_TYPE,
  DateInputValue,
  DateRef,
  FIELD_TYPES,
  FieldRef,
  Image,
  InputValue,
  LOGICAL_OPERATOR,
  NumericInputValue,
  PageRef,
  SELECT_FIELD_LAYOUTS,
  TextInputValue,
  TypedObject,
  Value,
  ValueLabel,
} from './common.dto';
import { UnitTypeShort } from 'dayjs';

/**
 * this files contains all dtos for the configuration in the final configurator
 * NO! cms specfic data
 */

export enum LocalCodes {
  EN_US = 'en-US',
  DE_DE = 'de-DE',
}

export interface ParentEntry {
  children: ConditionalEntry[];
}

export type NamedEntry = {
  label?: string;
  name: string;
};

export type GenericEntry = NamedEntry & {
  cls: CLS;
  hide?: boolean;
  hideLabel?: boolean;
};

export interface ChildWrapper {
  cls: CLS;
  entry: GenericEntry | GenericParentEntry;
}

export interface GenericParentEntry extends GenericEntry {
  children?: ChildWrapper[];
}

export interface Versions {
  current: number;
}

export interface LocalizationSettings {
  translations?: LocalCodes[];
  locale?: LocalCodes;
}

/**
 * contains general settings which are versioned
 */
export interface ConfiguratorSettings {
  cls: CLS.CONFIGURATION_SETTINGS;
  customerEmailField?: FieldRef;
  l10n?: LocalizationSettings;
}

export interface Configuration extends GenericEntry, GenericParentEntry {
  cls: CLS.CONFIGURATION;
  label: string;
  description?: string;
  versions: Versions;
  children: ConfiguratorPage[];
  // calculation?: Calculation;
  calculations?: CfgCalculation[];
  settings: ConfiguratorSettings;
}

// export interface FieldGroupConfig {
// }

export interface FieldGroup extends GenericParentEntry, ParentEntry {
  cls: CLS.FIELD_GROUP;
  info?: Info;
  hint?: string;
  children: ConfiguratorField[];
}

export interface ConfiguratorFieldGroup extends ChildWrapper, ConditionalEntry {
  cls: CLS.FIELD_GROUP_WRAPPER;
  entry: FieldGroup;
}

export interface Page extends GenericParentEntry, ParentEntry {
  cls: CLS.PAGE;
  info?: Info;
  noNavPrev?: boolean;
  noNavNext?: boolean;
  children: (ConfiguratorFieldGroup | ConfiguratorField)[];
}

export interface ConfiguratorPage extends ChildWrapper, ConditionalEntry {
  cls: CLS.PAGE_WRAPPER;
  entry: Page;
}

export interface Field<T = FieldConfig> extends GenericEntry {
  info?: Info;
  hint?: string;
  config: T;
}

export interface ConditionalEntry {
  cls: CLS;
  entry: GenericEntry | GenericParentEntry;
  condition?: ConditionGroup;
}

export interface ConditionalChildWrapper extends ChildWrapper, ConditionalEntry {}

export interface ConditionGroup {
  cls: CLS.CONDITION_GROUP;
  lop: LOGICAL_OPERATOR;
  conditions: Condition[];
}

export interface Condition {
  cls: CLS.CONDITION;
  //given
  value1: FieldRef;
  op: CONDITIONAL_OPERATOR;
  //expected
  value2?: InputValue | FieldRef | undefined;
}

export interface ConfiguratorField<T = FieldConfig> extends ChildWrapper, ConditionalEntry {
  cls: CLS.FIELD_WRAPPER;
  entry: Field<T>;
}

export enum CalcOperation {
  ADD = 'add',
  MUL = 'mul',
  DIV = 'div',
  SUB = 'sub',
  DATEDIFF = 'datediff',
}

export interface AliasValue {
  cls: CLS.ALIAS_VALUE;
  name: string;
  label?: ValueLabel;
}

export type CalculationParam = Calculation | CalculationRef | Value | AliasValue | FieldRef | BaseDataValueRef;
export type CalculationParams = CalculationParam[];

export enum FunctionCommand {
  ROUND = 'rnd',
  CEIL = 'cl',
  FLOOR = 'fl',
  ABS = 'abs',
}

export interface CalculationFunction {
  cls: CLS.CALCULATION_FUNCTION;
  command: FunctionCommand;
  params?: (string | number)[];
}

export interface Calculation {
  cls: CLS.CALCULATION;
  op: CalcOperation;
  params: CalculationParams;
  label?: ValueLabel;
  alias: string;
  excludeFromResult?: boolean;
  condition?: Condition;
  fn?: CalculationFunction;
}

export type CfgCalculation = NamedEntry & {
  calculation: Calculation;
};

export type ValueCalcParam = Calculation | Value | AliasValue;

export interface ValueCalculation extends Calculation {
  params: ValueCalcParam[];
}

export interface CalculationResult {
  cls: CLS.CALCULATION_RESULT;
  results: CalculationResult[];
  label?: ValueLabel;
  value?: number;
  exclude?: boolean;
}

export type Info = NamedEntry & {
  content: string[];
  modal?: boolean;
};

export interface Data {
  type: DATA_TYPES;
  //todo is this still in use since we added values array for SelectionData
  value?: Value;
  name?: string;
}

export interface SelectionData extends Omit<Data, 'value'> {
  cls: CLS.SELECTION_DATA;
  type: DATA_TYPES.SELECTION;
  id?: string;
  name: string;
  label: string;
  values?: InputValue[];
  templateName?: string;
  tags?: string[];
  image?: Image;
  description?: string;
  info?: Info;
}

export interface FieldConfig {
  cls: CLS.FIELD_CONFIG;
  type: FIELD_TYPES;
  dataType: DATA_VALUE_TYPE;
  //default type is unknown because we have huge differences between the types
  default?: TypedObject;
  required?: boolean;
  disabled?: boolean;
}

export interface InputFieldConfig {
  unit?: string;
  min?: NumericInputValue;
  max?: NumericInputValue;
  digits?: number;
  step?: number;
  showButtons?: boolean;
  required?: boolean;
}

// Calculation is deprecated and replaced by CalculationRef
export type DisplayDataValues = InputValue | Calculation | FieldRef | CalculationRef;

export interface DisplayDataFieldConfig extends FieldConfig {
  type: FIELD_TYPES.DISPLAY_DATA;
  value: DisplayDataValues;
}

export interface ResetButtonFieldConfig extends FieldConfig {
  type: FIELD_TYPES.RESETBTN;
  dataType: DATA_VALUE_TYPE.EMPTY;
  btnLabel: string;
}

export interface SubmitButtonFieldConfig extends FieldConfig {
  type: FIELD_TYPES.SUBMITBTN;
  dataType: DATA_VALUE_TYPE.EMPTY;
  btnLabel?: string;
  successText?: string;
  resetOnSubmit?: boolean;
  showRequestId?: boolean;
  linkRequestId?: boolean;
  goToPage?: PageRef;
}

export interface SummaryFieldConfig extends FieldConfig {
  type: FIELD_TYPES.SUMMARY;
}

export interface BooleanFieldConfig extends FieldConfig {
  type: FIELD_TYPES.YESNO;
  dataType: DATA_VALUE_TYPE.BOOL;
  default?: BoolInputValue;
  labels?: {
    yes: string;
    no: string;
  };
}

export interface NumericFieldConfig extends InputFieldConfig, FieldConfig {
  type: FIELD_TYPES.NUMBER;
  dataType: DATA_VALUE_TYPE.NUMERIC;
  default?: NumericInputValue;
  value?: NumericInputValue;
}

export enum TEXTFIELD_VALIDATIONS {
  TEXT = 'text',
  EMAIL = 'email',
  LINK = 'link',
  PHONE = 'phone',
  DATE = 'date',
}

export interface TextFieldConfig extends InputFieldConfig, FieldConfig {
  type: FIELD_TYPES.TEXT;
  dataType: DATA_VALUE_TYPE.STRING;
  multiline?: boolean;
  default?: TextInputValue;
  validation: TEXTFIELD_VALIDATIONS;
}

export enum START_DATES {
  TODAY = 'now',
  WEEK_START = 'week-start',
  WEEK_END = 'week-end',
  MONTH_START = 'month-start',
  MONTH_END = 'month-end',
}

export enum UNIT_TYPES {
  DAY = 'd',
  WEEK = 'w',
  MONTH = 'M',
  QUARTER = 'Q',
  YEAR = 'y',
}

export type DateUnits = UnitTypeShort;

export type RelativeDateManipulation = '+' | '-';

export interface RelativeDate {
  cls: CLS.RELATIVE_DATE;
  start: DateRef | FieldRef;
  manipulation: RelativeDateManipulation;
  value: number;
  unit: DateUnits;
}

export interface DateFieldConfig extends FieldConfig {
  type: FIELD_TYPES.DATE;
  dataType: DATA_VALUE_TYPE.DATE;
  default?: DateInputValue | RelativeDate;
  min?: DateInputValue | RelativeDate;
  max?: DateInputValue | RelativeDate;
  disabledDays?: number[];
  inline?: boolean;
  showOnFocus?: boolean;
  showIcon?: boolean;
}

export enum DATAHANDLER_TYPES {
  LIST = 'list',
  DYNAMIC = 'dynamic',
}

export interface DataHandler {
  cls: CLS.DATA_HANDLER;
  type: DATAHANDLER_TYPES;
}

export interface SimpleListHandler extends DataHandler {
  type: DATAHANDLER_TYPES.LIST;
  data: SelectionData[] | BaseDataRef[];
}

export enum SORT_DIRECTION {
  ASC = 'asc',
  DESC = 'desc',
}

export interface Sort {
  field: string;
  direction: SORT_DIRECTION;
}

export interface DynamicDataHandler extends DataHandler {
  type: DATAHANDLER_TYPES.DYNAMIC;
  templateName?: string;
  tags?: string[];
  sort?: Sort[];
}

export type DataHandlers = SimpleListHandler | DynamicDataHandler;

export interface LabeledValue {
  label: string;
  value: string;
}

// todo support default value???
export interface SelectFieldConfig extends FieldConfig {
  type: FIELD_TYPES.SELECT;
  layout: {
    type: SELECT_FIELD_LAYOUTS;
  };
  //string which will be added as a no-value-option
  emptySelection?: string;
  dataHandler: DataHandlers;
  additionalValues?: LabeledValue[];
}

export interface MultiSelectFieldConfig extends SelectFieldConfig {
  min?: NumericInputValue;
  max?: NumericInputValue;
}
