import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CLS, Create, DATA_VALUE_TYPE, ICON, InputValue, Is } from '@kfd/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'kfd-data-value-select',
  templateUrl: './data-value-select.component.html',
  styleUrls: ['./data-value-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DataValueSelectComponent,
    },
  ],
  standalone: false,
})
export class DataValueSelectComponent implements ControlValueAccessor {
  // shows accept button and disable automatic updates
  @Input()
  public showAcceptBtn = false;

  @Input()
  public allowedTypes: DATA_VALUE_TYPE[] = Object.values(DATA_VALUE_TYPE);

  @Input()
  public defaultValueEmpty = false;

  @Output()
  public valueChange: EventEmitter<InputValue> = new EventEmitter<InputValue>();

  protected readonly ICON = ICON;
  protected disabled = false;
  protected inputValue: InputValue = Create.emptyInputValue();
  protected type: DATA_VALUE_TYPE | undefined;
  private onChanged: CallableFunction | undefined;
  private onTouched: CallableFunction | undefined;

  @Input()
  public set value(value: InputValue) {
    if (!Is.inputValue(value)) {
      return;
    }
    this.inputValue = value;
    this.type = value.type;
  }

  public writeValue(value: InputValue | undefined): void {
    this.value = value;
  }

  public registerOnChange(onChange: CallableFunction): void {
    this.onChanged = onChange;
  }

  public registerOnTouched(onChange: CallableFunction): void {
    this.onTouched = onChange;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  protected onTypeChange(type: DATA_VALUE_TYPE): void {
    if (this.type === type) {
      return;
    }
    this.type = type;
    if (this.defaultValueEmpty) {
      //do not use create to avoid empty input values (type is required here)
      this.inputValue = {
        cls: CLS.INPUT_VALUE,
        type,
      };
    } else {
      this.inputValue = Create.createDefaultInputValue(type);
    }
    if (!this.showAcceptBtn) {
      this.save();
    }
  }

  protected onInputValueChange(value: InputValue): void {
    this.inputValue = value;
    if (!this.showAcceptBtn) {
      this.save();
    }
  }

  protected save() {
    if (!Is.inputValue(this.inputValue)) {
      return;
    }
    this.valueChange.emit(this.inputValue);
    if (this.onTouched) {
      this.onTouched(this.inputValue);
    }
    if (this.onChanged) {
      this.onChanged(this.inputValue);
    }
  }
}
