import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { User, USER_ROLES } from '@kfd/core';
import { of, Subject, switchMap } from 'rxjs';
import { AuthService } from '@kfd/web-core';
import { map } from 'rxjs/operators';

/**
 * example usage to show an element
 * <div *kfdOnlyAdmin="'MY_FEATURE'">
 */
@Directive({
  selector: '[kfdOnlyAdmin]',
  standalone: false,
})
export class OnlyAdminDirective implements OnInit, OnDestroy {
  private destroy$ = new Subject<boolean>();

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<unknown>,
    private authService: AuthService,
  ) {}

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public ngOnInit(): void {
    this.viewContainer.clear();

    this.authService
      .onSignInChange()
      .pipe(
        switchMap((signedIn) => {
          if (!signedIn) {
            return of(false);
          } else {
            return this.authService.currentUser().pipe(map((user: User) => user.roles.indexOf(USER_ROLES.ADMIN) >= 0));
          }
        }),
      )
      .subscribe((isAdmin) => {
        if (isAdmin) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
  }
}
