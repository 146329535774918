import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnsetCtxGuard } from './project/unset-ctx.guard';
import { UserGuard } from './resolver/user.guard';
import { HealthGuard } from './resolver/health.guard';
import { CMS_APP_ROUTE_PARTS_ROOT, CMS_APP_ROUTES, COMMON_APP_ROUTE_PARTS } from '@kfd/core';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: CMS_APP_ROUTES.main.dashboard.path,
    title: CMS_APP_ROUTES.main.dashboard.title,
    loadChildren: () => import(`./pages/dashboard/dashboard.module`).then((m) => m.DashboardModule),
    canActivate: [HealthGuard, UserGuard, UnsetCtxGuard],
  },
  {
    path: CMS_APP_ROUTES.main.projectOverview.path,
    title: CMS_APP_ROUTES.main.projectOverview.title,
    loadChildren: () =>
      import(`./pages/projects-overview/projects-overview.module`).then((m) => m.ProjectsOverviewModule),
    canActivate: [HealthGuard, UserGuard],
  },
  {
    path: 'admin',
    title: 'Administration',
    loadChildren: () => import(`./pages/admin/admin.module`).then((m) => m.AdminModule),
    canActivate: [HealthGuard],
  },
  {
    path: CMS_APP_ROUTES.main.notifications.path,
    title: CMS_APP_ROUTES.main.notifications.title,
    loadChildren: () => import(`./pages/notifications/notifications.module`).then((m) => m.NotificationsModule),
    canActivate: [HealthGuard, UserGuard],
  },
  {
    path: CMS_APP_ROUTES.main.service.path,
    title: CMS_APP_ROUTES.main.service.title,
    loadChildren: () => import(`./pages/service/service.module`).then((m) => m.ServiceModule),
    canActivate: [HealthGuard],
  },
  {
    path: 'internal',
    title: 'Internal',
    loadChildren: () => import(`./internal/internal.module`).then((m) => m.InternalModule),
  },
  {
    path: CMS_APP_ROUTE_PARTS_ROOT.PROJECT,
    title: 'Projekt',
    canDeactivate: [UnsetCtxGuard],
    loadChildren: () => import(`./project/project.module`).then((m) => m.ProjectModule),
    canActivate: [HealthGuard],
  },
  {
    path: COMMON_APP_ROUTE_PARTS.AUTH,
    loadChildren: () => import(`@kfd/web-auth`).then((m) => m.AuthModule),
    canActivate: [HealthGuard],
  },
  {
    path: '**',
    redirectTo: '/dashboard',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
