import { ScopedUserRef } from './web.dto';
import { ObjectId } from 'mongodb';
import {
  ConditionGroup,
  Configuration,
  ConfiguratorField,
  ConfiguratorFieldGroup,
  ConfiguratorPage,
  Field,
  FieldGroup,
  GenericEntry,
  Page,
  SelectionData,
  SimpleListHandler,
  Versions,
} from './cfg.dto';
import { BaseDataRef, BaseInputValue, CLS, FormValue, Project, ProjectCI, ProjectInfo } from './common.dto';
import { Id } from './core.models';

/**
 * this files contains all cms specific configuration dto overwrites
 */

export enum PUBLISHED_STATE {
  UNPUBLISHED = 'unpublished',
  PUBLISHED = 'published',
  DRAFTED = 'drafted',
}

export interface EntryMeta {
  created?: Date;
  createdBy?: Id;
  updated?: Date;
  updatedBy?: Id;
  projectId?: Id;
  configuratorId?: Id;
  //original entryId of versioned entries
  entryId?: Id;
  // version number of versioned entries
  version?: number;
  state?: PUBLISHED_STATE;
}

export interface CmsConditionalRefEntry {
  cls: CLS;
  entry: ObjectId;
  condition?: ConditionGroup;
}

export interface CmsConditionalEntry<T = CmsGenericEntry | CmsGenericParentEntry> {
  cls: CLS;
  entry: T;
  condition?: ConditionGroup;
}

export interface CmsChildWrapper extends CmsConditionalEntry {
  cls: CLS;
  isNew?: boolean;
  isDirty?: boolean;
  condition?: ConditionGroup;
}

export interface CmsIdEntry {
  _id: Id;
}

export interface CmsParentEntry extends CmsIdEntry {
  children: CmsConditionalEntry[];
}

export interface CmsGenericEntry extends GenericEntry, CmsIdEntry {
  meta: EntryMeta;
  isNew?: boolean;
  isDirty?: boolean;
}

export type NewEntry<T> = Omit<T, '_id'>;

export interface CmsGenericParentEntry extends CmsGenericEntry {
  children?: CmsChildWrapper[];
}

export enum LicencePackage {
  STARTER = 'starter',
  ADVANCED = 'advanced',
  PRO = 'pro',
  CUSTOM = 'custom',
}

export enum LicenceConfigOptions {
  VIEW_LIMIT = 'viewLimit',
  REQUEST_LIMIT = 'requestLimit',
  USER_LIMIT = 'userLimit',
  CONFIG_LIMIT = 'configLimit',
  DATA_LIMIT = 'dataLimit',
  ASSET_LIMIT = 'assetLimit',
}

export interface LicenceConfig {
  viewLimit: number;
  requestLimit: number;
  userLimit: number;
  configLimit: number;
  dataLimit: number;
  assetLimit: number;
}

export interface LicenceLimitValue {
  limitReached: boolean;
  value: number;
  used: number;
}

export interface LicenceLimit {
  limitReached: boolean;
  limits: {
    viewLimit: LicenceLimitValue;
    requestLimit: LicenceLimitValue;
    userLimit: LicenceLimitValue;
    configLimit: LicenceLimitValue;
    dataLimit: LicenceLimitValue;
    assetLimit: LicenceLimitValue;
  };
}

export interface LicenceOptions {
  name: LicencePackage;
  price: number;
  duration: number;
  config: LicenceConfig;
}

export class StarterLicenceOptions implements LicenceOptions {
  name = LicencePackage.STARTER;
  config = {
    viewLimit: 100,
    requestLimit: 20,
    userLimit: 2,
    configLimit: 3,
    dataLimit: 50,
    assetLimit: 10,
  };
  price = 0;
  duration = 12;
}

export class AdvancedLicenceOptions implements LicenceOptions {
  name = LicencePackage.ADVANCED;
  config = {
    viewLimit: 1000,
    requestLimit: 100,
    userLimit: 5,
    configLimit: 10,
    dataLimit: 500,
    assetLimit: 100,
  };
  price = 0;
  duration = 6;
}

export class ProLicenceOptions implements LicenceOptions {
  name = LicencePackage.PRO;
  config = {
    viewLimit: 10000,
    requestLimit: 1000,
    userLimit: 20,
    configLimit: 100,
    dataLimit: 5000,
    assetLimit: 1000,
  };
  price = 1;
  duration = 12;
}

export class CustomLicenceOptions implements LicenceOptions {
  name = LicencePackage.CUSTOM;
  config = {
    viewLimit: 0,
    requestLimit: 0,
    userLimit: 0,
    configLimit: 0,
    dataLimit: 0,
    assetLimit: 0,
  };
  price = 0;
  duration = 12;
}

export const availableLicences: LicenceOptions[] = [
  new StarterLicenceOptions(),
  new AdvancedLicenceOptions(),
  new ProLicenceOptions(),
];

export interface CmsLicence {
  startDate: Date;
  endDate: Date;
  options: LicenceOptions;
}

export interface OrderedLicence {
  licence: CmsLicence;
  date: Date;
  approved?: Date;
  approvedBy?: Id;
}

export interface CmsProjectCI extends Omit<ProjectCI, 'logo'> {
  logo?: Id;
}

export interface CmsProject extends Omit<Project, 'ci'> {
  _id: Id;
  meta: EntryMeta;
  infoChanges: ProjectInfo;
  infoVerified: Date;
  licence: CmsLicence;
  orderedLicence?: OrderedLicence;
  users: ScopedUserRef[];
  requestStatus?: RequestStatus[];
  configurators?: CmsConfigurator[];
  ci?: CmsProjectCI;
  baseDataTemplates?: BaseDataTemplate[];
}

export interface RequestStatus {
  label: string;
  value: string;
  disabled?: boolean;
  protected?: boolean;
}

export interface CmsConfiguratorPage extends ConfiguratorPage, CmsChildWrapper {
  cls: CLS.PAGE_WRAPPER;
  entry: CmsPage;
}

export function isCmsConfiguratorPage(value: unknown): value is CmsConfiguratorPage {
  return (
    (value as CmsConfiguratorPage).cls === CLS.PAGE_WRAPPER && (value as CmsConfiguratorPage).entry.cls === CLS.PAGE
  );
}

export interface CmsVersions extends Versions {
  current: number;
  drafted: number;
  published: number;
}

export interface InternalSettings {
  titleFields?: string[];
  initialState?: string;
}

export interface BaseDataTemplateValue {
  cls: CLS.BASE_DATA_TEMPLATE_VALUE;
  inputValue: BaseInputValue;
  label: string;
  name: string;
  required?: boolean;
}

export interface BaseDataTemplate {
  cls: CLS.BASE_DATA_TEMPLATE;
  label: string;
  name: string;
  description?: string;
  icon?: string;
  allowedTags: string[];
  defaultTags: string[];
  values: BaseDataTemplateValue[];
}

export interface CmsConfigurator extends Configuration, CmsGenericParentEntry {
  cls: CLS.CONFIGURATION;
  label: string;
  meta: EntryMeta;
  versions: CmsVersions;
  code: string;
  trash?: Date;
  internalSettings?: InternalSettings;
  children: CmsConfiguratorPage[];
}

export interface CmsConfiguratorFieldGroup extends ConfiguratorFieldGroup, CmsChildWrapper {
  cls: CLS.FIELD_GROUP_WRAPPER;
  entry: CmsFieldGroup;
}

export function isCmsConfiguratorFieldGroup(value: unknown): value is CmsConfiguratorFieldGroup {
  return (
    (value as CmsConfiguratorFieldGroup).cls === CLS.FIELD_GROUP_WRAPPER &&
    (value as CmsConfiguratorFieldGroup).entry.cls === CLS.FIELD_GROUP
  );
}

export interface CmsPage extends Page, CmsGenericParentEntry {
  cls: CLS.PAGE;
  meta: EntryMeta;
  children: (CmsConfiguratorFieldGroup | CmsConfiguratorField)[];
}

export interface CmsConfiguratorField extends ConfiguratorField, CmsChildWrapper {
  cls: CLS.FIELD_WRAPPER;
  entry: CmsField;
}

export function isCmsConfiguratorField(value: unknown): value is CmsConfiguratorField {
  return (
    (value as CmsConfiguratorField).cls === CLS.FIELD_WRAPPER && (value as CmsConfiguratorField).entry.cls === CLS.FIELD
  );
}

export interface CmsFieldGroup extends FieldGroup, CmsIdEntry {
  meta: EntryMeta;
  children: CmsConfiguratorField[];
}

export interface CmsField extends Field, CmsGenericEntry {
  meta: EntryMeta;
}

export interface CmsSelectionData extends Omit<SelectionData, 'image'> {
  _id: Id;
  meta: EntryMeta;
  image?: Id;
  templateName?: string;
}

export interface CmsSimpleListHandler extends SimpleListHandler {
  data: BaseDataRef[];
}

export interface RequestCode {
  cfg: string;
  number: number;
}

//this is a form value map structure which can be converted to a @see FormValueMap
export type FormValueRecord = Record<string, FormValue>;
//map for identifier and value without context
export type FormValueMap = Map<string, FormValue>;
//map for identifier and base data
export type BaseDataMap = Map<string, SelectionData>;

export interface ConfigurationIdentifier {
  id: string;
  version: number;
}
