import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DATA_VALUE_TYPE, SelectOption } from '@kfd/core';
import { inputDataValueTypeList } from '../../global';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'kfd-data-value-type',
  templateUrl: './data-value-type.component.html',
  styleUrls: ['./data-value-type.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DataValueTypeComponent,
    },
  ],
  standalone: false,
})
export class DataValueTypeComponent implements ControlValueAccessor {
  @Output()
  public typeChange: EventEmitter<DATA_VALUE_TYPE> = new EventEmitter<DATA_VALUE_TYPE>();

  @Input()
  public type: DATA_VALUE_TYPE = DATA_VALUE_TYPE.EMPTY;

  protected typeList: SelectOption[] = inputDataValueTypeList;
  protected disabled = false;
  private onChanged: CallableFunction | undefined;
  private onTouched: CallableFunction | undefined;

  private _allowedTypes: DATA_VALUE_TYPE[] = Object.values(DATA_VALUE_TYPE);

  get allowedTypes(): DATA_VALUE_TYPE[] {
    return this._allowedTypes;
  }

  @Input()
  set allowedTypes(value: DATA_VALUE_TYPE[]) {
    this._allowedTypes = Array.isArray(value) ? value : [];
    this.setDataValueList();
    if (this._allowedTypes.length === 1) {
      this.type = this._allowedTypes[0];
      this.onTypeChanged(this._allowedTypes[0]);
    }
    this.disabled = this._allowedTypes.length === 1;
  }

  public writeValue(value: DATA_VALUE_TYPE): void {
    this.type = value;
  }

  public registerOnChange(onChange: CallableFunction): void {
    this.onChanged = onChange;
  }

  public registerOnTouched(onChange: CallableFunction): void {
    this.onTouched = onChange;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setDataValueList() {
    if (this.allowedTypes?.length > 0) {
      this.typeList =
        this.allowedTypes?.length > 1
          ? [
              {
                value: DATA_VALUE_TYPE.EMPTY,
                label: '- Bitte wählen -',
              },
            ]
          : [];
      this.typeList = this.typeList.concat(
        inputDataValueTypeList.filter((type) => this.allowedTypes.includes(type.value as DATA_VALUE_TYPE)),
      );
    }
    // this.selectDefault()
  }

  protected onTypeChanged(type: DATA_VALUE_TYPE): void {
    this.typeChange.emit(type);
    if (this.onTouched) {
      this.onTouched(type);
    }
    if (this.onChanged) {
      this.onChanged(type);
    }
  }

  // selectDefault() {
  //   if (this._allowedTypes.indexOf(this.type) >= 0) {
  //     return
  //   }
  //   this.type = this.typeList[0].code
  //   this.typeChange.emit(this.type)
  // }
}
