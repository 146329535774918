import { Pipe, PipeTransform } from '@angular/core';
import { User, USER_ROLES } from '@kfd/core';

@Pipe({
  name: 'isAdmin',
  standalone: false,
})
export class IsAdminPipe implements PipeTransform {
  transform(user: User): boolean {
    return user.roles.indexOf(USER_ROLES.ADMIN) >= 0;
  }
}
