import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import {
  BASE_DATA_RESOLVER,
  BaseDataResolver,
  ConfigurationValueHandler,
  FormValue,
  PrintValue,
  SummaryFieldConfig,
  ValidationError,
} from '@kfd/core';
import { ConfigurationService } from '../../service/configuration.service';
import { CfgSettingsService } from '../../service/cfg-settings.service';
import { ConfigurationStateService } from '../../service/configuration-state.service';
import { combineLatestWith, mergeMap, Observable } from 'rxjs';

export interface StructureElement {
  name: string;
  label?: string;
  data?: FormValue;
  validation?: ValidationError;
  hasValues?: boolean;
  fields?: StructureElement[];
}

@Component({
  selector: 'kfd-cfg-field-summary',
  templateUrl: './cfg-field-summary.component.html',
  styleUrls: ['./cfg-field-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CfgFieldSummaryComponent {
  @Input()
  public demo = false;
  protected printValueList$: Observable<PrintValue[]>;
  private configurationValueHandler: ConfigurationValueHandler | undefined;

  constructor(
    private readonly configurationService: ConfigurationService,
    private readonly configurationStateService: ConfigurationStateService,
    private readonly cfgSettingsService: CfgSettingsService,
    @Inject(BASE_DATA_RESOLVER) readonly baseDataResolver: BaseDataResolver,
  ) {
    this.printValueList$ = this.configurationService.onCfgChange().pipe(
      combineLatestWith(this.configurationStateService.valueMapChange()),
      mergeMap(([cfgUtil, valueMap]) => {
        if (!this.configurationValueHandler) {
          this.configurationValueHandler = new ConfigurationValueHandler(
            this.cfgSettingsService.localConfig(),
            this.baseDataResolver,
            cfgUtil,
            valueMap,
          );
        } else {
          this.configurationValueHandler.setCfgUtil(cfgUtil);
          this.configurationValueHandler.setValueMap(valueMap);
        }
        return this.configurationValueHandler.printValueList(true);
      }),
    );
  }

  private _field: SummaryFieldConfig | undefined;

  public get field(): SummaryFieldConfig | undefined {
    return this._field;
  }

  @Input()
  public set field(value: SummaryFieldConfig | undefined) {
    if (!value) {
      return;
    }
    this._field = value;
  }
}
