import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  ALLOWED_CHILDREN,
  BASE_DATA_RESOLVER,
  CfgSkeleton,
  CLS,
  Create,
  ICON,
  ProjectCI,
  projectCiToVariables,
} from '@kfd/core';
import { combineLatestWith, Observable } from 'rxjs';
import { CfgStateService } from '../../cfg-state.service';
import {
  CFG_CONTEXT_SERVICE,
  CfgContextService,
  CfgSettingsService,
  ConfigurationService,
  DATA_PROVIDER,
  ScreenSize,
} from '@kfd/cfg-core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  CalculationService,
  ConfigurationBasedataResolver,
  ConfigurationConditionService,
  ConfigurationFieldRefResolver,
  ConfigurationValidationService,
  EventService,
  PaginationService,
} from '../../../../../../../libs/cfg-core/src/lib/configuration/service';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ConfigurationDataService } from '../../../services/configuration-data.service';
import { CmsContextService } from '../../../services/cms-context.service';
import { ProjectService } from '../../../services/project.service';
import { map, tap } from 'rxjs/operators';
import { CfgEditorService } from '../../cfg-editor.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'kfd-dnd-area-configuration',
  templateUrl: './dnd-area-configuration.component.html',
  styleUrls: ['../../../../../../../libs/cfg-core/src/theme.scss', './dnd-area-configuration.component.scss'],
  providers: [
    ConfigurationService,
    CalculationService,
    ConfigurationFieldRefResolver,
    ConfigurationValidationService,
    ConfigurationConditionService,
    PaginationService,
    EventService,
    {
      provide: DATA_PROVIDER,
      useClass: ConfigurationDataService,
    },
    {
      provide: CFG_CONTEXT_SERVICE,
      useClass: CfgContextService,
    },
    {
      provide: BASE_DATA_RESOLVER,
      useClass: ConfigurationBasedataResolver,
    },
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DndAreaConfigurationComponent implements OnInit {
  @Input()
  public pageWidth = 0;

  @Input()
  public pageHeight = 0;

  protected readonly ICON = ICON;
  protected readonly CLS = CLS;
  protected readonly allowedChildren = ALLOWED_CHILDREN;

  protected viewData$:
    | Observable<{
        cfgSkeleton: CfgSkeleton;
        editMode: boolean;
      }>
    | undefined;

  constructor(
    private elementRef: ElementRef,
    private readonly cfgStateService: CfgStateService,
    private readonly cfgConfigurationService: ConfigurationService,
    private readonly cfgConfigService: CfgSettingsService,
    private readonly ctx: CmsContextService,
    @Inject(CFG_CONTEXT_SERVICE) private readonly cfgCtx: CfgContextService,
    private readonly projectService: ProjectService,
    private readonly cfgEditorService: CfgEditorService,
  ) {}

  public ngOnInit() {
    this.cfgCtx.projectId = this.ctx.projectId;
    this.cfgConfigService.embedded = false;
    this.cfgConfigService.screenSize = ScreenSize.XS;
    this.cfgConfigService.demoMode = true;

    this.viewData$ = this.cfgStateService.onConfigurationChange.pipe(
      combineLatestWith(this.cfgStateService.onStructureChange, this.cfgEditorService.onEditModeChange),
      tap(() => {
        if (this.cfgStateService.getCfgUtil().configuration) {
          this.cfgCtx.configuratorId = this.cfgStateService.getCfgUtil().configuration._id.toString();
          this.cfgConfigurationService.configuration = this.cfgStateService.getCfgUtil().configuration;
        }
      }),
      map(([, cfgSkeleton, editMode]) => ({
        cfgSkeleton,
        editMode,
      })),
    );

    this.projectService.getProject(this.ctx.projectId).subscribe((project) => {
      this.applyStyles(project.ci);
    });
  }

  public createNewPage(): void {
    const pageCount = this.cfgStateService.getCfgUtil().getPages().length;
    const label = 'Seite ' + (pageCount + 1);
    const newEntry = Create.cmsConfiguratorPage(
      Create.cmsPage({
        name: uuidv4(),
        label,
      }),
    );
    this.cfgStateService.createEntry(newEntry, {
      name: this.cfgStateService.getCfgUtil().getCfg().name,
      pos: this.cfgStateService.getCfgUtil().getPages().length,
    });
  }

  private applyStyles(projectCI: ProjectCI | undefined) {
    const colors: Record<string, string> = projectCiToVariables(projectCI);
    for (const color of Object.entries(colors)) {
      this.elementRef.nativeElement.style.setProperty(color[0], color[1]);
    }
  }
}
