import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICON } from '@kfd/core';

/**
 * @deprecated use kfd-placeholder-msg from web-core instead
 */
@Component({
  selector: 'kfd-empty-msg',
  templateUrl: './empty-msg.component.html',
  styleUrls: ['./empty-msg.component.scss'],
  standalone: false,
})
export class EmptyMsgComponent {
  @Output() startCreation = new EventEmitter<void>();

  @Input()
  label = 'Jetzt starten';

  @Input()
  btnLink: string | undefined;

  @Input()
  info = '';

  @Input()
  infoIcon = ICON.NOTE;

  @Input()
  showInfoIcon = true;

  @Input()
  btnIcon = ICON.ADD;

  @Input()
  showAddBtn = false;

  @Input()
  btnDisabled = false;
}
