import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Asset, cmsServiceEP, Create, CreateAssetData, Id, Image } from '@kfd/core';
import { FeCache, WebEndpointService } from '@kfd/web-core';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AssetService implements OnDestroy {
  private destroy$ = new Subject<boolean>();
  private cache = new FeCache();

  constructor(private webEndpointService: WebEndpointService) {}

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public listAssets(projectId: string): Observable<typeof cmsServiceEP.assets.list.response> {
    return this.webEndpointService
      .get(cmsServiceEP.assets.list, [projectId])
      .pipe(map((assets) => assets.map((asset) => this.setAbsoluteUrl(asset))));
  }

  public getImage(projectId: string, assetId: Id, useCache = false): Observable<Image> {
    return this.getAsset(projectId, assetId, useCache).pipe(
      map((asset) => Create.image(asset.label, asset.url, asset.thumbnailUrl)),
    );
  }

  public getAsset(projectId: Id, assetId: Id, useCache = false): Observable<typeof cmsServiceEP.assets.get.response> {
    const key = `asset-${projectId}-${assetId}`;

    const obs$ = this.webEndpointService
      .get(cmsServiceEP.assets.get, [projectId.toString(), assetId.toString()])
      .pipe(map((asset) => this.setAbsoluteUrl(asset)));
    return useCache === true ? this.cache.cacheObs(obs$, key) : obs$;
  }

  public createAsset(projectId: string, data: CreateAssetData): Observable<typeof cmsServiceEP.assets.create.response> {
    return this.webEndpointService.post(cmsServiceEP.assets.create, [projectId], data);
  }

  public updateAsset(
    projectId: string,
    assetId: Id,
    data: Partial<CreateAssetData>,
  ): Observable<typeof cmsServiceEP.assets.update.response> {
    return this.webEndpointService.put(cmsServiceEP.assets.update, [projectId, assetId.toString()], data);
  }

  public getAssetUsages(
    projectId: Id,
    assetId: Id,
    useCache = false,
  ): Observable<typeof cmsServiceEP.assets.usages.response> {
    const key = `asset-usage-${projectId}-${assetId}`;

    const obs$ = this.webEndpointService.get(cmsServiceEP.assets.usages, [projectId.toString(), assetId.toString()]);
    return useCache === true ? this.cache.cacheObs(obs$, key) : obs$;
  }

  public deleteAsset(
    projectId: string,
    assetId: Id,
    force = false,
  ): Observable<typeof cmsServiceEP.assets.delete.response> {
    return this.webEndpointService.delete(cmsServiceEP.assets.delete, [projectId, assetId.toString()], {
      params: {
        force,
      },
    });
  }

  private setAbsoluteUrl(asset: Asset): Asset {
    return {
      ...asset,
      url: environment.services.cms + asset.url,
      thumbnailUrl: environment.services.cms + asset.thumbnailUrl,
    };
  }
}
