import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ICON, MyProject } from '@kfd/core';
import { BaseDialogComponent } from '../../../common/base-dialog.component';

@Component({
  selector: 'kfd-project-selection-dialog',
  templateUrl: './project-selection-dialog.component.html',
  styleUrls: [],
  standalone: false,
})
export class ProjectSelectionDialogComponent extends BaseDialogComponent {
  protected readonly ICON = ICON;

  constructor(
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
  ) {
    super(dialogRef, dialogConfig);
  }

  select(mandant?: MyProject) {
    this.close(mandant);
  }
}
