import { SchemaUtil } from '.';

export enum PATTERN {
  //https://urlregex.com/
  URL = '((([A-Za-z]{3,9}:(?:\\/\\/)?)(?:[\\-;:&=\\+\\$,\\w]+@)?[A-Za-z0-9\\.\\-]+|(?:www\\.|[\\-;:&=\\+\\$,\\w]+@)[A-Za-z0-9\\.\\-]+)((?:\\/[\\+~%\\/\\.\\w\\-_]*)?\\??(?:[\\-\\+=&;%@\\.\\w_]*)#?(?:[\\.\\!\\/\\\\\\w]*))?)',
  // file path or relative url
  PATH = '^[a-zA-Z0-9.:_~\\-\\/]*$',
  // EMAIL = '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])',
  EMAIL = '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$',
  BASE64 = '^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$',
  TEXT = '^[^<>{}$\\n\\r]*$',
  TEXT_MULTILINE = '^[^<>{}$]*$',
  SAVE_TEXT = '^[a-z0-9_-]*$',
  CUSTOMER_CODE = '^[A-Z0-9]{8,8}$',
  CFG_CODE = '^[A-Z0-9_]{3,6}$',
  HEX_COLOR = '^#(?:[0-9a-fA-F]{3}){1,2}$',
  VERIFICATION_CODE = '^[A-Z0-9]*$',
  ERROR_ID = '^ERR-[A-Z0-9]{8,8}$',
  BCRYPT_HASH = '^[a-zA-Z0-9\\$\\.\\/]*$',
  JWT = '^[$\\/A-Za-z0-9_\\-\\.]*$',
}

export const PROP_MONGO_ID = SchemaUtil.objectId();
export const PROP_VERIFICATION_CODE = SchemaUtil.string({
  minLength: 5,
  maxLength: 8,
  pattern: PATTERN.VERIFICATION_CODE,
});
export const CONFIGURATION_SHORT_CODE = SchemaUtil.string({
  pattern: PATTERN.CFG_CODE,
});
export const REQUEST_STATUS_CODE_VALUE = SchemaUtil.string({
  pattern: PATTERN.SAVE_TEXT,
  minLength: 3,
  maxLength: 15,
});
export const PROP_NAME = SchemaUtil.string({
  pattern: PATTERN.SAVE_TEXT,
  minLength: 3,
  maxLength: 50,
});
export const PROP_LABEL = SchemaUtil.string({
  pattern: PATTERN.TEXT,
  minLength: 3,
  maxLength: 50,
});
export const SIMPLE_TEXT = SchemaUtil.string({
  minLength: 0,
  maxLength: 50,
  pattern: PATTERN.TEXT,
});
export const SAVE_TEXT = SchemaUtil.string({
  minLength: 0,
  maxLength: 30,
  pattern: PATTERN.SAVE_TEXT,
});
export const PROP_ICON = SchemaUtil.string({
  minLength: 3,
  maxLength: 20,
  pattern: PATTERN.SAVE_TEXT,
});
export const PROP_TAG = SchemaUtil.string({
  minLength: 3,
  maxLength: 20,
  pattern: PATTERN.SAVE_TEXT,
});
export const PROP_EMAIL = SchemaUtil.string({
  minLength: 3,
  maxLength: 128,
  pattern: PATTERN.EMAIL,
});
export const PROP_JWT = SchemaUtil.string({
  minLength: 8,
  maxLength: 1024,
  pattern: PATTERN.JWT,
});
export const PROP_BCRYPT_HASH = SchemaUtil.string({
  minLength: 60,
  maxLength: 60,
  pattern: PATTERN.BCRYPT_HASH,
});

export const PROP_DESCRIPTION_S = SchemaUtil.string({ pattern: PATTERN.TEXT_MULTILINE });

export const TAG_LIST = SchemaUtil.array(PROP_TAG);
