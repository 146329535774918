<div
  class="header-toolbar flex flex-column md:flex-row md:align-items-center md:justify-content-between overflow-hidden">
  <div class="overflow-hidden mr-8 md:mr-0">
    <div [routerLink]="linkTarget ? linkTarget : undefined" class="overflow-hidden flex-column header-group">
      <!--div class="subtitle text-sm">{{ subTitle }}</div-->
      <h1 class="text-overflow-ellipsis">{{ headline }}</h1>
    </div>
    <ng-content select="[topLeft]"></ng-content>
  </div>
  <div class="flex mr-8">
    <ng-content></ng-content>
    <ng-content select="[topRight]"></ng-content>
    @if (menuItems) {
      <kfd-btn-menu [items]="menuItems" [endSeparator]="!!(showDocu && docuPage)"></kfd-btn-menu>
    }
    @if (showDocu && docuPage) {
      <kfd-docu-btn [pageId]="docuPage" [sectionId]="docuSection" class="ml-2"></kfd-docu-btn>
    }
  </div>
</div>
