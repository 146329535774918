import { Pipe, PipeTransform } from '@angular/core';
import { ApiProjectUserService } from '../../services/api/api-project-user.service';
import { Observable } from 'rxjs';
import { SETTINGS_KEYS } from '@kfd/core';
import { map } from 'rxjs/operators';

/**
 * usage
 * {{'setting-name' | setting | async}}
 * @if(('setting-name' | setting | async) === 'true') {}
 */
@Pipe({
  name: 'setting',
  standalone: false,
})
export class UserSettingPipe implements PipeTransform {
  constructor(private userService: ApiProjectUserService) {}

  transform(setting: SETTINGS_KEYS, fallback = 'false'): Observable<string> {
    return this.userService.getSetting(setting).pipe(map((result) => (result === undefined ? fallback : result)));
  }
}
