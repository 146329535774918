import { Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ICON } from '@kfd/core';
import { environment } from '../../../../environments/environment';

const metaMenuItems: MenuItem[] = [
  {
    label: 'Web',
    routerLink: `${environment.apps.web}`,
  },
  {
    label: 'Impressum',
    routerLink: `${environment.apps.web}/content/imprint`,
  },
  {
    label: 'Datenschutz',
    routerLink: `${environment.apps.web}/content/privacy`,
  },
  {
    label: 'Kontakt',
    routerLink: `${environment.apps.web}/content/contact`,
  },
  {
    label: 'Support',
    routerLink: `${environment.apps.web}/support`,
  },
];

@Component({
  selector: 'kfd-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
})
export class SideNavigationComponent {
  protected collapsed = true;
  protected menuItems: MenuItem[] | undefined;
  protected readonly ICON = ICON;
  protected readonly environment = environment;
  protected metaMenuItems: MenuItem[] = [];

  constructor(private readonly breakpointObserver: BreakpointObserver) {
    const isTablet = this.breakpointObserver.isMatched('(min-width: 768px)');
    this.collapsed = !isTablet;
  }

  private _items: MenuItem[] = [];

  get items(): MenuItem[] | undefined {
    return this._items;
  }

  @Input()
  set items(value: MenuItem[] | undefined) {
    if (value !== undefined) {
      this._items = value;
    }
    this.updateMenu();
  }

  protected toggleCollapse(): void {
    if (this.collapsed) {
      this.collapsed = false;
    } else {
      this.collapsed = true;
    }
    this.updateMenu();
  }

  protected updateMenu(): void {
    this.menuItems = this.items.map((item) => ({
      ...item,
      label: this.collapsed ? '' : item.label,
      tooltip: !this.collapsed ? '' : item.tooltip,
    }));

    this.metaMenuItems = metaMenuItems.map((item) => ({
      ...item,
      label: this.collapsed ? '' : item.label,
      tooltip: !this.collapsed ? '' : item.tooltip,
    }));
  }
}
